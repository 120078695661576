import { useState } from "react";
import { useLocation } from "react-router-dom";

import { usePlaylistContext } from "../../context/PlaylistContext";
import { BulkModeCTAStyle, BulkModeWrapperStyle } from "./BulkModeCTA.style1";

const BulkModeCTA = () => {
  const { state, dispatch } = usePlaylistContext();
  const [useText, setText] = useState("OFF");
  const location = useLocation();

  const handleChange = (e) => {
    if (e === false) {
      setText("OFF");
    } else {
      setText("ON");
    }
    localStorage.setItem("bulk", JSON.stringify(e, null, " "));
    dispatch({
      type: "TOGGLE_BULK_MODE",
      payload: e,
    });
  };

  const isDisabled = location.pathname.includes("search") && !state.isBulkMode;
  return (
    <>
      <span>
        <BulkModeWrapperStyle>

          <div className="row SwitchPlayListSpannerRows">
            <BulkModeCTAStyle
              checked={state.isBulkMode}
              disabled={isDisabled}
              onChange={handleChange}
            />
          </div>
        </BulkModeWrapperStyle>
      </span>
      <p className="textAction" style={{ marginLeft: '10px',color:'white', opacity:1 }}>{useText}</p>
    </>
  );
};

export default BulkModeCTA;
