import axios from "axios";
import queryString from "query-string";
import querystring from "querystring";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Alert } from "../../assets";
import { BASE_URL } from "../../constants/api-urls";
import {
  SPOTIFY_CODE,
  SPOTIFY_REFRESH_TOKEN,
  SPOTIFY_TOKEN,
  SPOTIFY_USER_INFO
} from "../../constants/storage";
import { useLoginContext } from "../../context";
import { Auth0Logout, addItemToStorage, deleteItemFromStorage } from "../../utils";

import PricingModal from "../landingPage/PaymentModal";
import { LoginStyle } from "./Login.style";
import { LoaderPageStyle } from "./ShiftPage.style";

const ShiftPage = () => {
  const access_token = queryString.parse(useLocation().hash);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  let proPlanMonthly = process.env.REACT_APP_STRIPE_PRO_MONTHLY;
  let proPlan = process.env.REACT_APP_STRIPE_PRO;
  let unlimitedPlanMonthly = process.env.REACT_APP_STRIPE_UNLIMITED_MONTHLY;
  let unlimitedPlan = process.env.REACT_APP_STRIPE_UNLIMITED;
  const plan = new URL(window.location as any).searchParams.get("plan");
  const planId = new URL(window.location as any).searchParams.get("planId");
  const planPaid = new URL(window.location as any).searchParams.get("planPaid");
  const defaultCurrency = new URL(window.location as any).searchParams.get(
    "defaultCurrency"
  );
  const { dispatch } = useLoginContext();
  // const [stripeProductType, setStripeProductType] = useState<string>("");
  const [princingModalIsOpen, setPrincingModalIsOpen] =
    useState<boolean>(false);
  const [message, setShowMessage] = useState("Loading");

  let ides = "";
  const PricingNext = (
    id: string,
    type: string,
    duration: string,
    currency: string
  ) => {
    // setAddCardModalIsOpen(true);
    setPrincingModalIsOpen(false);
    if (type === "Free") {
      window.location.href = `${window.location.origin}/created?session_id=free`;
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let updatedUser = {
        ...userInfo,
        planId: "free",
        free: true,
        duration: duration,
      };
      localStorage.setItem("userInfo", JSON.stringify(updatedUser));
    } else {
      fetch(`${BASE_URL}/stripe-payment-form`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          successUrl: `${window.location.origin}/created?session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: window.location.href,
          email: user?.email,
          subId: user?.userId,
          product_id: id,
          currency: currency.toLowerCase(),
          custId: user?.custId ? `${user?.custId}`:"",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          let updatedUser = {
            ...userInfo,
            planId: id,
            free: false,
            duration: duration,
          };
          localStorage.setItem("userInfo", JSON.stringify(updatedUser));
          window.location = data.data.url;
          // console.log(data.data.url);
        })
        .catch((error) => {
        });
    }
  };
  useEffect(() => {
    if (access_token?.access_token) {
      fetch(`https://dev-2z4p074m.us.auth0.com/userinfo`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token?.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {

          const bodies = {
            client_id: process.env.REACT_APP_AUTH0,
            client_secret: process.env.REACT_APP_AUTH0_SECRET,
            audience: "https://dev-2z4p074m.us.auth0.com/api/v2/",
            grant_type: `client_credentials`,
          };
          const congifs = {
            headers: {
              "content-type": "application/json",
            },
          };
          axios
            .post("https://dev-2z4p074m.us.auth0.com/oauth/token", bodies, congifs)
            .then((res1) => {
              fetch(`https://dev-2z4p074m.us.auth0.com/api/v2/users/${res.sub}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${res1?.data?.access_token}`,
                },
              })
                .then((res) => res.json())
                .then((res2) => {

                  // console.log(res);
                  // if (res.status == 200) {
                  // console.log({ res });
                  let data = {
                    profileImage: res?.picture,
                    username: res2?.name,
                    email: res?.email,
                    token: access_token?.access_token,
                    userId: res.sub,
                    allLogin: res2?.logins_count,
                    lastLogin: res2?.last_login,
                    emailVerified: res2?.email_verified,
                    free: res2?.user_metadata?.free,
                    custId: res2?.user_metadata?.customer,
                    subId: res2?.user_metadata?.subscription,
                    accountType:
                      res2?.identities[0]?.connection ===
                      "Username-Password-Authentication"
                        ? true
                        : false,
                  };
                  localStorage.setItem("userInfo", JSON.stringify(data));
                  if (res?.email) {
                    fetch(`${BASE_URL}/packages`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        email: `${res.email}`,
                        userId: ides,
                        subId: res2?.user_metadata?.subscription,
                        payId: res2?.user_metadata?.payment,
                        custId: res2?.user_metadata?.customer,
                      }),
                    })
                      .then((res) => res.json())
                      .then((data) => {
                        if (data?.data?.length > 0) {
                          data?.data &&
                            data?.data?.length > 0 &&
                            data?.data
                              .reverse()
                              .slice(-1)
                              .map((_c, index) => {
                                if (
                                  _c?.email === res?.email &&
                                  (_c?.payment_status === "active" ||
                                    _c?.payment_status === "trialing")
                                ) {

                                    ides = res.sub;
                                    let planId = _c?.planId;
                                    let data = {
                                      profileImage: res?.picture,
                                      username: res2?.name,
                                      email: res?.email,
                                      token: access_token?.access_token,
                                      userId: res.sub,
                                      allLogin: res2?.logins_count,
                                      lastLogin: res2?.last_login,
                                      emailVerified: res2?.email_verified,
                                      planId,
                                      accountType:
                                        res2?.identities[0]?.connection ===
                                        "Username-Password-Authentication"
                                          ? true
                                          : false,
                                      subId: _c?.subsId,
                                      custId: _c?.custId,
                                      duration:
                                        planId === proPlanMonthly ||
                                        planId === unlimitedPlanMonthly
                                          ? "month"
                                          : planId === proPlan ||
                                            planId === unlimitedPlan
                                          ? "year"
                                          : "year",

                                    };
                                    localStorage.setItem(
                                      "userInfo",
                                      JSON.stringify(data)
                                    );
                                 
                                    const config = {
                                      headers: {
                                        "Content-Type": "application-json",
                                      },
                                    };
                                    axios
                                      .get(
                                        `${BASE_URL}/user/${ides}/data`,
                                        config
                                      )
                                      .then((call) => {
                                        if (call?.data?.data?.length > 0) {
                                          call?.data?.data &&
                                            call?.data?.data?.length > 0 &&
                                            call?.data?.data
                                              .reverse()
                                              .slice(-1)
                                              .map((_a, indexes) => {
                                                const data = {
                                                  username: _a?.username,
                                                  image:
                                                    _a?.image !== undefined
                                                      ? _a?.image[0]?.url
                                                        ? _a?.image[0]?.url
                                                        : "dummy"
                                                      : "dummy",
                                                  code: _a?.code,
                                                  refresh: _a?.refresh_token,
                                                };
                                                localStorage.setItem(
                                                  "spotifyUser",
                                                  JSON.stringify(
                                                    data,
                                                    null,
                                                    " "
                                                  )
                                                );
                                                addItemToStorage(
                                                  SPOTIFY_TOKEN,
                                                  _a?.token
                                                );
                                                addItemToStorage(
                                                  SPOTIFY_REFRESH_TOKEN,
                                                  _a?.refresh
                                                );
                                                addItemToStorage(
                                                  SPOTIFY_CODE,
                                                  _a?.code
                                                );

                                                dispatch({
                                                  type: "ADD_APP_TOKEN",
                                                  payload: {
                                                    token: _a?.token,
                                                    refreshToken: _a?.refresh,
                                                  },
                                                });
                                                setTimeout(() => {
                                                  window.location.replace(
                                                    "/home"
                                                  );
                                                }, 1500);
                                              });
                                        } else {
                                          window.location.replace(
                                            "https://accounts.spotify.com/authorize?" +
                                              querystring.stringify({
                                                response_type: "code",
                                                client_id: encodeURIComponent(
                                                  process.env
                                                    .REACT_APP_CLIENT_ID
                                                ),
                                                scope:
                                                  "playlist-modify-private playlist-modify-public playlist-read-private ",
                                                redirect_uri:
                                                  process.env
                                                    .REACT_APP_REDIRECT_URI,
                                                show_dialog: false,
                                              })
                                          );
                                        }
                                      })
                                      .catch((err) => {
                                      });
                         
                                } else {
                                  setShowMessage("expire");
                                }
                              });
                        } else if (res2.user_metadata?.free) {
                          const config = {
                            headers: {
                              "Content-Type": "application-json",
                            },
                          };
                          axios
                            .get(`${BASE_URL}/user/${res.sub}/data`, config)
                            .then((call) => {
                              if (call?.data?.data?.length > 0) {
                                call?.data?.data &&
                                  call?.data?.data?.length > 0 &&
                                  call?.data?.data
                                    .reverse()
                                    .slice(-1)
                                    .map((_a, indexes) => {
                                      const data = {
                                        username: _a?.username,
                                        image:
                                          _a?.image !== undefined
                                            ? _a?.image[0]?.url
                                              ? _a?.image[0]?.url
                                              : "dummy"
                                            : "dummy",
                                        code: _a?.code,
                                        refresh: _a?.refresh_token,
                                      };
                                      localStorage.setItem(
                                        "spotifyUser",
                                        JSON.stringify(data, null, " ")
                                      );
                                      addItemToStorage(
                                        SPOTIFY_TOKEN,
                                        _a?.token
                                      );
                                      addItemToStorage(
                                        SPOTIFY_REFRESH_TOKEN,
                                        _a?.refresh
                                      );
                                      addItemToStorage(SPOTIFY_CODE, _a?.code);

                                      dispatch({
                                        type: "ADD_APP_TOKEN",
                                        payload: {
                                          token: _a?.token,
                                          refreshToken: _a?.refresh,
                                        },
                                      });
                                      setTimeout(() => {
                                        window.location.replace("/home");
                                      }, 1500);
                                    });
                              } else {
                                window.location.replace(
                                  "https://accounts.spotify.com/authorize?" +
                                    querystring.stringify({
                                      response_type: "code",
                                      client_id: encodeURIComponent(
                                        process.env.REACT_APP_CLIENT_ID
                                      ),
                                      scope:
                                        "playlist-modify-private playlist-modify-public playlist-read-private ",
                                      redirect_uri:
                                        process.env.REACT_APP_REDIRECT_URI,
                                      show_dialog: false,
                                    })
                                );
                              }
                            })
                            .catch((err) => {
                            });
                        } else {
                          if (
                            plan === "planChoosen" &&
                            !res2.user_metadata?.free
                          ) {
                            if (
                              planId ===
                              process.env.REACT_APP_STRIPE_PRO_MONTHLY
                            ) {
                              PricingNext(
                                planId,
                                planPaid,
                                "month",
                                defaultCurrency
                              );
                            } else if (
                              planId ===
                              process.env.REACT_APP_STRIPE_UNLIMITED_MONTHLY
                            ) {
                              PricingNext(
                                planId,
                                planPaid,
                                "month",
                                defaultCurrency
                              );
                            } else if (
                              planId === process.env.REACT_APP_STRIPE_PRO
                            ) {
                              PricingNext(
                                planId,
                                planPaid,
                                "year",
                                defaultCurrency
                              );
                            } else if (
                              planId === process.env.REACT_APP_STRIPE_UNLIMITED
                            ) {
                              PricingNext(
                                planId,
                                planPaid,
                                "year",
                                defaultCurrency
                              );
                            }
                          } else if (!plan && !planId && !planPaid) {
                            setPrincingModalIsOpen(true);
                          } else {
                            if (
                              planPaid !== "Free" &&
                              !res2.user_metadata?.free
                            ) {
                              setPrincingModalIsOpen(true);
                            }
                          }

                        }
                      })
                      .catch((error) => {
                        // message.error("Network error");
                      });
                  }
         
                })
                .catch((error) => {
                  if (error) {
                    deleteItemFromStorage(SPOTIFY_TOKEN);
                    deleteItemFromStorage(SPOTIFY_CODE);
                    deleteItemFromStorage(SPOTIFY_USER_INFO);

                    dispatch({
                      type: "SET_LOGIN",
                      payload: false,
                    });
                    Auth0Logout();
                  }
                });
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }
  }, []);
  const handleLogout = async () => {
    deleteItemFromStorage(SPOTIFY_TOKEN);
    deleteItemFromStorage(SPOTIFY_CODE);
    deleteItemFromStorage(SPOTIFY_USER_INFO);
    localStorage.removeItem("userInfo");
    localStorage.removeItem("spotifyUser");

    dispatch({
      type: "SET_LOGIN",
      payload: false,
    });
    Auth0Logout();
  };



  const onBack = () => {
    deleteItemFromStorage(SPOTIFY_TOKEN);
    deleteItemFromStorage(SPOTIFY_CODE);
    deleteItemFromStorage(SPOTIFY_USER_INFO);
    localStorage.removeItem("userInfo");
    localStorage.removeItem("spotifyUser");
    Auth0Logout();
  };

  return (
   

    <>
      {message === "expire" ? (
        <LoginStyle>
          <div className="background-col-1">
            <div className="background">
              <div className="expire-div" style={{}}>
                <Alert />
              </div>
              <h3 style={{}} className="h3">
                Your Subscription has been expired
              </h3>
              <p className="ppp" style={{}}>
                Subscribe now to enjoy your favourite playlists.
              </p>
              {/* <button onClick={onFinish} className="btn2">
                Renew Subscription
              </button> */}
              <div className="dives">
                <button onClick={onBack} className="btn1">
                  Logout
                </button>
              </div>
            </div>
          </div>
        </LoginStyle>
      ) : (
        <>
          <LoaderPageStyle>
            <div className="loadingPage">
              <h1>Taking you to Songplace</h1>
              <div className="loadingDiv">
                <div className="loadingCircle"></div>
              </div>
            </div>
          </LoaderPageStyle>
      
          <PricingModal
            next={PricingNext}
            isOpen={princingModalIsOpen}
            onClose={() => {
              handleLogout();
              setPrincingModalIsOpen(false);
            }}
            showmenu={true}
            currentPlan={""}
          />
        </>

      )}
    </>
  );
};

export default ShiftPage;
