import styled from "styled-components";

export const CardsListStyle = styled.div`
  // display: flex;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // grid-gap: 20px;

  // @media (max-width: 1366px) {
  //   grid-template-columns: 1fr 1fr 1fr;
  // }
  
  .image {
    width: 223px;
    aspect-ratio: 1;
background-size: contain;
    background-repeat: no-repeat;
    border-radius: 0px;
    filter: drop-shadow(0px 0px 15px rgba(154, 154, 154, 0.3));
        background-position: center;
  }
  .image:hover {
    filter: drop-shadow(0px 0px 15px rgba(154, 154, 154, 0.3));
  }
  .image:hover .overlay {
    background: rgb(0, 0, 0, 0.3);
  }
  .overlay {
    width: 100%;
    border-radius: 0px;
    display: flex;
    align-items: flex-start;
    padding: 10px;
    align-items: flex-start;
    justify-content:flex-start;
    flex-direction:column;
  }
  .overlay:hover .heading1 {
    transform: scale(1.1);
  }
  .overlay .playlistName{
    font-family: 'DM Sans';
    font-style: normal;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    

    color: #FFFFFF;

    flex: none;
    order: 0;
    flex-grow: 0;
        font-size: 12px;
    
  }
  .overlay .songName{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #F9FAFB;
    opacity: 0.8;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .heading1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px !important;
  }
  @media only screen and (max-width: 600px) {
    .heading1 {
      max-width: 80px !important;
    }
  }
  .p4 {
    color: white;
    font-size: 17px;
    margin-left: 30px;
    padding-top: 30px;
  }
  .p3 {
    color: white;
    font-size: 17px;
    margin-left: 30px;
    padding-top: 30px;
  }
  .p5 {
    font-size: 16px;
  }
  .div2 {
    // margin-top: 55%;
    // margin-left: 7%;
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 500;
  }
  .div3 {
    margin-top: 55%;
    margin-left: 7%;
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 500;
  }
  .div4 {
    margin-left: 20px;
  }
  .div5 {
    margin-left: 20px;
  }
  // @media (max-width: 767px) {
  //   grid-template-columns: 1fr;
  // }
`;
