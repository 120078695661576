import { DatePicker, Popconfirm, Popover } from "antd";
import moment from "moment";
import { FilledButtonStyle } from "../../styles/common.style";
import { ScheduleModalStyle } from "../AddToPlayListModal/AddToPlayListModal.style";
import Spinner from "../Spinner/Spinner";
import { useState } from "react";

function ScheduleRefreshModal({
  scheduleRefreshModal,
  handleScheduleModalhandleOk,
  handleScheduleModalCancel,
  scheduleDateTime,
  setScheduleDateTime,
  scheduleDateTimeDatePicker,
  setScheduleDateTimeDatePicker,
  scheduleType,
  setScheduleType,
  setOpenDatePicker,
  setdatePickeropen,
  saveRestore,
  setSaveRestore,
  scheduleLaoder,
  props, // assuming this is part of the function's signature for refreshScheduleData
  showModalDelete,
  ScheduleRefreshFn,
}) {
  const [cahngeDate,setCahngeDate]=useState(false)
  return (
    <ScheduleModalStyle
      visible={scheduleRefreshModal}
      onOk={handleScheduleModalhandleOk}
      footer={null}
      onCancel={handleScheduleModalCancel}
      className="ScheduleModalStyle"
    >
      <div
        onClick={() => {
          console.log(scheduleDateTime);
        }}
      >
        <h1 className="heading">Schedule refresh</h1>
      </div>
      <p className="scheduleText">
        Schedule refresh playlist feature to automatically refresh daily,
        weekly, or monthly permanently updating each song's start date and time
        to the scheduled date and time.
      </p>
      <div className="scheduleTime">
        {/* <label>
        <Select placeholder="Select schedule time" value={scheduleType} onChange={(value) => {
          setScheduleType(value)
        }}>
          <Select.Option value={'week'}>Weekly</Select.Option>
          <Select.Option value={'quarter'}>Quarterly</Select.Option>
          <Select.Option value={'month'}>Monthly</Select.Option>
        </Select>
      </label> */}
        {/* <DatePicker
        format="YYYY-MM-DD hh:mm:ss"
        showTime={true}
        value={scheduleDateTime ? moment(scheduleDateTime) : moment()}
        onChange={(date, dateString) => {
          console.log(date, dateString, 'evevev')
          setScheduleDateTime(new Date(dateString))
        }}
      /> */}
        <div>
          <Popconfirm
            overlayClassName="schedlepopConfirm"
            icon={false}
            title={
              <div>
                <DatePicker
                  showNow={false}
                  className="scheduleInput"
                  popupClassName="schedulePopup"
                  format="DD/MM/YYYY hh:mm A"
                  showTime={{ format: "hh:mm A" }}
                  value={
                    scheduleDateTimeDatePicker
                      ? moment(scheduleDateTimeDatePicker)
                      : moment()
                  }
                  onChange={(date, dateString) => {
                    setCahngeDate(true)
                    if (date) {
                      const formattedDate = moment(date).toDate();
                      setScheduleDateTimeDatePicker(formattedDate);
                    } else {
                      setScheduleDateTimeDatePicker(null);
                    }
                  }}
                  // Disable past dates
                  disabledDate={(current) => {
                    return current && current < moment().startOf("day");
                  }}
                  // Disable past times if the selected date is today
                  disabledTime={(current) => {
                    const now = moment();
                    if (current && current.isSame(now, "day")) {
                      return {
                        disabledHours: () =>
                          Array.from({ length: now.hours() }, (_, i) => i),
                        disabledMinutes: () => {
                          // Only disable minutes for the current hour
                          if (current.hours() === now.hours()) {
                            return Array.from(
                              { length: now.minutes() },
                              (_, i) => i
                            );
                          }
                          return [];
                        },
                      };
                    }
                    return {};
                  }}
                />
              </div>
            }
            showArrow={false}
            showCancel={false}
            okText="Yes"
          >
            <Popover
              content={
                <p>Playlist will be refreshed daily at the scheduled time</p>
              }
              placement="bottomLeft"
            >
              <button
                onClick={() => {
                  setScheduleType("daily");
                  setOpenDatePicker(true);
                }}
                className={
                  scheduleType === "daily"
                    ? "scheduleButton scheduleButtonActive"
                    : "scheduleButton"
                }
              >
                {" "}
                <img
                  alt="notes.svg"
                  src={"./static/Calendar.png"}
                  width="24px"
                  height="24px"
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />{" "}
                Daily{" "}
              </button>
            </Popover>
          </Popconfirm>
          {/* {scheduleType === "daily" && ( */}
          {props.refreshScheduleData?.nextrefreshtTime &&
          props.refreshScheduleData?.type === "daily" ? (
            <p
              className="scheduleText"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              Next refresh : <br />
              {/* {getRefreshTime(scheduleDateTime, scheduleType, props?.refreshScheduleData?.nextrefreshtTime ? false : true)} */}
              {/* {getTimeAfter(scheduleType, 1)} */}
              {moment(
                Number(props.refreshScheduleData.nextrefreshtTime)
              ).format("DD/MM/YYYY hh:mm A")}{" "}
              {Intl.DateTimeFormat().resolvedOptions().timeZone}
            </p>
          ) : null}
        </div>
        <div>
          <Popconfirm
            overlayClassName="schedlepopConfirm"
            icon={false}
            title={
              <div>
                <DatePicker
                  showNow={false}
                  className="scheduleInput"
                  popupClassName="schedulePopup"
                  format="DD/MM/YYYY hh:mm A"
                  showTime={{ format: "hh:mm A" }}
                  value={
                    scheduleDateTimeDatePicker
                      ? moment(scheduleDateTimeDatePicker)
                      : moment()
                  }
                  onChange={(date, dateString) => {
                    setCahngeDate(true)
                    if (date) {
                      const formattedDate = moment(date).toDate();
                      setScheduleDateTimeDatePicker(formattedDate);
                    } else {
                      setScheduleDateTimeDatePicker(null);
                    }
                  }}
                  // Disable past dates
                  disabledDate={(current) => {
                    return current && current < moment().startOf("day");
                  }}
                  // Disable past times if the selected date is today
                  disabledTime={(current) => {
                    const now = moment();
                    if (current && current.isSame(now, "day")) {
                      return {
                        disabledHours: () =>
                          Array.from({ length: now.hours() }, (_, i) => i),
                        disabledMinutes: () => {
                          // Only disable minutes for the current hour
                          if (current.hours() === now.hours()) {
                            return Array.from(
                              { length: now.minutes() },
                              (_, i) => i
                            );
                          }
                          return [];
                        },
                      };
                    }
                    return {};
                  }}
                />
              </div>
            }
            showArrow={false}
            showCancel={false}
            okText="Yes"
          >
            <Popover
              content={
                <p>
                  Playlist will be refreshed weekly from the scheduled date and
                  time
                </p>
              }
              placement="bottomLeft"
            >
              <button
                onClick={() => {
                  setScheduleType("week");
                  // console.log(datePickerRef.current?.click())
                  setdatePickeropen(true);
                  // setScheduleDateTimeDatePicker("")

                  // setScheduleDateTime(new Date())
                }}
                className={
                  scheduleType === "week"
                    ? "scheduleButton scheduleButtonActive"
                    : "scheduleButton"
                }
              >
                <img
                  alt="notes.svg"
                  src={"./static/Calendar.png"}
                  width="24px"
                  height="24px"
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />{" "}
                Weekly
              </button>
            </Popover>
          </Popconfirm>
          {/* {scheduleType === "week" && ( */}
          {props.refreshScheduleData?.nextrefreshtTime &&
          props.refreshScheduleData?.type === "week" ? (
            <p
              onClick={() => {
                console.log(scheduleDateTime);
              }}
              className="scheduleText"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              Next refresh:
              <br />
              {/* {getRefreshTime(scheduleDateTime, scheduleType, props?.refreshScheduleData?.nextrefreshtTime ? false : true)} */}
              {/* {getTimeAfter(scheduleType, 1)} */}
              {moment(
                Number(props.refreshScheduleData.nextrefreshtTime)
              ).format("DD/MM/YYYY hh:mm A")}{" "}
              {Intl.DateTimeFormat().resolvedOptions().timeZone}
            </p>
          ) : null}
        </div>
        <div>
          <Popconfirm
            overlayClassName="schedlepopConfirm"
            icon={false}
            title={
              <div>
                <DatePicker
                  showNow={false}
                  className="scheduleInput"
                  popupClassName="schedulePopup"
                  format="DD/MM/YYYY hh:mm A"
                  showTime={{ format: "hh:mm A" }}
                  value={
                    scheduleDateTimeDatePicker
                      ? moment(scheduleDateTimeDatePicker)
                      : moment()
                  }
                  onChange={(date, dateString) => {
                    setCahngeDate(true)
                    if (date) {
                      const formattedDate = moment(date).toDate();
                      setScheduleDateTimeDatePicker(formattedDate);
                    } else {
                      setScheduleDateTimeDatePicker(null);
                    }
                  }}
                  // Disable past dates
                  disabledDate={(current) => {
                    return current && current < moment().startOf("day");
                  }}
                  // Disable past times if the selected date is today
                  disabledTime={(current) => {
                    const now = moment();
                    if (current && current.isSame(now, "day")) {
                      return {
                        disabledHours: () =>
                          Array.from({ length: now.hours() }, (_, i) => i),
                        disabledMinutes: () => {
                          // Only disable minutes for the current hour
                          if (current.hours() === now.hours()) {
                            return Array.from(
                              { length: now.minutes() },
                              (_, i) => i
                            );
                          }
                          return [];
                        },
                      };
                    }
                    return {};
                  }}
                />
              </div>
            }
            showArrow={false}
            showCancel={false}
            okText="confrim"
          >
            <Popover
              content={
                <p>
                  Playlist will be refreshed monthly on the
                  scheduled date and time
                </p>
              }
              placement="bottomLeft"
            >
              <button
                onClick={() => {
                  console.log(props.refreshScheduleData);
                  setScheduleType("month");
                  setdatePickeropen(true);
                  // setScheduleDateTimeDatePicker("")
                }}
                className={
                  scheduleType === "month"
                    ? "scheduleButton scheduleButtonActive"
                    : "scheduleButton"
                }
              >
                <img
                  alt="notes.svg"
                  src={"./static/Calendar.png"}
                  width="24px"
                  height="24px"
                  style={{ marginRight: "5px", cursor: "pointer" }}
                />{" "}
                Monthly
              </button>
            </Popover>
          </Popconfirm>
          {/* {scheduleType === "month" && ( */}
          {props.refreshScheduleData?.nextrefreshtTime &&
          props.refreshScheduleData?.type === "month" ? (
            <p
              className="scheduleText"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              Next refresh : <br />
              {/* {getRefreshTime(scheduleDateTime, scheduleType, props?.refreshScheduleData?.nextrefreshtTime ? false : true)} */}
              {/* {getTimeAfter(scheduleType, 1)} */}
              {moment(
                Number(props.refreshScheduleData.nextrefreshtTime)
              ).format("DD/MM/YYYY hh:mm A")}{" "}
              {Intl.DateTimeFormat().resolvedOptions().timeZone}
            </p>
          ) : null}
        </div>
      </div>
      {!props?.refreshScheduleData?.nextrefreshtTime && <p className="scheduleText">
        Note: To help prevent data loss, we recommend creating a backup for this
        playlist.
      </p>}
      <div className="checkBoxSaveVersion ">
        <div
          className="checkVersion"
          style={{
            cursor: !cahngeDate && props?.refreshScheduleData?.nextrefreshtTime
              ? "not-allowed"
              : "pointer",
          }}
          onClick={() => {
            if (!cahngeDate && props?.refreshScheduleData?.nextrefreshtTime) {
              console.log(
                props?.refreshScheduleData?.nextrefreshtTime,
                "disabled"
              );
            } else {
              setSaveRestore(!saveRestore);
            }
          }}
        >
          {saveRestore ? (
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 448 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
            </svg>
          ) : null}
        </div>
        <p className="scheduleText"> Create a backup for this playlist.</p>
      </div>
      <div className="footer refreshModalFooter">
        <div className="yesNoButtons">
          {/* <FilledButtonStyle
          background="transparent"
          width="90px"
          type="button"
          onClick={handleCancel}
          className="btn1"
        >
          Cancel
        </FilledButtonStyle> */}
          {props.refreshScheduleData ? (
            <div>
              <button className="scheduleButton" onClick={showModalDelete}>
                Remove schedule
              </button>
            </div>
          ) : null}
          <FilledButtonStyle
            disabled={!cahngeDate && (scheduleLaoder || props?.refreshScheduleData?.nextrefreshtTime)}
            type="submit"
            className="btn2 confirmScheduleBtn"
            style={{
              width: "180px",
              cursor: !scheduleType
                ? "not-allowed !important"
                : "pointer !important",
            }}
            onClick={() => {
              ScheduleRefreshFn();
            }}
          >
            {scheduleLaoder ? <Spinner /> : `Confirm`}
          </FilledButtonStyle>
        </div>
      </div>
    </ScheduleModalStyle>
  );
}

export default ScheduleRefreshModal;
