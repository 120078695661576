import React, { useEffect, useState } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PageHeader } from "../../components";
import CardsList from "../../components/CardsList/CardsList";
import PlaylistCard from "../../components/PlaylistCard/NewCardList";
import SectionWrapperNew from "../../components/SectionWrapper/SectionWrapperNew";
import DeleteModal from "../../components/Subscriptions/CancelSubscription";
import SuccessSubscription from "../../components/Subscriptions/SuccessSubscription";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { HomeStyle } from "./Home.style";
import { truncateText } from "../../utils";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const { state } = usePlaylistContext();
  const [playlist, setPlaylist] = useState([]);

  useEffect(() => {
    const spotifyUser = JSON.parse(localStorage.getItem("spotifyUser"));
    let arr = state?.playlists?.filter(obj=>obj?.owner?.display_name === spotifyUser?.username)
    setPlaylist(arr);
  }, [state?.playlists]);

  const [isModalOpenCancelSubscription, setIsModalOpenCancelSubscription] =
    useState(false);

  const showModalCancelSubscription = () => {
    setIsModalOpenCancelSubscription(true);
  };

  const handleOkCancelSubscription = () => {
    setIsModalOpenCancelSubscription(false);
    showModalSubscriptionSuccess();
  };

  const handleCancelCancelSubscription = () => {
    setIsModalOpenCancelSubscription(false);
  };

  const [isModalOpenSubscriptionSuccess, setIsModalOpenSubscriptionSuccess] =
    useState(false);

  const showModalSubscriptionSuccess = () => {
    setIsModalOpenSubscriptionSuccess(true);
  };

  const handleOkSubscriptionSuccess = () => {
    setIsModalOpenSubscriptionSuccess(false);
  };

  const handleCancelSubscriptionSuccess = () => {
    setIsModalOpenSubscriptionSuccess(false);
  };



  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(()=>{
    console.log(playlist,'playlistplaylist')
  },[playlist])

  return (
    <>
      {/* <InnerPageLayout> */}
        <HomeStyle>
          <SectionWrapperNew heading={"Playlists"} number={playlist.length}>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              centerMode={true}
              arrows={false}
              responsive={responsive}
              // additionalTransfrom={-8 * playlist.length}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="transform 300ms ease-in-out"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              // deviceType={this.props.deviceType}
              itemClass="carousel-item-padding-8-px"
              className="artistCarousel"
              
              
            >
              {playlist?.map((data, index) => {
                const { id, name, tracks } = data;
                return (
                  <div style={{}} className="diver">
                    {/* <Link to={`/playlist/${id}`}> */}
                    <PlaylistCard
                      playlistId={id}
                      heading={name}
                      number={index}
                      subheading={`${tracks?.total} songs in total from spotify`}
                    />
                    {/* </Link> */}
                  </div>
                );
              })}
            </Carousel>
          </SectionWrapperNew>
          <div className="sc-home-padding-bottom">
            <div className="setPlayingMusicCardStyle1">
              {playlist?.map((data, index) => {
                const { id, tracks } = data;

                return index === 0 ? (
                  <>
                    <PageHeader
                      title="Songplace - The best platform to manage your Spotify playlists"
                      description="The best platform to manage your Spotify playlists."
                      image="./static/mini-logo.png"
                    />

                    <CardsList
                      playlistId={id}
                      heading={truncateText(data.name,23)}
                      subheading={`${tracks?.total} songs in total from spotify`}
                      grid={"33%"}
                      gridgap={"20px"}
                      gridStyle={"table"}
                    />
                  </>
                ) :   index === 1 ?
                <>
              <PageHeader
                title="Songplace - The best platform to manage your Spotify playlists"
                description="The best platform to manage your Spotify playlists."
                image="./static/mini-logo.png"
              />

              <CardsList
              className={"bigCard"}
                playlistId={""}
                playlistData={playlist}
                heading={"Song"}
                subheading={``}
                grid={"1fr 1fr 1fr 1fr 1fr"}
                gridgap={"40px"}
                gridStyle={"box"}
              />
              </>:
              null;
              })}
            </div>
            <div className="setPlayingMusicCardStyle2">
              {playlist?.map((data, index) => {
                const { id, tracks } = data;

                return index === 0 ? (
                  <>
                    <PageHeader
                      title="Songplace - The best platform to manage your Spotify playlists"
                      description="The best platform to manage your Spotify playlists."
                      image="./static/mini-logo.png"
                    />

                    <CardsList
                      playlistId={id}
                      heading={"Recent"}
                      subheading={`${tracks?.total} songs in total from spotify`}
                      grid={"1fr 1fr 1fr"}
                      gridgap={"20px"}
                      gridStyle={"table"}
                    />
                  </>
                ) : index === 1 ? (
                  <>
                    <PageHeader
                      title="Songplace - The best platform to manage your Spotify playlists"
                      description="The best platform to manage your Spotify playlists."
                      image="./static/mini-logo.png"
                    />

                    <CardsList
                      playlistId={id}
                      heading={"Song"}
                      subheading={`${tracks?.total} songs in total from spotify`}
                      grid={"1fr 1fr 1fr"}
                      gridgap={"40px"}
                      gridStyle={"box"}
                    />
                  </>
                ) : null;
              })}
            </div>
          </div>
        </HomeStyle>
      {/* </InnerPageLayout> */}

      <DeleteModal
        handleCancel={handleCancelCancelSubscription}
        showModal={showModalCancelSubscription}
        isModalOpen={isModalOpenCancelSubscription}
        handleOk={handleOkCancelSubscription}
        heading={"Your subscription has been cancelled!"}
        headingText={"Subscribe now to enjoy your favourite playlists."}
        button1={"Subscribe"}
        button2={"Login"}
      />
      <SuccessSubscription
        handleCancelSubscriptionSuccess={handleCancelSubscriptionSuccess}
        showModal={showModalSubscriptionSuccess}
        isModalOpen={isModalOpenSubscriptionSuccess}
        handleOkSubscriptionSuccess={handleOkSubscriptionSuccess}
      />
    </>
  );
};

export default Home;
