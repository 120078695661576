import { PageHeader, SignupForm } from '../../components';
import { LoginLayOutStyle } from '../../styles/common.style';

const Signup = () => {
  return (

     <LoginLayOutStyle>
       <PageHeader
         title="Songplace - Sign up & start scheduling songs in your playlists"
         description="The best platform to manage your Spotify playlists."
         image="./static/mini-logo.png"
       />
      
       {/* {
        !isMobile() && (
          <LeftSignupSide />
        )
      }  */}
    <SignupForm />

    </LoginLayOutStyle>
  );
};

Signup.propTypes = {};

export default Signup;