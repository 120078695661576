import styled from "styled-components";

const LoginStyle = styled.div`
  background-image: url("./static/Rectangle3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  .background-col-1{
    max-width:350px;
    width:100%;
  }
  .background {
    background: #0d0d0d;
    width: 100%;
    padding: 30px;
    text-align: center;
    border-radius: 16px;
    width:100%;
  }
  .dives {
    margin-top: 5%;
  }
  .btn1 {
    padding: 15px 15px;
    fontsize: 16px;
    border-radius: 8px;
    border: 2px solid #007aff;
    background: transparent;
    font-weight: bold;
    color: #007aff;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
  }

  .btn2 {
    padding: 15px 15px;
    border-radius: 8px;
    font-weight: bold;
    margin-top: 5%;
    width: 100%;
    border: 2px solid #007aff;
    color: white;
    background: #007aff;
    font-size: 16px;
    cursor: pointer;
  }
  .expire-div {
    margin-bottom: 5%;
  }
  .h3 {
    font-size: ;
    font-weight: bold;
    color: white;
  }
  .ppp {
    color: #788c9b;
    margin-bottom: 2%;
  }
  .expire-div{
    width: 120px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }
`;
export { LoginStyle };
