import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import {
    DateRangePickerDay as MuiDateRangePickerDay,
    DateRangePickerDayProps,
} from '@mui/x-date-pickers-pro/DateRangePickerDay';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { dateformatRough, dateformatRoughURLShare } from '../../utils';
import moment from 'moment';

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
    ({
        theme,
        isHighlighting,
        isStartOfHighlighting,
        isEndOfHighlighting,
        outsideCurrentMonth,
    }) => ({
        ...(!outsideCurrentMonth &&
            isHighlighting && {
            borderRadius: 0,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
        ...(isStartOfHighlighting && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        }),
        ...(isEndOfHighlighting && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        }),
    }),
) as React.ComponentType<DateRangePickerDayProps<Dayjs>>;

export default function CustomDateRangePickerDay({ ViewDetailData,originalDate }) {
    // Initialize with Dayjs instead of Date
    const [value, setValue] = React.useState<[Dayjs, Dayjs]>([
        dayjs(),
        null
    ]);

    React.useEffect(() => {
        console.log(moment(originalDate).format("MM/DD/YYYY"),'originalDate')
        if (ViewDetailData) {
            if (originalDate && ViewDetailData.endTime === '-') {
                setValue([
                    dayjs(moment(dateformatRoughURLShare(originalDate)).format("MM/DD/YYYY")),
                    null
                ])
            } else if(originalDate && ViewDetailData.endTime){
                setValue([
                    dayjs(moment(dateformatRoughURLShare(originalDate)).format("MM/DD/YYYY")),
                    dayjs(ViewDetailData.endTime)
                ])
            }else{}
        }
    }, [ViewDetailData,originalDate])

    React.useEffect(()=>{
        console.log(value,'valuevaluevalue')
    },[value])

    const renderWeekPickerDay = (
        date: Dayjs,
        dateRangePickerDayProps: DateRangePickerDayProps<Dayjs>,
    ) => {
        return <DateRangePickerDay {...dateRangePickerDayProps} />;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                // @ts-ignore
                label="date range"
                disabled
                value={value}
                calendars={1}
                onChange={(newValue) => setValue(newValue as [Dayjs, Dayjs])} // Ensure type [Dayjs, Dayjs]
                renderDay={renderWeekPickerDay}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <TextField {...startProps} />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField {...endProps} />
                    </React.Fragment>
                )}
            />
        </LocalizationProvider>
    );
}