import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import moment from "moment";
import { AddToPlayListModalStyle } from "../AddToPlayListModal/AddToPlayListModal.style";
import {
  copyToClipboard,
  dateFormationDDMMYY,
  dateformatRough,
} from "../../utils";
import { useLoginContext } from "../../context";
// import { DateRangePicker } from 'react-date-range';
import { addYears } from "date-fns";
import CustomDateRangePickerDay from "./ShareScheduleCalender";
import axios from "axios";
import { BASE_URL } from "../../constants/api-urls";
import Spinner from "../Spinner/Spinner";
import { useNavigate } from "react-router";
interface ShareModalProp {
  isModalVisible?: any;
  setIsModalVisible?: any;
  editType?: any;
  ViewDetailData?: any;
  setViewDetailData?: any;
  currentPlaylistData?: any;
  PlaylistRefreshRecord?: any;
  showCopyUrl?: any;
  scheduleSong?: any;
  detailLoader?: any;
}
function ShareModal({
  isModalVisible,
  setIsModalVisible,
  editType,
  ViewDetailData,
  setViewDetailData,
  currentPlaylistData,
  PlaylistRefreshRecord,
  showCopyUrl,
  scheduleSong,
  detailLoader
}: ShareModalProp) {
  const [notes, setNotes] = useState("");
  const [copied, setCopied] = useState(false);
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();
  const [originalDate, setOriginalDate] = useState("");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: addYears(new Date(), 1), // Adds 1 year to the current date
    key: "selection",
  });
  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (setViewDetailData) {
      setViewDetailData("");
    }
  };
  const handleCancel = (e: any) => {
    e.stopPropagation();
    setIsModalVisible(false);
    if (setViewDetailData) {
      setViewDetailData("");
    }
    setOriginalDate("");
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (ViewDetailData?.notes) {
      setNotes(ViewDetailData.notes);
    } else {
      setNotes("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ViewDetailData?.notes]);
  useEffect(() => {
    console.log(
      { ViewDetailData, currentPlaylistData, PlaylistRefreshRecord },
      "checkechecolkce"
    );

    // @ts-ignore
    const url = new URL(window.location);
    const originalDateLS = url.searchParams.get("originalDate");
    if (scheduleSong) {
      // if (PlaylistRefreshRecord.length) {
      //     console.log(PlaylistRefreshRecord, 'PlaylistRefreshRecord');

      //     // Example logic after fetching the PlaylistRefreshRecord
      //     const record = PlaylistRefreshRecord[0]?.addeddUri;
      //     let date = '';

      //     if (record) {
      //         const filteredRecord = JSON.parse(record).filter((obj) => obj.uri === ViewDetailData?.song_uri);
      //         const targetDate = filteredRecord.length ? filteredRecord[0]?.date : ViewDetailData?.started_time;

      //         date = moment(targetDate).format("DD/MM/YYYY");
      //     } else {
      //         if (ViewDetailData?.started_time) {
      //             date = moment(ViewDetailData?.started_time).format("DD/MM/YYYY");
      //         } else {
      //             date = moment(currentPlaylistData?.tracks?.items[0]?.added_at).format("DD/MM/YYYY")
      //         }
      //     }
      //     console.log(date, 'datedatedatedate')
      //     if (originalDate) {
      //         setOriginalDate(dateformatRough(originalDate))
      //     } else if (date) {
      //         setOriginalDate(date);
      //     }
      // }

      console.log(originalDateLS, 'asdasdas')

      setOriginalDate(
        originalDateLS
          ? originalDateLS
          : PlaylistRefreshRecord?.length
            ? PlaylistRefreshRecord[0].addeddUri
              ?
              JSON.parse(PlaylistRefreshRecord[0]?.addeddUri).filter(
                (obj) => obj.uri === ViewDetailData?.song_uri
              ).length
                ? JSON.parse(PlaylistRefreshRecord[0]?.addeddUri).filter(
                  (obj) => obj.uri === ViewDetailData?.song_uri
                )[0]?.date
                : ViewDetailData?.started_time

              : ViewDetailData?.started_time
            : ViewDetailData?.started_time
              ? ViewDetailData?.started_time
              : currentPlaylistData?.tracks?.items[0]?.added_at
      );
    } else {
      // if (PlaylistRefreshRecord.length) {
      //     console.log(PlaylistRefreshRecord, 'PlaylistRefreshRecord');

      //     // Example logic after fetching the PlaylistRefreshRecord
      //     const record = PlaylistRefreshRecord[0]?.addeddUri;
      //     let date = '';

      //     if (record) {
      //         const filteredRecord = JSON.parse(record).filter((obj) => obj.uri === ViewDetailData?.track?.uri);
      //         const targetDate = filteredRecord.length ? filteredRecord[0]?.date : ViewDetailData?.added_at;
      //         console.log(119,'datedatedatedate')
      //         date = moment(targetDate).format("DD/MM/YYYY");
      //     } else {
      //         if (ViewDetailData?.added_at) {
      //             console.log(123,'datedatedatedate')
      //             date = moment(ViewDetailData?.added_at).format("DD/MM/YYYY");
      //         } else {
      //             console.log(126,'datedatedatedate')
      //             date = moment(dateFormationDDMMYY(dateformatRough(originalDate)) || currentPlaylistData?.tracks?.items[0]?.added_at).format("DD/MM/YYYY")
      //         }
      //     }
      //     console.log(date, 'datedatedatedate')
      //     if (originalDate) {
      //         setOriginalDate(dateformatRough(originalDate))
      //     } else if (date) {
      //         setOriginalDate(date);
      //     }
      // }
      console.log(PlaylistRefreshRecord, ViewDetailData, currentPlaylistData, 'sadadasdadas')
      console.log(
        originalDateLS
          ? originalDateLS
          : PlaylistRefreshRecord.length
            ? PlaylistRefreshRecord[0].addeddUri
              ?
              JSON.parse(PlaylistRefreshRecord[0]?.addeddUri).filter(
                (obj) => obj.uri === ViewDetailData?.track?.uri
              ).length
                ? JSON.parse(PlaylistRefreshRecord[0]?.addeddUri).filter(
                  (obj) => obj.uri === ViewDetailData?.track?.uri
                )[0]?.date
                : ViewDetailData?.added_at

              : moment(ViewDetailData?.added_at).format("DD-MM-YY, h:mm a")
            : ViewDetailData?.added_at
              ? moment(ViewDetailData?.added_at).format("DD-MM-YY, h:mm a")
              : moment(
                originalDateLS || currentPlaylistData?.tracks?.items[0]?.added_at
              ).format("DD-MM-YY, h:mm a") + 'bv', ViewDetailData?.endTime, 'dasdsdasdasdasd'
      );
      setOriginalDate(
        originalDateLS
          ? originalDateLS
          : PlaylistRefreshRecord.length
            ? PlaylistRefreshRecord[0].addeddUri
              ?
              JSON.parse(PlaylistRefreshRecord[0]?.addeddUri).filter(
                (obj) => obj.uri === ViewDetailData?.track?.uri
              ).length
                ? JSON.parse(PlaylistRefreshRecord[0]?.addeddUri).filter(
                  (obj) => obj.uri === ViewDetailData?.track?.uri
                )[0]?.date
                : ViewDetailData?.added_at

              : ViewDetailData?.added_at
            : ViewDetailData?.added_at
              ? ViewDetailData?.added_at
              :
              originalDateLS || currentPlaylistData?.tracks?.items[0]?.added_at

      );
    }
  }, [PlaylistRefreshRecord.length, ViewDetailData, scheduleSong]);

  const [shareUrlLoader, setShareUrlLoader] = useState(false)
  const navigate = useNavigate();
  const [url, setUrl] = useState('')
  useEffect(() => {
    if (isModalVisible) {

      if (ViewDetailData) {

        let obj = {
          uri: ViewDetailData?.song_uri || currentPlaylistData?.uri,
          playlistId:
            ViewDetailData?.selected_playlist_id || currentPlaylistData?.id,
          id: ViewDetailData?.id,
          ref: loginState.refreshToken,
          position: ViewDetailData?.position,
          originalDate: originalDate,
          endTime: moment(ViewDetailData?.endTime).format("DD-MM-YY, hh:mm A"),
        };
        axios(`${BASE_URL}/createShareLinkToken/`, {
          data: obj,
          method: "post",
        }).then((res) => {
          console.log(window?.location?.origin + "/track-detail?token=" + res.data.data.token)
          setUrl(window?.location?.origin + "/track-detail?token=" + res.data.data.token);
        });
      }
    }
  }, [ViewDetailData, isModalVisible])
  const getShareURL = () => {
    // setShareUrlLoader(true)
    setCopied(true);
    setShareUrlLoader(false)
    copyToClipboard(url);
    setTimeout(() => {
      setCopied(false);
    }, 1000);

  };

  return (
    <AddToPlayListModalStyle
      visible={isModalVisible}
      onOk={handleOk}
      footer={null}
      onCancel={handleCancel}
      className={`tractDetailShare`}
      style={
        showCopyUrl
          ? { maxWidth: "450px", width: "100%" }
          : detailLoader ? { maxWidth: "450px", width: "100%" } : { maxWidth: "945px", width: "100%" }
      }
    >
      <>
        <div className="viewDetailInner">
          {showCopyUrl ? (
            <>

              <h1
                className="heading"
                onClick={() => {
                  console.log(ViewDetailData);
                }}
              >
                Share song details
              </h1>
              <div className="note songURlCopy">
                <Popover
                  content={
                    <span className="popSpan">
                      {url}
                    </span>
                  }
                  placement="bottomLeft"
                >
                  <span
                    onClick={() => {
                      console.log(loginState, "loginStateloginState");
                    }}
                  >
                    {url}
                    {/* {window?.location?.origin +
                    "/track-detail?uri=" +
                    (ViewDetailData?.song_uri || currentPlaylistData?.uri) +
                    "&playlistId=" +
                    (ViewDetailData?.selected_playlist_id ||
                      currentPlaylistData?.id) +
                    "&id=" +
                    ViewDetailData?.id +
                    "&ref=" +
                    loginState.refreshToken +
                    "&originalDate=" +
                    originalDate +
                    "&endTime" +
                    moment(ViewDetailData.endTime).format("DD-MM-YY, hh:mm A")} */}
                  </span>
                </Popover>
                <Popover
                  overlayClassName="copyPop"
                  content={<p>{copied ? "Copied" : "Copy"}</p>}
                  placement="topLeft"
                >
                  <button className="copyUrlSong" onClick={getShareURL}>
                    Copy
                  </button>
                </Popover>
              </div>

            </>
          ) : (
            <>
              {detailLoader ?
                <>
                  <div className="viewDetailInner">
                    <>
                      <div
                        className="addToPlaylistHead"
                        style={{ border: "none", padding: 0 }}
                      >
                        <h1 className="heading headingCenter">
                          {/* {"Song detail"} */}
                        </h1>
                      </div>
                      <div className="loadingDiv">
                        <div className="loadingCircle"></div>
                      </div>
                      <p className="refreshText" style={{ marginTop: '15px' }}>
                        {"Just a moment"}
                      </p>
                    </>
                  </div>
                </>
                :
                <>
                  <img
                    className="logo-image shareSongLogo"
                    src="./static/songplace.png"
                    alt="songplace-logo"
                    width="254"
                    height="51"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate('/')
                    }}
                  />
                  <h1
                    className="heading"
                    onClick={() => {
                      console.log(ViewDetailData);
                    }}
                  >
                    Song details
                  </h1>
                  <div className="songDetailsInner">
                    <div className="songDetailTime">
                      <div className="songDetail">
                        <div className="albumDiv">
                          <img
                            alt={
                              ViewDetailData?.track?.name || ViewDetailData?.name
                            }
                            className="albumPic"
                            src={
                              ViewDetailData?.track?.album?.images[0]?.url ||
                              ViewDetailData?.album?.images[0]?.url
                            }
                          />
                          <div className="albumDetail">
                            <h3>
                              {ViewDetailData?.track?.name || ViewDetailData?.name}
                            </h3>
                            {ViewDetailData?.artists ||
                              ViewDetailData?.track?.artists[0]?.name ? (
                              <p>
                                {ViewDetailData?.track?.artists[0]?.name ||
                                  ViewDetailData?.artists[0]?.name}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="dateDiv">
                          <div className="date">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.8889 0C16.5639 0 17.1111 0.547213 17.1111 1.22222V2.44444H19.5556C20.9056 2.44444 22 3.53886 22 4.88889V19.5556C22 20.9056 20.9056 22 19.5556 22H2.44444C1.09441 22 0 20.9056 0 19.5556V4.88889C0 3.53886 1.09441 2.44444 2.44444 2.44444H4.88889V1.22222C4.88889 0.547213 5.43609 0 6.11111 0C6.78613 0 7.33333 0.547213 7.33333 1.22222V2.44444H14.6667V1.22222C14.6667 0.547213 15.2139 0 15.8889 0ZM19.5556 4.88889H2.44444V19.5556H19.5556V4.88889ZM14.4517 7.79621C14.929 7.31891 15.7029 7.31891 16.1801 7.79621C16.6575 8.27352 16.6575 9.04738 16.1801 9.52466L10.1392 15.5657C9.65702 16.0478 8.87541 16.0478 8.39338 15.5657L5.8093 12.9817C5.33198 12.5043 5.33198 11.7305 5.8093 11.2531C6.2866 10.7758 7.06047 10.7758 7.53777 11.2531L9.26628 12.9817L14.4517 7.79621Z"
                                fill="white"
                              />
                            </svg>
                            {moment(originalDate).format(
                              "DD-MM-YY, h:mm a"
                            )}
                          </div>
                          <div className="dateText">Original start date</div>
                        </div>
                        <div style={{ padding: "6px 32px" }}>
                          <svg
                            width="11"
                            height="33"
                            viewBox="0 0 11 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="5.20833"
                              cy="5.20833"
                              r="5.20833"
                              fill="#D9D9D9"
                            />
                            <circle
                              cx="5.125"
                              cy="19.125"
                              r="3.125"
                              fill="#D9D9D9"
                              fill-opacity="0.7"
                            />
                            <circle
                              cx="5.08333"
                              cy="30.0833"
                              r="2.08333"
                              fill="#D9D9D9"
                              fill-opacity="0.3"
                            />
                          </svg>
                        </div>
                        <div className="dateDiv">
                          <div className="date">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.8889 0C16.5639 0 17.1111 0.547213 17.1111 1.22222V2.44444H19.5556C20.9056 2.44444 22 3.53886 22 4.88889V19.5556C22 20.9056 20.9056 22 19.5556 22H2.44444C1.09441 22 0 20.9056 0 19.5556V4.88889C0 3.53886 1.09441 2.44444 2.44444 2.44444H4.88889V1.22222C4.88889 0.547213 5.43609 0 6.11111 0C6.78613 0 7.33333 0.547213 7.33333 1.22222V2.44444H14.6667V1.22222C14.6667 0.547213 15.2139 0 15.8889 0ZM19.5556 4.88889H2.44444V19.5556H19.5556V4.88889ZM14.4517 7.79621C14.929 7.31891 15.7029 7.31891 16.1801 7.79621C16.6575 8.27352 16.6575 9.04738 16.1801 9.52466L10.1392 15.5657C9.65702 16.0478 8.87541 16.0478 8.39338 15.5657L5.8093 12.9817C5.33198 12.5043 5.33198 11.7305 5.8093 11.2531C6.2866 10.7758 7.06047 10.7758 7.53777 11.2531L9.26628 12.9817L14.4517 7.79621Z"
                                fill="white"
                              />
                            </svg>
                            {ViewDetailData?.endTime
                              ? ViewDetailData?.endTime === "-"
                                ? "Not decided"
                                : moment(ViewDetailData?.endTime).format(
                                  "DD-MM-YY, h:mm a"
                                )
                              : "Not decided"}
                          </div>
                          <div
                            className="dateText"
                            onClick={() => {
                              console.log(
                                ViewDetailData,
                                "ViewDetailDataViewDetailData"
                              );
                            }}
                          >
                            End date
                          </div>
                        </div>
                      </div>
                      <div className="calender">
                        <CustomDateRangePickerDay
                          ViewDetailData={ViewDetailData}
                          originalDate={originalDate}
                        />
                        {/* <DateRangePicker
      ranges={[selectionRange]}
      onChange={handleSelect}
    /> */}
                      </div>
                    </div>
                    <div className="playlistPositionDetail">
                      <div className="playlistPositionDiv">
                        <p>Playlist</p>
                        <p>{currentPlaylistData?.name}</p>
                      </div>
                      <div className="playlistPositionDiv">
                        <p>Position</p>
                        <p>
                          {Number(ViewDetailData?.position) >= 0
                            ? Number(ViewDetailData?.position)
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="SongplaceText">
                    Get your music and playlists managed through Songplace.{" "}
                    <a href={window?.location?.origin} target="_blank">
                      Learn more
                    </a>
                  </p>
                </>
              }
            </>
          )}

          {/* <div className="albumDiv">
                        <img
                            alt={ViewDetailData?.track?.name || ViewDetailData?.name}
                            className="albumPic"
                            src={ViewDetailData?.track?.album?.images[0]?.url || ViewDetailData?.album?.images[0]?.url}
                        />
                        <div className="albumDetail">
                            <h3>{ViewDetailData?.track?.name || ViewDetailData?.name}</h3>
                            {ViewDetailData?.artists || ViewDetailData?.track?.artists[0]?.name ?
                                <p>{ViewDetailData?.track?.artists[0]?.name || ViewDetailData?.artists[0]?.name}</p>
                                : null}
                        </div>
                    </div>
                    <div className="detailBoxes1">
                        <div className="box">
                            <p>Song artist</p>
                            {ViewDetailData?.artists || ViewDetailData?.track?.artists[0]?.name ?
                                <h4>{ViewDetailData?.track?.artists[0]?.name || ViewDetailData?.artists[0]?.name}</h4>
                                : null}
                        </div>
                        <div className="box">
                            <p>Song position</p>
                            <h4>{Number(ViewDetailData?.position) > 0 ? Number(ViewDetailData?.position) + 1 : "-"}</h4>
                        </div>
                        <div className="box">
                            <p
                            >
                                Playlist
                            </p>
                            <Popover
                                content={<p>{currentPlaylistData?.name}</p>}
                                placement="topLeft"
                            >
                                <h4 className="playlistNameViewDetail">
                                    {currentPlaylistData?.name}
                                </h4>
                            </Popover>
                        </div>
                    </div>
                    <div className="detailBoxes2">
                        <div className="box">
                            <p>Original Start date</p>
                            <h4>
                                {originalDate}
                           
                            </h4>
                        </div>
                        <div className="box">
                            <p>End date</p>
                            <h4>
                                {moment(ViewDetailData?.endTime).format("DD/MM/YYYY") !==
                                    "Invalid date"
                                    ? moment(ViewDetailData?.endTime).format("DD/MM/YYYY")
                                    : "-"}
                            </h4>
                        </div>
                        <div className="box">
                            <p onClick={() => {
                                console.log(ViewDetailData, 'PlaylistRefreshRecord')
                            }}>Song popularity</p>
                            <h4>
                                {ViewDetailData?.popularity}
                            </h4>
                        </div>
                    </div>
                    {notes && (
                        <div className="note">
                            <h3>Note</h3>
                            <p>{notes}</p>
                        </div>
                    )}
                    {showCopyUrl && (


                        <div className="note songURlCopy">
                            <p onClick={() => {
                                console.log(loginState, 'loginStateloginState')
                            }}>{window?.location?.origin + "/track-detail?uri=" + (ViewDetailData?.song_uri || currentPlaylistData?.uri) + "&playlistId=" + (ViewDetailData?.selected_playlist_id || currentPlaylistData?.id) + '&id=' + ViewDetailData?.id + '&ref=' + loginState.refreshToken + '&originalDate=' + originalDate  + '&endTime' + moment(ViewDetailData.endTime).format('DD-MM-YY, h:mm a')}</p>
                            <Popover
                                overlayClassName='copyPop'
                                content={<p>{copied ? 'Copied' : 'Copy'}</p>}
                                placement="topLeft"
                            >
                                <button className='copyUrlSong' onClick={() => {
                                    setCopied(true)
                                    copyToClipboard(window?.location?.origin + "/track-detail?uri=" + (ViewDetailData?.song_uri || currentPlaylistData?.uri) + "&playlistId=" + (ViewDetailData?.selected_playlist_id || currentPlaylistData?.id) + '&id=' + ViewDetailData?.id + '&ref=' + loginState.refreshToken + '&position=' + ViewDetailData?.position + '&originalDate=' + originalDate)
                                    setTimeout(() => {
                                        setCopied(false)
                                    }, 1000)
                                }}>Copy</button>
                            </Popover>
                        </div>
                    )} */}
        </div>
      </>
    </AddToPlayListModalStyle>
  );
}

export default ShareModal;
