import { Modal } from "antd";
import React from "react";
interface LoginModalProps {
  header: string;
  para: any;
  isOpen: boolean;
  object: any;
  setObject: any;
  onClose: () => void;
  onChange: () => void;
  onLogin: (boolean) => void;
  handleGoogleFormSubmit: () => void;
  loader:boolean
}

const LoginModal: React.FC<LoginModalProps> = ({
  header,
  para,
  isOpen,
  onClose,
  onChange,
  onLogin,
  setObject,
  object,
  handleGoogleFormSubmit,
  loader
}) => {
  
  return (
    // <LoginModalStyle>
    <Modal footer={false} className="login-component" centered open={isOpen}>
      <div className="login-modal">
        <div className="login-heading-container">
          <h1 className="login-heading">{header}</h1>
          <img alt="close.svg" src="./static/close.svg" onClick={onClose} />
        </div>
        {/* <p className="login-heading-description">
          Please fill the form below to receive a quote for your project. Feel
          free to add as much detail as needed.
        </p> */}
        <div className="login-modal-inner">
          <div className="login-socail">
            <button
              className="combine-button"
              style={{ cursor: "pointer" }}
              onClick={() => handleGoogleFormSubmit()}
            >
              <span className="btn-svg">
                <img alt="google.svg" src="./static/google.svg" />
              </span>
              <div className="btn-text">Sign in with Google</div>
            </button>
            <button className="combine-button" style={{ cursor: "pointer" }}>
              <span className="btn-svg">
                <img alt="facebook.svg" src="./static/facebook.svg" />
              </span>
              <div className="btn-text">Sign in with Facebook</div>
            </button>
          </div>
          <p className="login-or">Or</p>
          <div className="main-form-login">
            <label>Email</label>
            <input
              placeholder="Email address"
              value={object?.email}
              onChange={(ev: any) =>
                setObject({ ...object, email: ev.target.value })
              }
            />
            <label>Password</label>
            <input
              placeholder="Password"
              value={object?.password}
              type="password"
              onChange={(ev: any) =>
                setObject({ ...object, password: ev.target.value })
              }
            />
            <div className="form-login-inner">
              <div className="para-parent">
                {para}
              </div>
              {/* {header === "Login" && (
                <div className="para-parent">
                  <p>Forgot password</p>
                </div>
              )} */}
              <div className="para-parent para-parent-btn" >
                <button style={{cursor:"pointer"}} onClick={()=>onLogin(false)}>{loader ? <div className="donut"></div> : `${header}`}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    // </LoginModalStyle>
  );
};

export default LoginModal;
