import { SyncModalStyle } from "./SyncModal.style";

function SyncModal({ isModalVisible, setIsModalVisible }) {
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(handleCancel);
  };
  return (
    <SyncModalStyle
      visible={isModalVisible}
      onOk={handleOk}
      footer={null}
     onCancel={handleCancel}
     className="syncModal"
    >
      <>
        <div
          className="addToPlaylistHead"
          style={{ border: "none", padding: 0 }}
        >
          <h1 className="heading headingCenter">
             Please wait while we are processing your request
          </h1>
        </div>
        <div className="loadingDiv">
          <div className="loadingCircle"></div>
        </div>
      </>
    </SyncModalStyle>
  );
}

export default SyncModal;
