import styled from "styled-components";

const LeftLoginSideStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: #007aff;
  background-image: url("./static/circle.png");
  background-size: 540px 520px;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;

  .images-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .first-image {
    width: 490px;
  }

  .screen-image {
    width: 408px;
    height: 251px;
    position: absolute;
    top: 23px;
  }

  .heading {
    font-size: 28px;
    line-height: 42px;
    font-weight: 600;
    position: relative;
    bottom: 65px;
    color: ${(props) => props.theme.colors.primary};
    margin-top: 1.5%;
  }

  .sub-heading {
    font-size: ;
    font-weight: 500;
    line-height: 30px;
    color: ${(props) => props.theme.colors.primary};
  }

  .description {
    font-size: 14px;
    padding: 1rem 2rem;
  }

  .text-below-wrapper {
    margin-top: 40px;
  }
`;
export default LeftLoginSideStyle;
