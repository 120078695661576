import axios from "axios";
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
  useRef
} from "react";
import { SPOTIFY_TOKEN } from "../constants/storage";
import { addItemToStorage, getRefreshedToken, isEmptyOrNil } from "../utils";

import { useLoginContext } from "./LoginContext";
// import { PaginationStyle } from "../pages/Playlist/Playlist.style";

type PlaylistAction =
  | {
    type: "INITIALIZE_PLAYLISTS";
    payload: any;
  }
  | {
    type: "SET_PREVIEW_SONG";
    payload: any;
  }
  | {
    type: "SET_SEARCHED_SONG";
    payload: string;
  }
  | {
    type: "SET_SEARCHED_SONG_DATA";
    payload: any;
  }
  | {
    type: "SET_CURRENT_PLAYLIST";
    payload: any;
  }
  | {
    type: "DELETE_CURRENT_PLAYLIST_SONG";
    payload: number | string;
  }
  | {
    type: "SET_SELECTED_PLAYLIST_ID";
    payload: string;
  }
  | {
    type: "TOGGLE_BULK_MODE";
    payload: boolean;
  }
  | {
    type: "TOGGLE_SONG_PLACE";
    payload: boolean;
  }
  | {
    type: "TOGGLE_WHOLE_THEME";
    payload: boolean;
  }
  | {
    type: "SET_SONGS_SEARCH_LIST";
    payload: any;
  }
  | {
    type: "SET_PLANS_PRICING";
    payload: any;
  }
  | {
    type: "UPDATE_SONGS_SEARCH_LIST";
    payload: any;
  };

export type PlaylistContextType = {
  state: any;
  dispatch: Dispatch<PlaylistAction>;
  tableRef: any;
};

export type PlaylistContextTypes = {
  states: any;
  dispatch: Dispatch<PlaylistAction>;
  tableRef: any;
};

const theme = JSON.parse(localStorage.getItem("theme"));

const initialContent: any = {
  isBulkMode: false,
  isSongPlace: false,
  isWholeTheme: !theme ? false : theme,
  playlists: [],
  currentPlaylist: [],
  searchedSongs: [],
  proPlansPrices: [],
  unlimitedPlansPrices: [],
  monthly_pro_price: [],
  annually_pro_price: [],
  monthly_unlimited_price: [],
  annually_unlimited_price: [],
  searchSong: "",
  searchedSongData: {},
  previewSong: {
    url: "",
    id: "",
    name: "",
    artist: "",
    image: "",
    external: "",
  },
  audio: new Audio(),
  selectedPlaylistId: "",
};

export const PlaylistContext = createContext<PlaylistContextType>({
  state: {
    ...initialContent,
  },
  dispatch: () => undefined,
  tableRef: null
});
export const usePlaylistContext = () => useContext(PlaylistContext);

export const PlaylistContexts = createContext<PlaylistContextTypes>({
  states: {
    ...initialContent,
  },
  dispatch: () => undefined,
  tableRef: null

});
export const usePlaylistCont = () => useContext(PlaylistContexts);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "INITIALIZE_PLAYLISTS":
      return {
        ...state,
        playlists: action.payload,
      };

    case "SET_CURRENT_PLAYLIST":
      return {
        ...state,
        currentPlaylist: action.payload,
      };

    case "DELETE_CURRENT_PLAYLIST_SONG": {
      const updatedItem = state.currentPlaylist.filter(
        (_, index) => index !== action.payload
      );
      return {
        ...state,
        currentPlaylist: updatedItem,
      };
    }

    case "SET_PREVIEW_SONG":
      return {
        ...state,
        previewSong: {
          url: action.payload.url,
          id: action.payload.id,
          name: action.payload.name,
          artist: action.payload.artist,
          image: action.payload.image,
          external: action.payload.external,
        },
      };

    case "SET_SEARCHED_SONG":
      return {
        ...state,
        searchSong: action.payload,
      };

    case "SET_SEARCHED_SONG_DATA":
      return {
        ...state,
        searchedSongData: action.payload,
      };

    case "TOGGLE_BULK_MODE":
      return {
        ...state,
        isBulkMode: action.payload,
      };

    case "TOGGLE_SONG_PLACE":
      return {
        ...state,
        isSongPlace: action.payload,
      };

    case "TOGGLE_WHOLE_THEME":
      return {
        ...state,
        isWholeTheme: action.payload,
      };

    case "SET_SELECTED_PLAYLIST_ID":
      return {
        ...state,
        selectedPlaylistId: action.payload,
      };
    case "SET_SONGS_SEARCH_LIST":
      return {
        ...state,
        searchedSongs: action.payload,
      };
    case "SET_PLANS_PRICING":
      return {
        ...state,
        proPlansPrices: action.payload.proPlansPrices,
        unlimitedPlansPrices: action.payload.unlimitedPlansPrices,
        monthly_pro_price: action.payload.monthly_pro_price,
        annually_pro_price: action.payload.annually_pro_price,
        monthly_unlimited_price: action.payload.monthly_unlimited_price,
        annually_unlimited_price: action.payload.annually_unlimited_price,
      };
    case "UPDATE_SONGS_SEARCH_LIST":
      let updateList = state.searchedSongs.map((item: any, index: any) => {
        if (item.id === action.payload) {
          return {
            ...item,
            isPlaying: !item.isPlaying,
          };
        } else {
          return {
            ...item,
            isPlaying: false,
          };
        }
      });
      return {
        ...state,
        searchedSongs: updateList,
      };
    default:
      return state;
  }
};

export const PlaylistContextProvider = (props: any) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialContent);

  const { state: loginState, dispatch: loginDispatch } = useLoginContext();
  useEffect(() => {
    if (state.previewSong === "") return;
    state.audio.src = state.previewSong;
    //  state.audio.play();
  }, [state, state.previewSong]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!isEmptyOrNil(state.playlists)) return;
    if (isEmptyOrNil(loginState.token)) return;

    axios(`https://api.spotify.com/v1/me/playlists`, {
      method: "GET",
      headers: { Authorization: "Bearer " + loginState.token },
    })
      .then((genreResponse) => {
        dispatch({
          type: "INITIALIZE_PLAYLISTS",
          payload: genreResponse?.data.items,
        });
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken)
            .then((response) => {
              addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

              loginDispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            })
            .catch(() => { });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState, loginState.token, loginDispatch]);

  const tableRef = useRef()

  return (
    <PlaylistContext.Provider value={{ state, dispatch, tableRef }}>
      {children}
    </PlaylistContext.Provider>
  );
};
