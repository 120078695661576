import styled from "styled-components";
type CardsListProps = {
  grid: any;
  gridgap: any;
  gridStyle: any
};
export const CardsListStyle = styled.div<CardsListProps>`
  display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content:space-between;
  
  .divWidthauto {
      width: 33%;  
  &.bigCard{
    width:calc(100% / 4);
    }
  }


  @media (max-width: 1365px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content:space-between;
    .divWidthauto {
      width: 33%;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content:space-between;
    .divWidthauto {
      width:  ${(props) => (props.gridStyle === 'box' ? '46%' : '50%')};
    }
  }
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    .divWidthauto {
      width: 100%;
    }
  }
  @media only screen and (max-width: 353px) {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .divWidthauto {
      width: 100%;
    }
  }

  .skeleton-buttons {
    height: 260px;
    width: 230px;
    border-radius: 50px;
  }
  .skeleton-buttons1 {
    height: 330px;
    width: 220px;
    border-radius: 12px;
  }


`;
