import styled from "styled-components";

export const InnerPageLayoutStyle = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  background: ${(props) => props.theme.background.page3};
  height: 100%;
  overflow: hidden;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .skeleton-buttons {
    width: 230px;
    height: 35px;
  }

  sub-skeleton-buttons {
    width: 130px;
    height: 30px;
  }

  .page-content {
    padding: 0px 0px 20px 0px;
    max-height: 100vh;
    overflow: auto;
    flex: 1;
    width: calc(100vw - 230px);    
@media (max-width: 1500px) {
width: calc(100vw - 230px);  
}
    @media (max-width: 768px) {
      padding: 20px 0px;
    width:100vw;
      
    }
    @media (max-width: 600px) {
      padding: 0px 0;
      width:100vw;
    }
  }
  @media only screen and (max-width: 600px) {
    .page-content {
      padding: 0px 0 !important;
    }
  }
`;
