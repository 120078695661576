import React, { useState } from "react";
interface NewsLetterProps { }

const NewsLetter: React.FC<NewsLetterProps> = () => {
  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <div className="news-letter-container" id="Contact">
      <h1>Join Our Newsletter</h1>
      <p className="main-para">
        Are you a music artist? Join the mailing list to find out more information about how to manage and sort your tracks or playlists on Songplace.
      </p>
      <form className="input-container"
        action="https://spotplace.us14.list-manage.com/subscribe/post?u=5d845dc9bdd50ddec46ce928b&amp;id=9fe6ded621"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
      >
        <input placeholder="Enter your email address"
          type="email"
          value={email}
          name="EMAIL"
          className="required email"
          id="mce-EMAIL"
          onChange={handleEmailChange}
          required
        />
        <button value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe">Submit</button>
      </form>
    </div>
  );
};

export default NewsLetter;
