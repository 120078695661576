import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { toast } from "react-toastify";
import { Policies } from "../../components/PolicyModal";
import { webAuth } from "../../store/actions/playlistAction";
import NewSubscription from "../Login/NewSubscription";
import AboutUs from "./AboutUs";
import PricingPlan from "./PricingPlan";
import Features from "./features";
import FirstSection from "./firstSection";
import Footer from "./footer";
import Header from "./header";
import { LandingPageStyle } from "./landingPage.style";
import LoginModal from "./loginModal";
import NewsLetter from "./newsLetter";
interface LandingPageProps { }

const LandingPage: React.FC<LandingPageProps> = () => {

  const [loginModalIsOpen, setLoginModalIsOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [newName, setNewName] = useState("");
  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [newSubIsModalVisible, setNewSubIsModalVisible] = useState(false);
  const [loginObject, setLoginObject] = useState<any>({
    email: "",
    password: "",
  });
  const [signUpObject, setSignUpObject] = useState<any>({
    email: "",
    password: "",
  });
  const [planStatus, setPlanStatus] = useState("choosePlan");
  const [planId, setPlanId] = useState("");
  const [planPaid, setPlanPaid] = useState("");
  const [defaultCurrency, setdefaultCurrency] = useState("EUR");
  const [signUpModalIsOpen, setSignUpModalIsOpen] = useState<boolean>(false);
  const changeModal = () => {
    setLoginModalIsOpen(!loginModalIsOpen);
    setSignUpModalIsOpen(!signUpModalIsOpen);
  };
  const handleCloseLogin = () => {
    setLoginModalIsOpen(!loginModalIsOpen);
  };
  const handleCloseSignUp = () => {
    setSignUpModalIsOpen(!signUpModalIsOpen);
    // setPlanStatus('choosePlan')
  };
  const handleFormSubmit = async () => {
    const domain = "dev-2z4p074m.us.auth0.com";
    const client_id = process.env.REACT_APP_AUTH0;
    const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
    const scope = "openid profile email";
    const response_type = "token";
    const redirect_uri = `${window.location.origin}/shift?defaultCurrency=${defaultCurrency}`;
    window.location.replace(
      `https://${domain}/authorize?` +
      `audience=${audience}&` +
      `scope=${scope}&` +
      `response_type=${response_type}&` +
      `client_id=${client_id}&` +
      `redirect_uri=${redirect_uri}`
    );
  };
  const loginFn = (signup) => {

    let objData = signup ? { ...signup } : { ...loginObject };
    setLoader(true);
    webAuth.login(
      {
        realm: `Username-Password-Authentication`,
        responseType: `token`,
        responseMode: `fragment`,
        redirectUri: `${window.location.origin}/shift?plan=${planStatus}${planId ? `&planId=${planId}` : ""
          }${planPaid ? `&planPaid=${planPaid}` : ""
          }&defaultCurrency=${defaultCurrency}`,
        email: objData?.email,
        password: objData?.password,
      },
      function (err: any, authResult: any) {
        if (err) {
          setLoader(false);
          toast.error(err?.error_description || "Invalid error");
          return;
        }
        // if (authResult) {
        if (authResult && authResult.accessToken) {
          setLoginObject({
            email: "",
            password: "",
          });
        }
      }
    );
  };
  const signUpFn = () => {
    webAuth.signup(
      {
        connection: `Username-Password-Authentication`,
        email: signUpObject?.email,
        password: signUpObject?.password
      },
      async (err: any, result: any) => {
        if (err) {
          setLoader(false);
          return;
        }
        if (result) {
          toast.success("Your account has been created");
          loginFn(signUpObject);
          setSignUpObject({
            email: "",
            password: "",
          });
        }
      }
    );
  };
  const handleAdd = (value: any) => {
    setNewName(value);
    setAddModalVisible(true);
  };
  const handleGoogleFormSubmit = async () => {
    const domain = "dev-2z4p074m.us.auth0.com";
    const client_id = process.env.REACT_APP_AUTH0;
    const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
    const scope = "openid profile email";
    const response_type = "token";
    const CONNECTION = "google-oauth2";
    const redirect_uri = `${window.location.origin}/shift`;
    window.location.replace(
      `https://${domain}/authorize?` +
      `audience=${audience}&` +
      `connection=${CONNECTION}&` +
      `scope=${scope}&` +
      `response_type=${response_type}&` +
      `client_id=${client_id}&` +
      `redirect_uri=${redirect_uri}`
    );
  };


  useEffect(() => {
    const alreadyVisited = localStorage.getItem('alreadyVisited')
    if (!alreadyVisited) {
      setNewSubIsModalVisible(true)
      localStorage.setItem('alreadyVisited', "true")
    }
  }, [])
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".row");
    const scrollTop = window.scrollY;
    scrollTop >= 50
      ? header.classList.add("scroll-header")
      : header.classList.remove("scroll-header");
  };
  return (
    <LandingPageStyle>
      <Header onOpen={handleFormSubmit} onOpenSignUp={handleFormSubmit} />
      <FirstSection onOpen={handleFormSubmit} onOpenSignUp={handleFormSubmit} />
      <AboutUs />
      <Features />
      <PricingPlan
        blob={true}
        setSignUpModalIsOpen={handleFormSubmit}
        setPlanStatus={setPlanStatus}
        setPlanId={setPlanId}
        setPlanPaid={setPlanPaid}
        planStatus={planStatus}
        planId={planId}
        planPaid={planPaid}
        defaultCurrency={defaultCurrency}
        setdefaultCurrency={setdefaultCurrency}
      />
      <NewsLetter />
      <Footer handleAdd={handleAdd} />
      <img alt="blob1.png" className="blob1" src={'./static/images/blob1.webp'} />
      <LoginModal
        header={"Login"}
        isOpen={loginModalIsOpen}
        onClose={handleCloseLogin}
        onChange={changeModal}
        onLogin={loginFn}
        setObject={setLoginObject}
        object={loginObject}
        handleGoogleFormSubmit={handleGoogleFormSubmit}
        para={
          <p>
            Don’t have an account? <span onClick={changeModal}>Sign up</span>
          </p>
        }
        loader={loader}
      />
      <LoginModal
        header={"Sign Up"}
        isOpen={signUpModalIsOpen}
        onClose={handleCloseSignUp}
        onChange={changeModal}
        onLogin={signUpFn}
        setObject={setSignUpObject}
        handleGoogleFormSubmit={handleGoogleFormSubmit}
        object={signUpObject}
        para={
          <p>
            Already have an account? <span onClick={changeModal}>Log in</span>
          </p>
        }
        loader={loader}
      />
      <Policies
        setName={newName}
        isModalVisible={isAddModalVisible}
        setIsModalVisible={setAddModalVisible}
      />
      <NewSubscription
        isModalVisible={newSubIsModalVisible}
        setIsModalVisible={setNewSubIsModalVisible}
      // onCheckout={() => {
      //   setPrincingModalIsOpen(true);
      // }}
      />
    </LandingPageStyle>
  );
};

export default LandingPage;
