import { DatePicker } from "antd";
import styled from "styled-components";

interface DatePickerStyleProps {
  width: string;
  height: string;
  transparent: boolean;
}

const DatePickerStyle = styled(DatePicker)<DatePickerStyleProps>`
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  background: #333333;
  border: 1px solid #474747;
  max-width: ${(props) => (props.width ? props.width : "358px")};
  width: 100%;
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.background.inputs};
  border: 1px solid ${(props) => props.theme.background.inputsBorder};

  .ant-picker-input > input {
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-picker-suffix {
    color: ${(props) => props.theme.colors.primary};
  }
`;
export { DatePickerStyle };
