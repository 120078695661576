import * as yup from "yup";
import { SingupType } from "./types";

export const SignupFormInitialValues: SingupType = {
   username: '',
   password: '',
   confirmPassword: '',
   email: '',
   termConditions: true,
}

export const SignupValidationSchema = yup.object().shape({
   username: yup.string().required(),
   email: yup.string().required(),
   password: yup.string().required('Password is required'),
   termConditions: yup.boolean().required('You need to accept term and conditions'),
   confirmPassword: yup.string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
});