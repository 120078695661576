import { Button } from "antd";
import { useEffect, useState } from "react";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { SearchBar } from "../SearchBar";
import SwitchAccount from "./SwitchAccount";
import { TopNavbarStyle } from "./TopNavbar.style";

interface TopNavbarProps {
  showDrawer: () => void;
  onClose: () => void;
}
const TopNavbar = (props: TopNavbarProps) => {
  const { state } = usePlaylistContext();
  const [theme, setTheme] = useState("dark");


  useEffect(() => {
    setTheme(state?.isWholeTheme === false ? "dark" : "light");
  }, [state]);




  return (
    <TopNavbarStyle>
      <div className="leftclassnamepart">
        <div className="leftitemclassName1">
          <div className="left-item">
            <div className="centerLogo">
              {theme === "dark" ? (
                <img
                  className="logo-image"
                  src="./static/songplace.png"
                  alt="songplace-logo"
                  width="254"
                  height="51"
                
                />
              ) : (
                <img
                  className="logo-image"
                  id="logo-image1"
                  src="./static/logo.png"
                  alt="songplace-logo"
                />
              )}
            </div>
            <SearchBar />
            <SwitchAccount/>
            <Button className="drawerButton" onClick={props.showDrawer}>
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="0"
                viewBox="0 0 15 15"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
                  fill="currentColor"
                ></path>
              </svg>
            </Button>
          </div>
        </div>

      </div>
    </TopNavbarStyle>
  );
};

export default TopNavbar;
