import { InputBoxStyle } from "../../styles/common.style";
import { DatePickerStyle } from "./DatePicker.style";

const DatePickerWithoutFormik = (props: any) => {


  const handleChange = (data, dateString) => {
    props.onChange(data.toISOString());
  };
  
  return (
    <InputBoxStyle>
      <label>{props.label}</label>
      <DatePickerStyle
        name={props.name}
        onChange={handleChange}
        showTime
        {...props}
      />
      
      {props.error && <p className="error">{props.error}</p>}
    </InputBoxStyle>
  );
};

export default DatePickerWithoutFormik;