import React from "react";

interface AboutUsProps {}

const AboutUs: React.FC<AboutUsProps> = () => {
  return (
    <div className="about-us-Container" id="About">
      <h1> About Us</h1>
      <p>
        Songplace is the best platform to professionally manage your Spotify and
        Apple Music playlists. Whether you are a record label, an artist that
        manages playlists or a professional curator, this is the must-have tool
        for you. No longer will you have to waste countless hours of checking
        songs in your playlists, setting reminders to place or delete them, be
        busy on release day, and keep track of your playlists. With Songplace as
        the number one playlist management tool this will be all done for you!
        <br />
        Would you like to make an inquiry, or do you have any feedback or
        remarks? Don’t hesitate to reach out below:
      </p>
      <button className="SideStyleButton">Contact Us</button>
      <img alt="about-us-blob1" src="./static/aboutUS1Blob.webp" className="about-us-blob1" />
      <img alt="about-us-blob2" src="./static/aboutUS2Blob.webp" className="about-us-blob2" />
    </div>
  );
};

export default AboutUs;
