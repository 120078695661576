import { Pagination } from "antd";
import styled from "styled-components";

import { SlimScrollStyle, TableStyle } from "../../styles/common.style";

export const PlaylistStyle = styled.main`
  ${SlimScrollStyle};
  // overflow: auto;
  // max-height: 90vh;
  padding-bottom: 1%;
  .searchDetails{
    padding: 81px 60px 0px;
    margin-bottom:32px;
@media screen and (max-width: 900px){
    padding: 20px;
}
@media screen and (max-width: 768px){
    padding-bottom: 60px;
}
  }
  .innerSearchDetails{
    padding-bottom:32px;
    border-bottom: 1px solid #ffffff1a;
  }
  @media only screen and (min-width: 1366px) {
    padding-bottom: 1%;
  }
  @media only screen and (min-width: 1440px) {
    padding-bottom: 1%;
  }
  @media only screen and (min-width: 1536px) {
    padding-bottom: 1%;
  }
  @media only screen and (min-width: 1920px) {
    padding-bottom: 1%;
  }
  @media (max-width: 600px) {
    // max-height: calc(100vh - 05px);
  }

  @media only screen and (max-width: 600px) {
    padding-bottom: calc(100% - 136px);
  }

  @media only screen and (max-width: 412px) {
    padding-bottom: calc(100% - 118px);
  }

  @media only screen and (max-width: 400px) {
    padding-bottom: calc(100% - 100px);
  }

  @media only screen and (max-width: 375px) {
    padding-bottom: calc(100% - 90px);
  }

  @media only screen and (max-width: 360px) {
    padding-bottom: calc(100% - 80px);
  }
  #hancker {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    max-width: 350px;

  }

  #hancker1 {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
  .spotify-redirect {
    opacity: 0;
    transition: all 300ms linear;
  }
  .ant-table-measure-row td {
    border-bottom: 1px solid transparent !important;
  }
  .ant-table-cell-row-hover {
    background: ${(props) => props.theme.background.hoverTable} !important;
    border-bottom: 1px solid ${(props) => props.theme.background.hrBorder} !important;
  }
  .ant-empty-description {
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-modal-close {
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-message-notice-content {
    background: ${(props) => props.theme.background.primary} !important;
    /* Blue */
    border: 2px solid ${(props) => props.theme.background.primary} !important;
    border-radius: 4px !important;
    color: ${(props) => props.theme.colors.primary} !important;
    margin-top: 20px !important;
    z-index: 1000000000 !important;
    position: relative;
  }
  .ant-popover-arrow-content {
    --antd-arrow-background-color: ${(props) =>
    props.theme.background.primary} !important;
  }
  .ant-popover-inner-content {
    width: 100%;
    background: ${(props) => props.theme.background.primary} !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-table-cell-row-hover #hancker1,
  .ant-table-cell-row-hover #hancker,
  .ant-table-cell-row-hover .moreOutline {
    color: white;
  }
  .ant-table-row {
    &:hover {
      .spotify-redirect {
        opacity: 1;
        transition: all 300ms linear;
      }
    }
  }

  .ant-table .ant-table-thead {
    position: -webkit-sticky !important; /* Safari */
    position: sticky !important;
    top: 0 !important;
    z-index: 1000;
    padding: 20px;
  }

  @media (max-width: 600px) {
    .ant-table .ant-table-thead {
      z-index: 0;
    }
    .ant-table-cell,
    .ant-table-cell ant-table-cell-row-hover {
      background: ${(props) => props.theme.background.transparent1} !important;
    }
  }

  .song-title {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .song-artist {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icons {
    align-items: center;
    grid-gap: 20px;

    & > span {
      cursor: pointer;
    }
  }

  .icons1 {
    visibility: hidden;
    cursor: pointer;
  }

  .ant-pagination-item-active {
    background: #007aff !important;
  }

  .ant-table-cell-row-hover .icons1 {
    visibility: visible;
  }

  .ant-table-cell-row-hover .icons2 {
    visibility: hidden;
  }
  .ant-table-cell-row-hover .icons45 {
    color: white;
  }
  .icons45 {
    color: ${(props) => props.theme.colors.primary};
  }
  .ant-table-cell-row-hover .icons45 {
    color: white;
  }

  .ant-popover {
    z-index: 100 !important;
  }

  .ant-table .ant-table-tbody .ant-table-cell {
    padding: 6px 10px !important;
    border-bottom: 1px solid ${(props) => props.theme.background.hrBorder} !important;
  }

  ${TableStyle} {
    .ant-table-cell {
      width: 100%;
      &.ant-table-cell-row-hover {
        background: #ffffff;
      }

      &:nth-of-type(2) {
        .title {
          max-width: 40px;
        }
      }
    }
  }
  .ant-table-wrapper {
    background: ${(props) => props.theme.background.transparent1};
  }

  .ant-spin-container {
    padding: 20px;
  }
  .ant-table-body::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  .ant-table-body::-webkit-scrollbar-track {
    background: transparent !important;
  }

  /* Handle */
  .ant-table-body::-webkit-scrollbar-thumb {
    background: #007aff !important;
    border-radius: 8px !important;
  }

  /* Handle on hover */
  .ant-table-body::-webkit-scrollbar-thumb:hover {
    background: #007aff !important;
  }

  .ant-table-tbody tr:nth-child(even) {
    background: ${(props) => props.theme.background.evenTable};
  }
  .ant-table-tbody tr .ant-table-row-hover {
    border-radius: 8px !important;
    background: #ffffff;
  }
  .imageName{
    display:flex;
    align-items:center;
  }
  .imgSpan{
    width:35px;
    height:35px;
    margin-right: 10px;
  }
  .table-list-image {
        height: 28px;
    aspect-ratio: 1;
    width: 28px;
    object-fit: contain;
  }
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding-bottom: 15px !important;
  }
  .rows4 {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
  }
  .rows4:hover {
    background: rgb(196, 196, 196, 0.3);
    border-radius: 8px;
  }
  .table {
    padding-left: 5px;
  }

  .tablePlayListDiv{
    padding: 0px 60px;
  }
  .tablePlayList{
    width:100%;
  }
  .tablePlayList th{
  opacity:0.5;
  }
  .tablePlayList td,.tablePlayList th{
    color: ${(props) => props.theme.colors.primary};

    font-family: Poppins;
font-size: 10px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.11em;
text-align: left;

  }
  .tablePlayList .seriesNumber{
    width:30px;
    text-align: center;
  }
  .tablePlayList tr:hover td{
    background: #242429;
  }
  .tablePlayList tr td:first-child{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .tablePlayList tr td:last-child{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .tablePlayList td{
    padding: 6px;
  }
  .tablePlayList td h3{
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    

  }

  .blurrScreen{
    position:absolute;
    top:0px;
    left:0px;
    background: linear-gradient(157.78deg, rgba(6, 5, 5, 0.32) -0.75%, rgba(0, 0, 0, 0) 100%),
linear-gradient(169.15deg, rgba(255, 255, 255, 0.1) 0%, rgba(238, 237, 237, 0.05) 96.79%);
width:100%;
height:100%;

backdrop-filter: blur(13px);

  }


  .tablePlayList tr:hover .icons1{
    visibility: visible;
  }
  .tablePlayList tr:hover .icons2{
    visibility: hidden;
  }

  .namePlaylist #hancker:first-child{
    font-family: Poppins;
font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 200px;
    width: 100%;


  }
  .namePlaylist #hancker:last-child{
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    width: 120px;
    

  }
  .startEndDate td{
    padding:0px;
  }
  .startEndDate .startEndTime{
    display:flex;
    align-items:center;

    font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

  }
  .startEndDate  p{
    font-family: Poppins;
font-size: 10px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0.11em;
text-align: left;
color: #9CA3AF;
margin-right: 16px;

  }
  .playSpot{
    display:flex;
    align-items:center;
  }
  .playSpot img{
height: 19px;
width:19px;
    margin-right: 6px;
  }
  .playSpot p{
    font-family: Poppins;
font-size: 11px;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.03em;
text-align: left;
color:white;

  }
  .platform{
    display: flex;
    align-items: center;
    margin-bottom:24px;

    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: -0.03em;
text-align: left;
color:white;

  }
  .platform img{
    width: 70px;
    margin-bottom: 10px;
    margin-right:12px;
    object-fit: contain;
  }
  .row1 {
    display: flex;
    align-items: center;
    
  }
  .row1 .p1{
    display: flex;
    align-items: center;
  }
  .row1 .p1, .row1 svg {
    margin-left: 6px;
    color:white;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: left;
    opacity:0.7;

  }
  .row1 svg {
    width: 10px;
  }
  .row1 .p1 span{
    font-family: Poppins;
font-size: 32px;
font-weight: 500;
line-height: 48px;
letter-spacing: 0.04em;
text-align: left;
margin-left:24px;
  }
  .load-1{
    height: 40px;
  }
  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    margin-right:10px;
  }
  .load-1 .line:nth-last-child(1) {
    background-color: #005cd5;
    animation: loadingA 1.5s 1s infinite;
  }
  .load-1 .line:nth-last-child(2) {
    background-color: #2d1834;
    animation: loadingA 1.5s 0.5s infinite;
  }
  .load-1 .line:nth-last-child(3) {
    background-color: #3f132c;
    animation: loadingA 1.5s 0s infinite;
  }
  @keyframes loadingA {
    0 {
      height: 15px;
    }
    50% {
      height: 35px;
    }
    100% {
      height: 15px;
    }
  }
  .fixedHeader{
    background: #242429;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    height:100px;
    transition:0.5s;
    height:0px;
    overflow:hidden;
    &.visible{
      height:90px;
      
    }
    @media screen and (min-width:600px){
      width: calc(100% - 230px);
    }
    .playListDetail{
      height: 100%;
      display: flex;
      align-items: center;
      padding: 10px 20px;  
      .playlistImage{
        display: flex;
        align-items: center;
        
        max-width: 250px;
        min-width: 30%;
    display: flex;
    align-items: center;
    .playlistImageDiv{
      width: 65px;
      aspect-ratio: 1;
      background: black;
      color: #242429;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      border-radius: 10px;

    }

        img{
          width:100%;
      aspect-ratio:1;
      margin-right:10px
        }
        .playlistTitle{
          font-weight: 700;
          color: white;
          height: 26px;
          overflow: hidden;
          margin-left: 10px;
          font-size:15px;
        }
        .playlistDescription{
          color:white;
          height: 26px;
          overflow: hidden;
          margin-left: 10px;
          font-size: 11px;
        }
      }
      .searchBAr{
        max-width: calc(100% - 250px);
        min-width: 70%;
        display: flex;
        align-items: center;
        width: calc(100% - 250px);
        justify-content:flex-end;
    
        .searchInput{
          width: calc(100% - 180px);
          display:flex;
          align-items:center;
          justify-content:flex-end;
        }
      }
    } 
  }
  .songTableDiv{
    overflow-y: auto;
    overflow-x: hidden;
    /* width */
&::-webkit-scrollbar {
  width: 10px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: transparent !important; 
}
 
/* Handle */
&::-webkit-scrollbar-thumb {
  background: #888 !important; 
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
  }
  
`;

export const TablesStyle = styled.div`
  z-index: 100 !important;
  
  .moreOutline {
    color: ${(props) => props.theme.colors.primary};
    fontsize: 22px;
  }
  .dropDownActions{
    display:flex;
    align-items:center;
    justify-content: center;
  }
`;

export const PaginationStyle = styled(Pagination)`
  padding: 5px 0px 5px 0px;
  text-align: center;

  .ant-pagination-item a {
    background: transparent !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-pagination-item {
    background: transparent !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-pagination-item-link {
    background: transparent !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-pagination-item-active {
    background: #007aff !important;
  }
  .ant-pagination-options {
    display: none;
  }
`;
