import { Modal } from "antd";
import styled from "styled-components";

export const EditToPlayListModalStyle = styled(Modal)`
  .heading {
    font-size: 26px;
    color: ${(props) => props.theme.colors.primary};
  }

  .description {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primary};
  }
  .ant-modal-mask {
    backdrop-filter: blur(5px);
    z-index: 1052 !important;
  }
  .ant-modal-content {
    background-color: ${(props) => props.theme.background.secondary};
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid white;
    width: 600px;

    border-radius: 7px;
  }

  .btn2 {
    padding: 20px;
    font-size: 16px;
  }

  .btn1 {
    border: 1px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    padding: 14px 18px;
  }

  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }
  .heading {
    text-shadow: 0px 0px #ff0000 !important;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .ant-modal-wrap {
    z-index: 1052 !important;
    backdrop-filter: blur(5px) !important;
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .date {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }
  .ant-modal-mask {
    backdrop-filter: blur(5px) !important;
  }
  .footer {
    display: flex;
    gap: 10px;
    align-self: flex-end;
  }

  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      width: 100%;
      margin-left: 0%;
    }
  }
`;

export const EditToPlayListDivStyle = styled.div`
  .spanner {
    font-size: 15px;
    color: ${(props) => props.theme.colors.primary};
  }
`;
