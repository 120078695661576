import React from "react";

import { SectionWrapperStyle } from "./SectionWrapperNew.style";

type SectionWrapperProps = {
  heading: string;
  children: React.ReactElement;
  number: number;
  // id: any;
};

const SectionWrapperNew = (props: SectionWrapperProps) => {
  const { heading,children,number } = props;

  return (
    <SectionWrapperStyle>
      <div className="headingSlider">
        <h2 className="heading">{heading}</h2>
        <div className="artistNumber">
          {number}
        </div>
      </div>
      {children}
    </SectionWrapperStyle>
  );
};

export default SectionWrapperNew;
