import { useField, useFormikContext } from "formik";
import { InputBoxStyle } from "../../styles/common.style";
import { DatePickerStyle } from "./DatePicker.style";

const DatePicker = (props: any) => {
  const [field] = useField(props);

  const { setFieldValue } = useFormikContext();

  const handleChange = (data, dateString) => {
    setFieldValue(field.name, data.toISOString());
  };
  
  return (
    <InputBoxStyle>
      <label>{props.label}</label>
      <DatePickerStyle
        name={field.name}
        onChange={handleChange}
        showTime
        {...props}
      />
     
      {props.error && <p className="error">{props.error}</p>}
    </InputBoxStyle>
  );
};

export default DatePicker;