import React from "react";

interface FeaturesProps {}

const Features: React.FC<FeaturesProps> = () => {
  return (
    <div className="features-container" id="Features">
      <h1>Features</h1>
      <p className="main-para">
      Songplace offers unique features tailored for playlist management and curation enthusiasts.
      </p>
      <div className="features-list">
        <div className="features-list-items">
          <div>
            <img alt="music.svg" src={"./static/music.svg"} width="72" height="72"/>
          </div>
          <p><b>Pre-release:</b> Schedule your unreleased favorites with our exclusive pre-release feature, ensuring they seamlessly join your playlist upon launch. </p>
        </div>
        <div className="features-list-items">
          <div>
            <img alt="calender.svg" src={"./static/calender.svg"} width="72" height="72" />
          </div>
          <p><b>Schedule:</b> Tailor your playlist effortlessly by scheduling songs, based on their popularity, with start and end dates, giving you dynamic control over your music experience.</p>
        </div>
        <div className="features-list-items">
          <div>
            <img alt="text.svg" src={"./static/text.svg"} width="72" height="72" />
          </div>
          <p><b>Priority Position:</b> Elevate your favorite songs to priority positions within your playlists, ensuring they get the attention they deserve.</p>
        </div>
        <div className="features-list-items">
          <div>
            <img alt="graph.svg" src={"./static/graph.svg"} width="72" height="72" />
          </div>
          <p><b>Refresh:</b> Keep your playlists up-to-date with a simple refresh option, ensuring you always have the latest tracks at your fingertips.</p>
        </div>
        <div className="features-list-items">
          <div>
            <img alt="notes.svg" src={"./static/notes.svg"} width="72" height="72" />
          </div>
          <p><b>Notes:</b> Add personal touches to your playlist by attaching notes or descriptions to each song, making your music collection uniquely yours.</p>
        </div>
        <div className="features-list-items">
          <div>
            <img alt="people.svg" src={"./static/people.svg"} width="72" height="72"/>
          </div>
          <p><b>Link Multiple Profiles:</b> Connect and sync multiple profiles seamlessly, ensuring your playlists are accessible</p>
        </div>
        <div className="features-list-items">
          <div>
            <img alt="song.svg" src={"./static/song.svg"} width="72" height="72" />
          </div>
          <p><b>Preview Play:</b> Test the waters before finalizing your playlist with the preview play feature, ensuring each song fits seamlessly into your curated collection.</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
