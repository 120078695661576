import { head, pathOr, propOr } from "ramda";
import { useEffect, useState } from "react";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { CardsListStyle1 } from "./CardsList.1style";
import { CardsListStyle } from "./NewCardList.style";
import { useNavigate } from "react-router";
import { truncateText } from "../../utils";

type Props = {
  heading: string;
  subheading: string;
  playlistId: string;
  number: any;
};

function NewCardList(props: Props) {
  const { playlistId, number } = props;
  const { state: playlistState } = usePlaylistContext();
  const { state } = useLoginContext();
  const [currentPlaylistData, setCurrentPlaylistData] = useState<any>();
  const [currentPlaylistNo, setCurrentPlaylist] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPlaylistContent = playlistState.playlists.find(
      (item) => item.id === playlistId
    );
    // console.log(currentPlaylistContent);
    setCurrentPlaylistData(currentPlaylistContent);
    setCurrentPlaylist(currentPlaylistContent?.tracks?.total);
  }, [playlistId, state, playlistState]);

  return (
    <CardsListStyle1>
      <CardsListStyle>
        <>
          {currentPlaylistNo !== 0 ? (
            <>
              <div
                className="image"
                style={{
                  backgroundImage: `url('${propOr(
                    "",
                    "url",
                    head(pathOr("", ["images"], currentPlaylistData))
                  )}')`,
                  cursor: "grab",
                }}
              >

              </div>
              <div className="overlay" >

                <h3 className="playlistName" style={{ cursor: "pointer", }} onClick={() => {
                  navigate(`/playlist/${currentPlaylistData.id}`)
                }}>{truncateText(currentPlaylistData?.name, 25)}</h3>
                <div className="songName">
                  {`${currentPlaylistNo} songs`}
                </div>

              </div>
            </>
          ) : (
            <>
              <div
                className="image"
                style={{
                  backgroundImage: `url('./static/abstract.png')`,
                  cursor: "grab",
                }}
              >

              </div>
              <div className="overlay">

                <h3 className="playlistName" style={{ cursor: "pointer", }} onClick={() => {
                  navigate(`/playlist/${currentPlaylistData.id}`)
                }}>{truncateText(currentPlaylistData?.name, 25)}</h3>
                <div className="songName">
                  {`${currentPlaylistNo} songs`}
                </div>

              </div>
            </>
          )}
        </>
      </CardsListStyle>
    </CardsListStyle1>
  );
}

export default NewCardList;
