import { notification } from "antd";
import axios from "axios";
import { Form, Formik } from "formik";
import { useState } from "react";
import { BASE_URL } from "../../constants/api-urls";
import {
  FilledButtonStyle
} from "../../styles/common.style";
import { InputBox } from "../InputBox/index";
import { ContactFooter, LogoWithHeading } from "../LogoWithHeading";
import { SignupFormStyle } from "./SignupForm.style";
import { SignupFormInitialValues, SignupValidationSchema } from "./formData";

const SigupForm = () => {
  // let stripePromise;

  // const getStripe = (e) => {
  //   if (!stripePromise) {
  //     stripePromise = loadStripe(
  //       "pk_live_51KFeX8Lq1OqlOQjRE9kMCTyfv4j4UWWE19oYmaYc6dJ99Ca5xdECIttSwneki9K9GhZmYjKOWN41R6eing8bK0db00NOzPo9XU"
  //     );
  //   }
  //   //"pk_test_51KFeX8Lq1OqlOQjRgP0EHUh4d4Dhd9GmFihN5HsKyoHqZfm7lE47xnfQiSbidb3sprINHqM5880eUhhtmVFYcTaG00P3rLIicf"

  //   return stripePromise;
  // };
  const [loading, setLoading] = useState(false);
  const handleFormSubmit = async(e: any) => {
    if (e.email === "") return;
    setLoading(true);
    const domain = "dev-2z4p074m.us.auth0.com";
    const client_id = process.env.REACT_APP_AUTH0;
    try {
    const response = await axios.post(
      `https://${domain}/dbconnections/signup`,
      // '{"client_id":"YOUR_CLIENT_ID", "email":"test.account@signup.com", "password":"PASSWORD", "connection":"CONNECTION", "username": "johndoe", "given_name": "John", "family_name": "Doe", "name": "John Doe", "nickname": "johnny", "picture": "http://example.org/jdoe.png", "user_metadata":{ "plan": "silver", "team_id": "a111" }}',
      {
          'client_id': `${client_id}`,
          'email': `${e.email}`,
          'password': `${e.password}`,
          "given_name": `${e.username}`,
          'connection': 'Username-Password-Authentication',
          'username': `${e.username}`,
          'name': `${e.username}`,
      },
      {
          headers: {
              'content-type': 'application/json'
          }
      }
  );
  if(response?.status === 200){
  notification.open({
    message: "Successful",
    description: "Signup successful!",
  });

  setLoading(false);
    fetch(`${BASE_URL}/stripe-payment-form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        successUrl: `${window.location.origin}/created`,
        cancelUrl: `${window.location.origin}/`,
        email: `${response?.data?.email}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        window.location = data.data.url;
        // console.log(data.data.url);
      })
      .catch((error) => {
      });
      } else {
        notification.open({
          message: "Sign Up Error",
          description: response,
        });
        setLoading(false);
      }
    } catch(error){
      notification.open({
        message: "Sign Up Error",
        description: error,
      });
      setLoading(false);
    }
  };
  const handleSubmit = async () => {
    const domain = "dev-2z4p074m.us.auth0.com";
    const client_id = process.env.REACT_APP_AUTH0;
    const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
    const scope = "openid profile email";
    const response_type = "token";
    const redirect_uri = `${window.location.origin}/shift`;
    window.location.replace(
      `https://${domain}/authorize?` +
        `audience=${audience}&` +
        `scope=${scope}&` +
        `response_type=${response_type}&` +
        `client_id=${client_id}&` +
        `redirect_uri=${redirect_uri}`
    );
  };
  return (
    <SignupFormStyle>
     
<br/>
      {/* <OutlineButtonStyle>Sign up with Google</OutlineButtonStyle> */}
      {/* <p className="or-text">Or</p> */}
      <div style={{
        background:"white",
        padding:"30px 20px",
        width:"25%",
        borderRadius:"4px"
      }}>
         <LogoWithHeading
        heading="Create account"
        description="For curators, artists and labels."
      />
      <Formik
        initialValues={SignupFormInitialValues}
        validationSchema={SignupValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { validate }: any) => {
          if (validate) {
            validate(values);
          }
          handleFormSubmit(values);
        }}
      >
        {({ errors }) => (
          <Form className="form-wrapper">
              <InputBox
                name="username"
                label="Name"
                transparent
                id="inputed"
                error={errors.username}
              />
              <InputBox
                name="email"
                label="Email"
                transparent
                error={errors.email}
              />
              <InputBox
                name="password"
                type="password"
                label="Password"
                transparent
                error={errors.password}
              />
              <InputBox
                name="confirmPassword"
                type="password"
                label="Confirm password"
                transparent
                error={errors.confirmPassword}
              />
            <span className="terms">
              <InputBox
                name="termConditions"
                required
                type="checkbox"
                error={errors.termConditions}
              />
              <label>
                I agree to the <span>terms</span> & <span>privacy policy </span>
              </label>
            </span>
            <FilledButtonStyle type="submit">{loading === true ? "Loading" :"Create account"}</FilledButtonStyle>
            <p>
              Already have an account? <span style={{color:"#007aff", cursor:"pointer"}} onClick={handleSubmit}>Log in</span>
            </p>
          </Form>
        )}
      </Formik>
      </div>
<br/>

      <ContactFooter />
    </SignupFormStyle>
  );
};

export default SigupForm;
