import axios from "axios";
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer
} from "react";
import { SPOTIFY_TOKEN } from "../constants/storage";
import { addItemToStorage, getRefreshedToken, isEmptyOrNil } from "../utils";

import { useLoginContext } from "./LoginContext";
// import { PaginationStyle } from "../pages/Playlist/Playlist.style";

type ScheduleAction =
  | {
    type: "SCHEDULE_DATA";
    payload: any;
  }

export type ScheduleContextType = {
  state: any;
  dispatch: Dispatch<ScheduleAction>;
};

export type ScheduleContextTypes = {
  states: any;
  dispatch: Dispatch<ScheduleAction>;
};

const theme = JSON.parse(localStorage.getItem("theme"));

const initialContent: any = {
  ScheduleData: false,
};

export const ScheduleContext = createContext<ScheduleContextType>({
  state: {
    ...initialContent,
  },
  dispatch: () => undefined,
});
export const useScheduleContext = () => useContext(ScheduleContext);

export const ScheduleContexts = createContext<ScheduleContextTypes>({
  states: {
    ...initialContent,
  },
  dispatch: () => undefined,
});
export const usePlaylistCont = () => useContext(ScheduleContexts);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SCHEDULE_DATA":
      return {
        ...state,
        ScheduleData: action.payload,
      };
    default:
      return state;
  }
};

export const ScheduleContextProvider = (props: any) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialContent);

  const { state: loginState, dispatch: loginDispatch } = useLoginContext();


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!isEmptyOrNil(state.playlists)) return;
    if (isEmptyOrNil(loginState.token)) return;

    axios(`${process.env.REACT_APP_BACKEND_URI}/GetScheduledList/?page=1&limit=10&access_token=${loginState.token}&userId=${loginState.userId}`, {
      method: "GET",
      headers: { Authorization: "Bearer " + loginState.token },
    })
      .then((genreResponse) => {
        // console.log(genreResponse.data,'playlistState')
        dispatch({
          type: "SCHEDULE_DATA",
          payload: genreResponse?.data.data,
        });
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken)
            .then((response) => {
              addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

              loginDispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            })
            .catch(() => { });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState, loginState.token, loginDispatch]);

  return (
    <ScheduleContext.Provider value={{ state, dispatch }}>
      {children}
    </ScheduleContext.Provider>
  );
};
