import { Dropdown, Popover, Space } from 'antd';
import React, { useState } from 'react'
import { SideBarAccount } from '../SideBar/SideBarAccount.style';
import { SwitchStyle } from './SwitchAccount.style';

function SwitchAccount() {
    const [openPlatForm, setOpenPlatForm] = useState(false);
    const [blurBackground, setBlurBackground] = useState(false);
    const [activePlatForms, setActivePlatForms] = useState("Spotify");
    const platForms = [
        {
            name: "Spotify",
            image: './static/images/spotifyLogo.png',
            id: 1,
            disable: false,
        },
        {
            name: "Apple Music",
            image: './static/images/appleMusicLogo.png',
            id: 2,
            disable: true,
        },
    ];
    const menu = (
        <SideBarAccount>
            <div className="menuLinks menuLinksPlatforms">
                {platForms.map((a, i) =>
                    !a.disable ? (
                        <div
                            className={`menuDiv ${a.name === activePlatForms ? "active" : ""
                                } ${a.disable ? " disabled" : ""}`}
                            onClick={() => {
                                setActivePlatForms(a.name);
                            }}
                        >
                            <div className="imageName">
                                <img alt={a.name} src={a.image} />
                                {a.name}
                            </div>
                            <svg
                                className="ticIcon"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.75 8.75L6.25 12.25L13.25 4.75"
                                    stroke="#56BA32"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    ) : (
                        <Popover
                            content={<p className="hanker2">Coming soon</p>}
                            placement="left"
                            title="Apple Music"
                        >
                            <div
                                className={`menuDiv ${a.name === activePlatForms ? "active" : ""
                                    } ${a.disable ? " disabled" : ""}`}
                            // onClick={() => {
                            //   setActivePlatForms(a.name);
                            // }}
                            >
                                <div className="imageName">
                                    <img alt={a.name} src={a.image} />
                                    {a.name}
                                </div>
                                <svg
                                    className="ticIcon"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.75 8.75L6.25 12.25L13.25 4.75"
                                        stroke="#56BA32"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </Popover>
                    )
                )}
            </div>
        </SideBarAccount>
    );

    return (
        <SwitchStyle>
            <Dropdown
                overlay={menu}
                trigger={["click"]}
                className="switchAccount"
                onOpenChange={() => {
                    setOpenPlatForm(!openPlatForm);
                    setBlurBackground(!blurBackground);
                }}
            >
                <span onClick={(e) => e.preventDefault()}>
                    <Space>
                        <div className="platformSelect">
                            <p>{activePlatForms}</p>
                            <svg
                                style={
                                    openPlatForm
                                        ? { transform: "rotate(0deg)" }
                                        : { transform: "rotate(180deg)" }
                                }
                                width="14"
                                height="8"
                                viewBox="0 0 14 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.3331 7.33337L6.66646 0.666708L-0.000202656 7.33338L13.3331 7.33337Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </Space>
                </span>
            </Dropdown>
        </SwitchStyle>
    )
}

export default SwitchAccount