import styled from 'styled-components';

const LogoWithHeadingStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	.logo {
		width: 178px;
	}

	.signin-heading {
		font-size: 28px;
		margin-bottom: 5px;
	}
`;
const ContactFooterStyle = styled.div`
	color: #d1d1d1;
	font-size: 14px;
    font-weight: 400;
	botton:0px;
	
`;

export { ContactFooterStyle, LogoWithHeadingStyle };
