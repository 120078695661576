import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import {
  addUserProfileReducers,
  deleteUserProfileReducers,
  getUserProfileReducers,
} from "./reducers";

const reducer = combineReducers({
  addUserProfileList: addUserProfileReducers,
  deleteUserProfileList: deleteUserProfileReducers,
  getUserProfileList: getUserProfileReducers,
});

// const sharedInfoFromLocalStorage = localStorage.getItem("userInfo")
//   ? JSON.parse(localStorage.getItem("userInfo"))
//   : null;


const middleware = [thunk];

const Store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default Store;
