import styled from "styled-components";
import { SlimScrollStyle } from "../../styles/common.style";

const SearchStyle = styled.div`
padding: 81px 0px 0px;
color: white;
text-align: center;
  .ant-table {
    ${SlimScrollStyle};

    max-height: 80vh;
    overflow: auto;

    @media (max-width: 600px) {
      max-height: calc(100vh - 260px);
    }
  }

  .song-title {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .song-artist {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-pagination {
    justify-content: center;
  }
  .platformSelect{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 36px;
    border-radius: 8px;
    background: rgb(36, 36, 41);
    border: 0.6px solid rgba(255, 255, 255, 0.2);
    font-size: 12px;
    padding: 9.5px 16px;
    width: 127.33px !important;
    color: rgb(255, 255, 255) !important;
  }
  .searchBarBrowse{
      display:none;
      align-items: center;
    justify-content: center;
    padding: 0px 20px;
  }
  @media screen and (max-width: 768px){
    .searchBarBrowse{
      display:flex;
    }
  }
`;
export { SearchStyle };
