
const About = () => {
  return (
    <div style={{ paddingBottom: "20px" }}>


      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "450px",
          overflow: "auto",
        }}
      >
        <div className="justifywidth">
          <div style={{ marginTop: "0px" }}>
            <br />
            <p className="PrivacyPolicyParam">
              Songplace is a platform designed to support curators, artists and
              labels who manage their own Spotify playlists on a daily or weekly
              basis.
            </p>
            <br />
            <br />
            <p className="PrivacyPolicyParam">
              With Songplace you can find, listen and schedule any song on
              Spotify without the need to login on the Spotify platform itself.
              Besides this, Songplace offers unique features that can’t be found
              on Spotify such as scheduling a starting or End date for the song
              in your playlists, pre-scheduling unreleased songs in your
              playlists, adding notes to songs in your playlists and getting key
              performance insights of all the songs on Spotify and those that
              are in your playlists.
            </p>
            <br />
            <br />
            <p className="PrivacyPolicyParam">
              Anyone that manages their own Spotify playlists can use Songplace.
              You can easily connect multiple Spotify profiles to your account
              in order to quickly switch between various profiles and place
              songs faster. Our bulk mode allows you to schedule a song in
              multiple playlists at the same time to save you more time. If you
              would like to contact us with any questions or you would like to
              make an inquiry, please fill out the form below and we will get
              back to you as soon as possible.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
