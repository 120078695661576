import { Select } from "antd";
import { useField, useFormikContext } from "formik";

import { ArrowDownIcon } from "../../assets";
import { SelectBoxStyle, SelectInputStyle } from "./SelectBox.style";

const { Option } = Select;

type options = {
  key: string;
  value: string;
  disabled?: boolean;
};

type SelectBoxProps = {
  name: string;
  defaultValue?: string;
  options: options[];
  width?: string;
  setFieldValue?: (field: string, value: any) => void;
  initializeSettingsData?: any;
  values?: any;
  disabled?: boolean;
  label?: any;
  type?: "horizontal" | "vertical";
  placeholder?: string;
  mode?: string;
};

function tagRender(props) {
  const { label, value } = props;
  return (
    <div
      className="custom-tag"
      style={{
        backgroundColor: value === "red" ? "#f50" : "#007aff",
        color: "#fff",
        borderRadius: "4px",
        padding: "2px 8px",
        margin: "2px",
      }}
    >
      <span className="custom-tag-text">{label}</span>
    </div>
  );
}

const SelectBox = (props: SelectBoxProps) => {
  const [field] = useField(props);

  const {
    options,
    initializeSettingsData,
    values,
    defaultValue,
    disabled = false,
    label,
    type = "vertical",
    placeholder,
    mode,
  } = props;

  const { setFieldValue } = useFormikContext();

  const handleChange = (value: any) => {
    setFieldValue && setFieldValue(field.name, value);
    initializeSettingsData && initializeSettingsData(value, undefined, values);
  };

  return (
    <SelectInputStyle type={type}>
      {label && <label className="select-label">{label}</label>}
      <div id="add-song-mode-div" style={{ width: "200px" }}>
        {mode ? (
          <SelectBoxStyle
            id="add-song-mode-select"
            mode="multiple"
            tagRender={tagRender}
            suffixIcon={<ArrowDownIcon />}
            name={field.name}
            width={"100%"}
            defaultValue={field.value || defaultValue}
            value={field.value || defaultValue}
            onChange={handleChange}
            disabled={disabled}
            placeholder={placeholder}
          >
            {options.map(
              (option: { key: string; value: string; disabled?: boolean }) => (
                <Option
                  id="add-songs-mode-options"
                  key={option.key}
                  value={option.key}
                  disabled={option.disabled}
                >
                  {option.value}
                </Option>
              )
            )}
          </SelectBoxStyle>
        ) : (
          <SelectBoxStyle
            id="add-song-mode-select"
            suffixIcon={<ArrowDownIcon />}
            name={field.name}
            width={"100%"}
            defaultValue={field.value || defaultValue}
            value={field.value || defaultValue}
            onChange={handleChange}
            disabled={disabled}
            placeholder={placeholder}
          >
            {options.map(
              (option: { key: string; value: string; disabled?: boolean }) => (
                <Option
                  id="add-songs-mode-options"
                  key={option.key}
                  value={option.key}
                  disabled={option.disabled}
                >
                  {option.value}
                </Option>
              )
            )}
          </SelectBoxStyle>
        )}
      </div>
    </SelectInputStyle>
  );
};

export default SelectBox;
