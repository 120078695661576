import React, { useEffect } from "react";
import { PaymentModalStyle } from "./PaymentModalStyle.style";
import PricingPlan from "./PricingPlan";

interface PricingModalProps {
  isOpen: boolean;
  onClose: () => void;
  next: (id: string, type: string, duration: string, currency: string) => void;
  showmenu?: boolean;
  currentPlan?: string;
  selectedCurrency?: string;
}

const PricingModal: React.FC<PricingModalProps> = ({
  isOpen,
  onClose,
  next,
  showmenu,
  currentPlan,
  selectedCurrency,
}) => {
  
  // useEffect(()=>{
  //     console.log(selectedCurrency,'selectedCurrency')
  // },[selectedCurrency])

  return (
    // <PricingModalStyle>
    <PaymentModalStyle
      footer={false}
      className="pricingModal-component"
      centered
      open={isOpen}
    >
      <div className="login-modal">
        {showmenu && (
          <div className="login-heading-container">
            <img alt="close.svg" src="./static/close.svg" onClick={onClose} />
          </div>
        )}
        <PricingPlan
          selectedCurrency={
            selectedCurrency !== undefined ? selectedCurrency : ""
          }
          blob={false}
          next={next}
          currentPlan={currentPlan}
        />
      </div>
    </PaymentModalStyle>
    // </LoginModalStyle>
  );
};

export default PricingModal;
