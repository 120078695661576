import { Modal } from "antd";
import styled from "styled-components";

export const NewSubscriptionStyle = styled(Modal)`
max-width: 890px !important;
width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-modal-mask {
    backdrop-filter: blur(5px) !important;
  }
  .ant-modal-content {
    backdrop-filter: blur(5px);
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.background.secondary1};
    border: 1px solid #ffffff4d;
    max-width: 890px;
    width: 100%;
    border-radius: 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .newSubModal {
    display:flex;
    align-items:center;
    flex-direction:column;
    .announmentImage{
      height:267px; 
    }
    .announmentText{
      padding: 26px 32px 40px;
    }
    .headingDiv{
      width: 100%;
      margin-bottom: 20px;
      h3 {
        font-family: Public Sans;
        font-size: 24px;
        letter-spacing: -0.03em;
        text-align: left;
        color:white;        
        img{
          width:172px;
        }
      }
      p{
        font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;

      }
    }
    .text{
      font-family: Poppins;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;
      
    }
    .diveText{
      margin-top:24px;
      font-family: Poppins;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;

    }
    .whatsNew{
      font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.02em;
text-align: left;
color:#55A2F7;
margin-top:26px;
display:flex;
align-items:center;
cursor:pointer;
svg{
  margin-right:8px;
}
    }
  }
  .youCanSee{
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;

color:white;
margin-top:16px;
  }
  .featureList{
    padding: 0px 30px;
    .listItem{
      font-family: Poppins;
font-size: 16px;
font-weight: 300;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;
color:#ffffffba;

    }
  }
  .icluded{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color:#ffffffba;
    margin-top:24px;
  }
  .checkoutBtn{
    width: 238px;
    padding: 14.5px 32px 14.5px 32px;
    border-radius: 6px;
    background: #007aff;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border: none;
    outline: none;
    cursor: pointer;
    position:relative;
    margin-top: 20px;
  }
  .seeMore{
    color:#007aff;
    text-decoration: underline;
    cursor:pointer;
  }
  .more{
    color: white;
    opacity: 0.8;
    display:none;
    font-size: 16px;
    line-height: 8px;
    &.show{
      display:inline;
    }
    li{
      line-height: normal;
    }
  }
`;
