import { Modal } from "antd";
import styled from "styled-components";

export const EditToPlayListModalStyle = styled(Modal)`
  .heading {
    font-size: 27px;
    color: ${(props) => props.theme.colors.primary};
  }

  .description {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primary};
  }

  .btn1 {
    padding: 14px 18px;
    border: 1px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
  }

  .btn2 {
    padding: 20px;
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.15);
  }
  .ant-modal-content {
    backdrop-filter: blur(5px);
    background-color: ${(props) => props.theme.background.secondary};
    color: ${(props) => props.theme.colors.primary};
    width: 785px;
    border: 1px solid white;
    border-radius: 7px;
  }

  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }
  .heading {
    text-shadow: 0px 0px #ff0000 !important;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .date {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }

  .footer {
    display: flex;
    gap: 5px;
    align-self: flex-end;
  }

  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      width: 100%;
      margin-left: 0%;
    }
    .ant-select .ant-select-selector,
    .ant-select-selection,
    .ant-select-item {
      font-size: 17px !important;
    }
  }
`;
export const EditToPlayListStyle = styled.div`
  .spanner {
    font-size: 20px;
    color: ${(props) => props.theme.colors.primary};
  }
`;
