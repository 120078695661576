import { Modal } from "antd";
import styled from "styled-components";

export const SyncModalStyle = styled(Modal)`

  display: flex;
  align-items: center;
  justify-content: center;
  .ant-modal-mask {
    backdrop-filter: blur(5px) !important;
  }
  .ant-modal-content {
    backdrop-filter: blur(5px);
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.background.secondary1};
    border: 1px solid #ffffff4d;
width:100%;
    border-radius: 20px;
  }
  .ant-modal-body {
    padding: 40px 24px;
  }
  .addToPlaylistHead{
    border-bottom: 1px solid #ffffff4d;
    padding-bottom: 16px;
  }
  .heading {
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 32px;
letter-spacing: 0em;
text-align: center;
margin-bottom:8px;

  }
  .heading{
    margin-bottom:24px;
  }
  .loadingDiv{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .loadingCircle{
    width: 85px;
    height: 85px;
    border: 5px solid #007AFF;
    border-radius: 90px;
    border-left: 5px solid transparent;
    animation: refreshRound 1s infinite;
  }
  @keyframes refreshRound {
    0%{
      transform: rotate(0deg);
    }
    0%{
      transform: rotate(359deg);
    }
  }

`;
