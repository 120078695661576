import styled from "styled-components";
export const PlayListBannerStyle = styled.div`
  display: flex;
  padding: 85px 30px 10px;
  // background: linear-gradient(180deg, #007aff 0%, rgba(0, 122, 255, 0) 100%);
  background: transparent;
  
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  .platform{
    display: flex;
    align-items: center;
        margin-bottom: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.03em;
    text-align: left;
    color: white;
  }
  .platform img{
    width: 70px;
    margin-bottom: 10px;
    margin-right:12px;
    object-fit: contain;
  }
  .images1 {
    height: 24px;
    width: 22px;
  }
  .row1 {
    display: flex;
  }
    .row1 svg{
        width: 13px;
        }
 .row1 .p1,.row1 svg {
    margin-left: 6px;
    color: white;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: left;
    opacity: 0.7;
  }
  .row-space {
    display: flex;
    justify-content: space-between;
  }
  #btn12 {
    padding: 10px;
    height: 50px;
    margin-right: 30px;
    border: white;
    background: #181818;
  }

  .container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    @media only screen and (max-width: 600px) {
      margin: auto;
      width: 100%;
      align-items: center;
      flex-direction: column-reverse;
    }
  }

  .image {
    width: 220px;
    opacity: 0.6;
    box-shadow: 0px 6px 32px 6px rgba(0, 0, 0, 0.2);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 13px;
    font-size: 20px;
    width: 70%;
    margin-left: 2%;
    margin-bottom: 6px;
  }

  .title {
    
    color: ${(props) => props.theme.colors.primary};
 
    font-family: Poppins;
    font-size: 35px;
    font-weight: 600;
    line-height: 52px;
    letter-spacing: 0.04em;
    text-align: left;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: flex;
    align-items: flex-start;


    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .desc{
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
    font-size: 21px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: -0.03em;
    text-align: left;
    opacity:0.5;
  }

  
  .p2 {

        display: flex;
    margin-top: 5.5px;
    font-family: Poppins;
        color: ${(props) => props.theme.colors.primary};
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: left;
    -webkit-box-align: center;
    align-items: center;

  }

  .p2 span {
    margin: 0px 14px 0px 12px
  }
  .p2 .platForm{
     opacity: 0.7;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: left;

  }
  .row-space {
    flex-direction: row;
  }

  .pre-release {
    font-size: inherit;
  }
  .songPlayListButtons{
    display:flex;
    align-items:center;

    margin-top:15px;
  }
  .songPlayListButtons .addSong{
    display:flex;
    align-items:center;
    justify-content:center;
    
    background: #007AFF;
    padding: 0px 15.;
    height:33px;
    
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border-radius:8px;
    border:none;

    margin-right: 12px;

    cursor:pointer;
        padding: 10px 20px;
  }
  .songPlayListButtons .addSong svg{
    margin-right:10px;
    // width:;
    // height:;

  }
  .songPlayListButtons .editPlaylist{
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    background: linear-gradient(0deg, #303036, #303036),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));    
    padding: 0px 30.;
    height:42px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border-radius:8px;
    border:none;
    border: 1px solid #FFFFFF33;

    margin-right: 12px;
        padding: 10px 20px;
    min-width: 142px;
  }
  .songPlayListButtons .editPlaylist svg{
    margin-right:10px;
    
    // width:;
    // height:;

  }
  .playListPic{
height: 230px;
    width: 275.64px;
    display:flex;
    background: #242429;
    align-items:center;
    border-radius:0px;
    overflow:hidden;
    position:relative;
    .uploadImage{
      opacity:0;
      transition: 0.2s;
      &:hover{
      opacity:1;
      }
        position: absolute;
        background: #0000008a;
        width: 100%;
        z-index: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 20px;
        backdrop-filter: blur(2px);
        cursor: pointer;
          .innerUploadImage{
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            flex-direction: column;
            padding: 30px;
            border: 2px dashed white;
            border-radius: 5px;
              input{
                transform:scale(0);
                display:none;
              }
              svg{
                font-size: 30px;
                margin-bottom: 7px;
              }
          }
    }

  }
  .playListPic img{
        width: calc(100% - 59.71px);
    object-fit: cover;
    height:100%;
  }
  .playListPic .upcoming{
    transform: rotate(-90deg);
    height: 59.71px;
    white-space:nowrap;
    right: -71px;
    position: absolute;
    height: 59.71px;
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  @media only screen and (max-width: 900px) {
    padding: 90px 10px 10px;
    .innerMobTablePlaylist{
      padding: 0px 20px;
    }
    .playlistActions{
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;


    &::-webkit-scrollbar {
      width: 3px;
      height: 2px !important;
    }
    
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1;  
    }
     
    
    &::-webkit-scrollbar-thumb {
      background: #888 !important; 
    }
    
    
    &::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
    }
    .actionDiv{
      margin-right:20px;
    }
  }

  

  @media only screen and (max-width: 600px) {
    .songPlayListButtons buttons{
      white-space:nowrap;
      
    }
  
   
    .text-container {
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
      width: 100%;
      margin:0px;
    }
    .images1 {
      height: 16px !important;
      width: 16px !important;
    }


    .p2 {
      font-size: 14px;
    }

    .row-space {
      flex-direction: column;
    }

    .title {
      margin: 10px 0px;
      font-size: 28px !important;
      line-height: initial;
    }

    #btn12 {
      padding: 8px;
      height: 40px;
      margin-right: 90px;
    }

    .pre-release {
      font-size: 12px;
    }

    .spanner {
      padding: 0px 0px !important;
    }

    .icons {
      font-size: 14px;
    }
  .playListPic{
    max-width: 375.64px;
    width: 100%;
    position:relative;
  }
  .playListPic .upcoming{
    right:-84px;
  }
  }

  @media only screen and (max-width: 375px) {
    .spanner {
      padding: 0px 0px !important;
    }
  }
  @media only screen and (max-width: 320px) {
    #btn12 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
`;
