import { Pagination } from "antd";
import styled from "styled-components";

import { SlimScrollStyle, TableStyle } from "../../styles/common.style";

export const PlaylistStyle = styled.main`
  ${SlimScrollStyle};
  background-image:url('./static/images/futureBackground.png') !important;
  background-position: top;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: black;
  position:relative;
  // overflow: auto;
  // height: 100vh;
  padding-bottom: 120px;
  min-height:85vh;
  @media only screen and (min-width: 1366px) {
    padding-bottom: 120px;
  }
  @media only screen and (min-width: 1440px) {
    padding-bottom: 120px;
  }
  @media only screen and (min-width: 1536px) {
    padding-bottom: 120px;
  }
  @media only screen and (min-width: 1920px) {
    padding-bottom: 120px;
  }
  @media (max-width: 600px) {
    // max-height: calc(100vh - 05px);
    padding-bottom: 300px;
  }
  #hancker {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    max-width: 200px;

  }
  #hancker1 {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
  .ant-table-measure-row td {
    border-bottom: 1px solid transparent !important;
  }
  .ant-table-cell-row-hover {
    background: ${(props) => props.theme.background.hoverTable} !important;
    border-bottom: 1px solid ${(props) => props.theme.background.hrBorder} !important;
  }
  .ant-empty-description {
    color: ${(props) => props.theme.colors.primary4} !important;
  }
  .ant-modal-close {
    color: ${(props) => props.theme.colors.primary4} !important;
  }

  .ant-table-cell-row-hover #hancker1,
  .ant-table-cell-row-hover #hancker,
  .ant-table-cell-row-hover .moreOutline {
    color: white;
  }
  .ant-table-row {
    &:hover {
      .spotify-redirect {
        opacity: 1;
        transition: all 300ms linear;
      }
    }
  }

  .ant-table .ant-table-thead {
    position: -webkit-sticky !important; /* Safari */
    position: sticky !important;
    top: 0 !important;
    z-index: 1000;
    padding: 20px;
  }

  .song-title {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .song-artist {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icons {
    align-items: center;
    grid-gap: 20px;

    & > span {
      cursor: pointer;
    }
  }

  .icons1 {
    visibility: hidden;
    cursor: pointer;
  }

  .ant-pagination-item-active {
    background: #007aff !important;
  }

  .ant-table-cell-row-hover .icons1 {
    visibility: visible;
  }

  .ant-table-cell-row-hover .icons2 {
    visibility: hidden;
  }
  .ant-table-cell-row-hover .icons45 {
    color: white;
  }
  .icons45 {
    color: ${(props) => props.theme.colors.primary};
  }
  .ant-table-cell-row-hover .icons45 {
    color: white;
  }

  .ant-popover {
    z-index: 100 !important;
  }

  .ant-table .ant-table-tbody .ant-table-cell {
    padding: 6px 10px !important;
    border-bottom: 1px solid ${(props) => props.theme.background.hrBorder} !important;
  }
  .ant-table-cell {
    border-bottom: 1px solid ${(props) => props.theme.background.hrBorder} !important;
  }

  ${TableStyle} {
    .ant-table-cell {
      width: 100%;
      &.ant-table-cell-row-hover {
        background: #ffffff;
      }

      &:nth-of-type(2) {
        .title {
          max-width: 40px;
        }
      }
    }
  }
  .ant-table-wrapper {
    background: ${(props) => props.theme.background.transparent1};
  }

  .ant-spin-container {
    padding: 20px;
  }
  .ant-table-body::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  .ant-table-body::-webkit-scrollbar-track {
    background: transparent !important;
  }

  /* Handle */
  .ant-table-body::-webkit-scrollbar-thumb {
    background: #007aff !important;
    border-radius: 8px !important;
  }

  /* Handle on hover */
  .ant-table-body::-webkit-scrollbar-thumb:hover {
    background: #007aff !important;
  }

  .ant-table-tbody tr:nth-child(even) {
    background: ${(props) => props.theme.background.evenTable};
  }
  .ant-table-tbody tr .ant-table-row-hover {
    border-radius: 8px !important;
    background: #ffffff;
  }
  @media (max-width: 600px) {
    .ant-table .ant-table-thead {
      z-index: 0;
    }
    .ant-table-cell,
    .ant-table-cell ant-table-cell-row-hover {
      background: ${(props) => props.theme.background.transparent1} !important;
    }
  }
  .table-list-image {
       height: 28px;
    aspect-ratio: 1;
    width: 28px;
    object-fit: contain;
  }
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding-bottom: 15px !important;
  }
  .rows4 {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
  }
  .rows4:hover {
    background: rgb(196, 196, 196, 0.3);
    border-radius: 8px;
  }
  .tablePlayListDiv{
    padding: 0px 60px;
  }
  .filterBox{
    padding: 0px 60px 0px;
  }
  .innerFilterBox{
    width:100%;
    padding:26px 0px;
    border-top: 1px solid #ffffff1a;
    button{
    border: 1px solid #ffffff1a;
    padding:8px;
    border-radius:8px;
    background:transparent;
    display:flex;
    align-items:center;
    justify-content:center;
    &.active{
      background:rgb(0, 122, 255);
    }
    .ant-dropdown-trigger{
      width:18px;
      height:18px;
    }
    svg{
      width:18px;
      height:18px;
    }
    }
  }
  .tablePlayList{
    width:100%;
  }
  .tablePlayList th{
  opacity:0.5;
  }
  .tablePlayList td,.tablePlayList th{
    color: ${(props) => props.theme.colors.primary};

    font-family: Poppins;
font-size: 10px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.11em;
text-align: left;

  }
  .tablePlayList .seriesNumber{
    width:30px;
    text-align: center;
  }
  .tablePlayList tr:hover td{
    background: #242429;
  }
  .tablePlayList tr td:first-child{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .tablePlayList tr td:last-child{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .tablePlayList td{
   padding: 6px;
  }
  .tablePlayList td h3{
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
font-size: 11px;
font-weight: 400;
letter-spacing: 0em;
text-align: left;
margin-bottom: 4px;

  }

  .blurrScreen{
    position:absolute;
    top:0px;
    left:0px;
    background: linear-gradient(157.78deg, rgba(6, 5, 5, 0.32) -0.75%, rgba(0, 0, 0, 0) 100%),
linear-gradient(169.15deg, rgba(255, 255, 255, 0.1) 0%, rgba(238, 237, 237, 0.05) 96.79%);
width:100%;
height:100%;

backdrop-filter: blur(13px);

  }

  .innerFilterBox{
    display: flex;
    align-items:flex-start;
    .filterTags{
      padding-bottom:15px;
      display:flex;
      align-items:center;
      width: calc(100% - 47px);
      overflow: auto;
      flex-wrap: wrap;
&::-webkit-scrollbar {
  display: none;
}
&:hover::-webkit-scrollbar {
  display: block;
}


  -ms-overflow-style: none;
  scrollbar-width: none; 

      .tag{
        margin-left:12px;
        color:#007AFF;
    display: flex;
    align-items:center;
    background:#007AFF1F;
    padding: 9px 14px;
    border: 1px solid #007AFF;
    border-radius:8px;
    margin-bottom: 10px;
    p{

font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
white-space:nowrap;
    }
    b{
      margin-right:5px;
    }
    svg{
      cursor:pointer;
     margin-left:10px; 
    }
  }
    }
  }

 .playlistInputTextSearch{
      outline: none;
      background: transparent;
      background-color: transparent;
      border: none;
    }
  
  
`;

export const PaginationStyle = styled(Pagination)`
  padding: 15px 0px 15px 0px;
  text-align: center;
  .ant-pagination-item a {
    background: transparent !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-pagination-item {
    background: transparent !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-pagination-item-link {
    background: transparent !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-pagination-options {
    display: none;
  }
`;

export const PopoverStyle = styled.div`
  .ant-message-notice-content {
    background: ${(props) => props.theme.background.primary4} !important;
    /* Blue */
    border: 2px solid ${(props) => props.theme.background.primary4} !important;
    border-radius: 4px !important;
    color: ${(props) => props.theme.colors.primary} !important;
    margin-top: 20px !important;
    z-index: 1000000000 !important;
    position: relative;
  }
  .ant-popover-arrow-content {
    --antd-arrow-background-color: ${(props) =>
    props.theme.background.primary4} !important;
  }

  .ant-popover-inner,
  .ant-popover-inner-content {
    width: 100%;
    background: ${(props) => props.theme.background.primary4} !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .rowing {
    padding: 5px;
    padding-bottom: 15px;
  }
`;
