import { Alert } from "../../assets";
import { BASE_URL } from "../../constants/api-urls";
import {
  SPOTIFY_CODE,
  SPOTIFY_TOKEN,
  SPOTIFY_USER_INFO
} from "../../constants/storage";
import { Auth0Logout, deleteItemFromStorage } from "../../utils";
import { LoginStyle } from "./Login.style";

const CancelSub = () => {
  

  const onFinish = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      fetch(`${BASE_URL}/stripe-payment-form`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          successUrl: `${window.location.origin}/created`,
          cancelUrl: `${window.location.origin}/`,
          email: `${userInfo.email}`,
          subId: `${userInfo?.userId}`,
          custId: userInfo?.custId ? `${userInfo?.custId}`:"",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          window.location = data.data.url;
        })
        .catch((error) => {
        });
    } else {
      window.location.replace("/");
    }
  };

  const onBack = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      deleteItemFromStorage(SPOTIFY_TOKEN);
      deleteItemFromStorage(SPOTIFY_CODE);
      deleteItemFromStorage(SPOTIFY_USER_INFO);
      localStorage.removeItem("userInfo");
      localStorage.removeItem("spotifyUser");
      Auth0Logout();
    } else {
      deleteItemFromStorage(SPOTIFY_TOKEN);
      deleteItemFromStorage(SPOTIFY_CODE);
      deleteItemFromStorage(SPOTIFY_USER_INFO);
      localStorage.removeItem("userInfo");
      localStorage.removeItem("spotifyUser");
      window.location.replace("/");
    }
  };

  return (

    <LoginStyle>
      <div className="background-col-1">
        <div className="background">
          <div className="expire-div">
            <Alert />
          </div>
          <h3 style={{}} className="h3">
            Your subscription has been cancelled!
          </h3>
          <p className="ppp">
            Subscribe now to enjoy your favourite playlists.
          </p>
          <button onClick={onFinish} className="btn2">
            Subscribe
          </button>
          <div className="dives">
            <button onClick={onBack} className="btn1">
              Logout
            </button>
          </div>
        </div>
      </div>
    </LoginStyle>
  
  );
};

export default CancelSub;
