import { Modal } from "antd";
import styled from "styled-components";

export const PaymentModalStyle2 = styled(Modal)`
width: 95% !important;
.ant-modal-content {
    background: #242429 !important;
  }
  .ant-modal-close {
    display: none;
  }
   .main-para {
    width: 692px;
    text-align: center;
    color: #fff;
    font-size: 17.53px;
    font-weight: 400;
    line-height: 30px;
    margin: 20px auto 60px auto;
    z-index: 1;
  }
  .pricing-container{
    width:100% !important;
  }
   h1 {
    color: #fff;
    font-size: 27px;
    font-weight: 600;
    line-height: 60px;
  }
   .main-title {
    font-size: 22.19px;
    color: white;
    font-weight: 700;
  }
   .sub-title {
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin: 10px 0px 20px 0px;
  }
   .SideStyleButton {
    width: 85%;
  }
   table {
    border-collapse: collapse;
    width: 100%;
  }
   td {
    border: 1px solid #d1d1d1;
    padding:  0px;
  }
   td p {
    /* // width: 333px; */
  }
   .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.5px;
    color: white;
    width: 85%;
    margin: auto;
  }
   .pricing-container {
    padding-bottom: 0px;
  }
   .SideStyleButton {
    background: #007aff;
    border-radius: 5px;
    border: 0px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    width: 176px;
    color: #fff;
  }
   .pricingPlanDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
   .pricingPlan {
    background-color: #242429;
    border: 0.8px solid #d1d1d1;
    box-shadow: 0px 0px 48px 0px #7987b014 inset;
  
    box-shadow: 8px 8px 29px 0px #50587114;
  
    box-shadow: 2px 2px 14px 0px #50587105;
    border-radius: 24px;
    padding: 20px 30px;
    max-width: 404px;
    width: 100%;
  }
   .innerPricingPlan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 12.33px;
    margin-bottom: 10px;
  }
   .packageName {
    opacity: 0.8;
  }
   .packageName h3 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: ;
    letter-spacing: 0px;
    text-align: left;
    color: #ffffff;
  }
   .packageName p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: ;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: left;
    margin-top: 8px;
  
    color: #ffffff;
  }
  .belowPrice{
    margin-bottom:10px;
   color: rgb(255, 255, 255);
   height: 20px;
    font-size: 12px;
  }
   .packagePrice {
    // margin-bottom: 24px;
    display: flex;
    align-items: flex-end;
  }
   .packagePrice h1 {
    font-family: Poppins;
    font-size: 54px;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: -1.7999999523162842px;
    text-align: left;
    color: #ffffff;
    text-wrap: nowrap;
  }
   .packagePrice p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: ;
    letter-spacing: 0px;
    text-align: left;
  
    color: #ffffff;
  }
   .priceLine {
    width: 100%;
    margin-bottom: 24px;
  }
   .features {
    width: 100%;
  }
   .features .feat {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }
   .features .feat p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: ;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    margin-left: 9px;
  }
   .getStarted {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    padding: 9px 0px;
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 8px;
  
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  
    cursor: pointer;
  }
  .annualMonth{
    
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color:#fff;
margin-bottom:32px;
z-index:1;
position:relative;
.div1{
  display:flex;
  align-items:center;
  justify-content:center;
}
.div2{
  display:flex;
  align-items:center;
  justify-content:center;
  }
p{
  margin-right:12px;
  font-size: 13px;
}
  }

  

`

 export const PaymentModalStyle = styled(Modal)`
 width: 95% !important;
.ant-modal-content {
    background: #242429 !important;
  }
  .ant-modal-close {
    display: none;
  }
  .pricing-container {
    width: 87%;
    text-align: center;
    margin: auto;
    padding-bottom: 150px;
    position: relative;
  }
    .currnecyropDown{
    font-size: 12.53px;
    }
  .pricing-container .main-para {
    width: 692px;
    text-align: center;
    color: #fff;
    font-size: 12.53px;
    font-weight: 400;
    line-height: 30px;
    margin: 0px auto 15px;
    z-index: 1;
  }
  .pricing-container h1 {
    color: #fff;
font-size: 35px;
    font-weight: 600;
    line-height: 50px;
  }
  .annualMonth{
    
      font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color:#fff;
  margin-bottom:32px;
  z-index:1;
  position:relative;
  .div1{
    display:flex;
    align-items:center;
    justify-content:center;
    .ant-switch{
    min-width: 37px !important;
    height: 16px !important;
     .ant-switch-handle{
    left: 1px;
    right:auto;
    top: 1px !important;
    width: 12px !important;
    height: 12px !important;
    }
    &.ant-switch-checked .ant-switch-handle{
    left: auto;
    right: 1px;
    }
    }
  }
  .div2{
    display:flex;
    align-items:center;
    justify-content:center;
    }
p{
  margin-right:12px;
  font-size: 13px;
}
  }
  .main-title {
    font-size: 22.19px;
    color: white;
    font-weight: 700;
  }
  .sub-title {
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin: 10px 0px 20px 0px;
  }
  .pricing-container .SideStyleButton {
    width: 85%;
  }
  .pricing-container table {
    border-collapse: collapse;
    width: 100%;
    z-index: 1;
    position: inherit;
  }
  td {
    border: 1px solid #d1d1d1;
    padding:  0px;
  }
  td p {
    // width: 333px;
  }
  .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.5px;
    color: white;
    width: 85%;
    margin: auto;
  }
  .pricingplanblob {
    position: absolute;
    top: -50%;
    right: -7.5%;
    z-index: 0;
  }

 `
