import styled from "styled-components";

export const TopNavbarStyle = styled.nav`
  display: grid;
  padding: 18px 20px 0px 0px;
  background: ${(props) => props.theme.background.page3};
  position: sticky !important;
  top: 0 !important;
  z-index: 1003;
  .left-item {
    display: flex;
    grid-gap: 0px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 10px 20px;
    padding-top: 0px;
  }
  .row-center{
     width: 90%; 
     display: flex; 
     justify-Content: center;
  }
  .divs {
    display: flex;
    justify-content:  space-around;
    align-items: center;
    color:  #c4c4c4;
    background: rgba(225,225,225, 0.3);
    padding:  10px;
    border-radius: 100px;
    font-weight: 700,
    cursor:  pointer;
  }
  .skeleton-buttons{
    height: 45px;
    width: 500px;
    border-radius: 100px;
  }
`;
