import { AddToPlayListModalStyle } from "../AddToPlayListModal/AddToPlayListModal.style";
// import { DateRangePicker } from 'react-date-range';
function ShareModalLoader({
  isModalVisible,
  setIsModalVisible
}) {

  return (
    <AddToPlayListModalStyle
      visible={isModalVisible}
      className={`tractDetailShare`}
      style={ { maxWidth: "450px", width: "100%" }}
      footer={false}
      title={false}
    >
      <>
        <div className="viewDetailInner">
          <>
            <div
              className="addToPlaylistHead"
              style={{ border: "none", padding: 0 }}
            >
              <h1 className="heading headingCenter">
                {/* {"Share URL"} */}
              </h1>
            </div>
            <div className="loadingDiv">
              <div className="loadingCircle"></div>
            </div>
            <p className="refreshText" style={{ marginTop: '15px' }}>
              {"Just a moment"}
            </p>
          </>
        </div>
      </>
    </AddToPlayListModalStyle>
  );
}

export default ShareModalLoader;
