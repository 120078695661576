import { Modal } from "antd";
import styled from "styled-components";

export const DeleteFromPlayListModal = styled(Modal)`
  .heading {
    font-size: 26px;
    color: ${(props) => props.theme.colors.primary};
  }

  .description {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primary};
  }

  .btn1 {
    padding:  20px;
    font-size: 16px;
    background: transparent;
    border: 2px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
  }

  .btn2 {
    padding:  20px;
    border: 2px solid #007aff;
    font-size: 16px;
    margin-left: 20px;
  }

  .h4 {
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    font-size: 19px;
    padding: 20px 0px 10px;
  }

  .row-space {
    display: flex;
    justify-content: space-between;
  }

  .row-center {
    display: flex;
    justify-content: center;
  }

  .p1 {
    padding: 0px 0px 30px;
    color: #788c9b;
  }

  .ant-modal-content {
    background-color: ${(props) => props.theme.background.secondary};
    color: ${(props) => props.theme.colors.primary};
    width: 600px;
    border: 1px solid white;
    border-radius: 7px;
    margin-left: -60px;
  }

  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .ant-select-selection-item {
    font-size: 15px !important;
  }
  .ant-select-selector {
    height: 49px !important;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .date {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }
  .ant-modal-mask {
    backdrop-filter: blur(5px) !important;
  }
  .footer {
    display: flex;
    gap: 5px;
    align-self: flex-end;
  }

  .DeleteFromPlayModalAlertWarning1 {
    display: block;
  }

  .DeleteFromPlayModalAlertWarning2 {
    display: none;
  }

  .row-space {
    flex-direction: row;
  }

  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      width: 100%;
      margin-left: 0%;
    }

    .ant-select .ant-select-selector,
    .ant-select-selection,
    .ant-select-item {
      font-size: 17px !important;
    }

    .ant-select-selection-item {
      font-size: 17px !important;
    }

    .DeleteFromPlayModalAlertWarning1 {
      display: none;
    }

    .DeleteFromPlayModalAlertWarning2 {
      display: block;
    }

    .h4 {
      font-size: 14px !important;
    }

    .p1 {
      font-size: 12px;
    }

    .btn1 {
      font-size: 14px;
    }

    .row-space {
      flex-direction: column-reverse;
    }

    .btn2 {
      margin-left: 0px !important;
      margin-bottom: 20px;
      font-size: 14px;
    }
  }
`;

export const DeleteFromPlayList = styled.div`
  .spanner {
    font-size: 15px;
    display: flex;
    color: ${(props) => props.theme.colors.primary};
  }
`;
