const lightTheme = {
  background: {
    primary: "#FFFFFF", // white
    primary4: "#070707", // white
    primary3: "#FFFFFF", // white
    primary5: "#FFFFFF", // white
    secondary: "white", // blue
    secondary1: "#F3F4F6", // blue
    page1: "linear-gradient(180deg, #515d83 -63.64%, #ffffff 89.7%)",
    page2: "linear-gradient(180deg, #90aea7 -63.64%, #ffffff 89.7%)",
    page3: "white",
    transparent: "rgba(225,225, 255,0.7)",
    transparent1: "rgba(225,225, 255,0.4)",
    main: "#E5E7EB",
    boxes: "#F9FAFB",
    // boxesNew: "#F3F4F6",
    boxesNew: "transparent",
    border: "#E5E7EB",
    hoverTable: "rgb(26, 26, 26, 0.4)",
    evenTable: "#E5E7EB",
    inputs: "#E5E7EB",
    inputsBorder: "#E5E7EB",
    hrBorder: "#E5E7EB",
    primary1: "linear-gradient(to right, #007aff 75%, white 25%)",
    primary2: "linear-gradient(to right, #007aff 100%, white 0%)",
    firstImages: "./static/firstImage2.png",
    droping: "#F3F4F6",
  },
  colors: {
    primary: "#1D1D1D", // black
    primary1: "#1D1D1D", // black
    secondary: "blue", //blue
    main1: "#E5E7EB",
    link: "045ED8",
    icon: "rgba(0, 122, 255, 0.4)",
    dropingColor: "#1D1D1D",
  },
  profile:"#242429"
};

const darkTheme = {
  background: {
    primary: "#1A1A1A",
    primary4: "#1A1A1A",
    primary3: "rgba(255,255,255,0.4)",
    primary5: "#242429",
    secondary: "#030303", // grey
    secondary1: "#242429", // grey
    main: "#2E2D2D",
    page1: " #000000",
    page2: "linear-gradient(180deg, #90aea7 -63.64%, #000000 89.7%)",
    page3:
      "linear-gradient(180deg,#404040 -96.96%,#1b1a1a 11.24%,#171717 65%),#ffffff",
    transparent: "rgba(0,0,0,0.7)",
    transparent1: "rgba(0,0,0,0.4)",
    boxes: "#303036",
    // boxesNew: "#0d0d0d",
    boxesNew: "transparent",
    border: "#2e2d2d",
    hoverTable: "#1a1a1a",
    evenTable: "rgba(255, 255, 255, 0.06)",
    inputs: "#333333",
    inputsBorder: "#474747",
    hrBorder: "#ffffff4d ",
    primary1: "linear-gradient(to right, #007aff 75%, black 25%)",
    primary2: "linear-gradient(to right, #007aff 100%, black 0%)",
    firstImages: "./static/firstImage.png",
    droping: "#191919",
    profile:"#242429"
  },
  colors: {
    primary: "#fff", // white
    primary1: "#ABABAB", // white
    main1: "rgba(255,255,255,0.4)",
    secondary: "white", // grey
    link: "#045ED8",
    icon: "rgba(46, 45, 45, 0.4)",
    dropingColor: "#c4c4c4",
  },
};

export { darkTheme, lightTheme };
