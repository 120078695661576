import styled from "styled-components";

export const TopNavbarStyle = styled.nav`
  display: grid;
  top: 0 !important;
  z-index: 1003;
  position:absolute;
  width:100%;
  .bta {
    // width: 25%;
    margin-left: 0.5%;
    margin-top: -10px;
  }
  .left-item {
    display: flex;
    grid-gap: 0px;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 20px 10px 20px;
    padding-top: 0px;
  }
  .left-item .drawerButton {
    display:none;
  }
  
  .leftclassnamepart {
    padding: 6px 20px 0px 0px;
    position: sticky !important;
    // background: ${(props) => props.theme.background.transparent};
  }

  .thememode {
    width: 10px;
    margin-left: 20px;
  }

  .leftitemclassName1 {
    display: block;
  }
  .leftitemclassName2 {
    display: none;
  }
  .centerLogo{
    display:none;
    align-items:center;
    justify-content:center;
    margin-bottom:18px;
  }
  .centerLogo img{
    width:147px;
  }
  .platformSelect{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 36px;
    border-radius: 8px;
    background: rgb(36, 36, 41);
    border: 0.6px solid rgba(255, 255, 255, 0.2);
    font-size: 12px;
    padding: 9.5px 16px;
    width: 127.33px !important;
    color: rgb(255, 255, 255) !important;
  }
  // .platformSelect .dropDownIcon{
  //   transform: rotate(180deg);
  // }


  @media only screen and (max-width: 768px) {
  .left-item .drawerButton{
    display:block;
    background: transparent;
    color: white;
    font-size: 21px;
    border: none;
    padding: 0px 10px;
    display: flex;
    align-items: center;

  }
  .left-item{
    padding: 10px;
    width: 100%;
    justify-content: space-between;
  }
  .switchAccount{
    display:none;
  }
  .left-item div:nth-child(2){
    display:none !important;
  }
  .centerLogo{
    display:flex;
  }
}
  @media only screen and (max-width: 600px) {
    
    .leftitemclassName2 {
      display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    width:100%;
    }
  
    .leftclassnamepart {
      padding: 18px 0px 0px 0px;
      position: inherit !important;
      background: transparent;
      width:100%;
    }

  
    .logo-image {
      width: 60%;
    }

    .acount-avatar-span {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 16.5px;
    }
  }
  .blurrScreen{
    position:absolute;
    top:0px;
    left:0px;
    background: linear-gradient(157.78deg, rgba(6, 5, 5, 0.32) -0.75%, rgba(0, 0, 0, 0) 100%),
linear-gradient(169.15deg, rgba(255, 255, 255, 0.1) 0%, rgba(238, 237, 237, 0.05) 96.79%);
width:100%;
height:calc(100vh - 80px);

backdrop-filter: blur(13px);

  }
`;
