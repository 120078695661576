import styled from "styled-components";

export const MusicCardStyle = styled.div`
  padding: 18px;
  border-radius: ;
  // background: rgba(255, 255, 255, 0.08);
  background: ${(props) => props.theme.background.boxesNew};
  border: 2px solid ${(props) => props.theme.background.boxesNew};
  transition: all 200ms linear;
  cursor: pointer;
  width: 100%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  .spanner {
    margin-left: 5px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
  }



  &:hover {
    // background: rgba(255, 255, 255, 0.03);
    background: rgba(0, 122, 255, 0.1);
    // background: #333333;
    border: 2px solid #007aff;
  }
.musicCard{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
  .row {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
.imageName{
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 81px );
}
.name{
  margin-left: 15.57px;
  width: calc(100% - 25.57px - 77.83px);
}
  .card-thumbnail {
    width: 55.83px;
    aspect-ratio: 1;
    border-radius: 0px;
  }

  .card-heading {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;

    overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
  }

  .card-description {
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
    opacity: 0.6;
    margin-top: -10px;
  }

  .card-paragraph {
    color: ${(props) => props.theme.colors.primary};
  }

  .playss {
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0px 20px 0px 0px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    margin-left: 12%;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .playss1 {
    border: 1px solid ${(props) => props.theme.colors.primary} !important;
    height: 60px;
    width: 40%;
    justify-content: center;
    font-size: ;
    display: flex;
    align-items: center;
    border-radius: 100px;
    margin-left: 12%;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .playButton{
    border: 1px solid ${(props) => props.theme.colors.primary} !important;
    border-radius: 100%;
    background:transparent;
    width:41.51px;
    height:41.51px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-left: 10px;
  }
  .playSpot{
    margin-left:10px;
  }
  .playSpot img{
    
    width:35.51px;
    height:35.51px;
  }
  .popularButton{
    display:flex;
    align-items:center;
  }
  .popularity{
    margin-right:0px;
    
    display:flex;
    align-items:center;
  }
  .popularity svg{
      margin-right:5px
  }
`;

export const MusicCardStyle1 = styled.div`
  padding: 18px;
  border-radius: ;
  // background: rgba(255, 255, 255, 0.08);
  background: rgba(0, 122, 255, 0.1);
  border: 2px solid #007aff;
  transition: all 200ms linear;
  cursor: pointer;
  width: 300px;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-width: 1366px) {
    width: 250px;
  }
  @media only screen and (min-width: 1536px) {
    width: 300px;
  }

  // &:hover {
  //   // background: rgba(255, 255, 255, 0.03);
  //   background: rgba(0, 122, 255, 0.1);
  //   // background: #333333;
  //   border: 2px solid #007aff;
  // }

  .row {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .imageName{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 81px );
  }
  .name{
    margin-left: 15.57px;
    width: calc(100% - 25.57px - 77.83px);
  }
  .card-thumbnail {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 0px;
  }

  .card-heading {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.primary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .card-description {
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
    opacity: 0.6;
    margin-top: -10px;
  }

  .playss {
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0px 20px 0px 0px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    margin-left: 12%;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .playss1 {
    border: 1px solid ${(props) => props.theme.colors.primary} !important;
    height: 60px;
    width: 40%;
    justify-content: center;
    font-size: ;
    display: flex;
    align-items: center;
    border-radius: 100px;
    margin-left: 12%;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .playButton{
    border: 1px solid ${(props) => props.theme.colors.primary} !important;
    border-radius: 100%;
    background:transparent;
    width:41.51px;
    height:41.51px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-left: 10px;
  }
  .popularButton{
    display:flex;
    align-items:center;
  }
  .popularity{
    margin-right:0px;
    
    display:flex;
    align-items:center;
  }
  .popularity svg{
      margin-right:5px
  }
`;
