import styled from "styled-components";

export const SpotifyRedirectStyle = styled.a`
  display: flex;
  align-items: center;
  min-width: 120px;

  .redirect-text {
    color: #fff;
    font-size: 12px;
    margin-left: 5px;
  }
  .images {
    opacity: 0.7;
  }
  .images:hover {
    opacity: 1;
  }
`;
