import React, { useEffect, useState, useRef } from 'react';
import { PrismCode } from 'react-prism';
import { Player, ControlBar } from 'video-react';
import { Button } from 'antd';

interface UserGuideVideoProps {
    src?: string;
}




const UserGuideVideo: React.FC<UserGuideVideoProps> = ({ src }) => {
    const [source, setSource] = useState('http://media.w3.org/2010/05/sintel/trailer.mp4');
    const [playerState, setPlayerState] = useState<any>(null); // Change 'any' to the appropriate type
    const [isFullScreen, setIsFullScreen] = useState(false)
    const playerRef = useRef<Player>(null);
    const guideVideoDiv = useRef(null)
    const [playing, setPlaying] = useState(true)

    const setMuted = (muted: boolean) => {
        return () => {
            if (playerRef.current) {
                playerRef.current.muted = muted;
            }
        };
    };

    const handleStateChange = (state: any) => {
        setPlayerState(state);
    };

    const play = () => {
        if (playerRef.current) {
            playerRef.current.play();
            setPlaying(true)
        }
    };

    const pause = () => {
        if (playerRef.current) {
            playerRef.current.pause();
            setPlaying(false)
        }
    };

    const load = () => {
        if (playerRef.current) {
            playerRef.current.load();
        }
    };

    const changeCurrentTime = (seconds: number) => {
        return () => {
            const { player } = playerRef.current?.getState() || {};
            if (playerRef.current) {
                playerRef.current.seek(player.currentTime + seconds);
            }
        };
    };

    const seek = (seconds: number) => {
        return () => {
            if (playerRef.current) {
                playerRef.current.seek(seconds);
            }
        };
    };

    const changePlaybackRateRate = (steps: number) => {
        return () => {
            const { player } = playerRef.current?.getState() || {};
            if (playerRef.current) {
                playerRef.current.playbackRate = player.playbackRate + steps;
            }
        };
    };

    const changeVolume = (steps: number) => {
        return () => {
            const { player } = playerRef.current?.getState() || {};
            if (playerRef.current) {
                playerRef.current.volume = player.volume + steps;
            }
        };
    };


    const toggleFullscreen = () => {
        if (playerRef.current) {
            // Check if fullscreen is currently active
            const isFullscreen = document.fullscreenElement || (document as any).webkitFullscreenElement;
            // setIsFullScreen(true)

            if (!isFullscreen) {
                // Enter fullscreen mode
                if (playerRef.current.toggleFullscreen) {
                    playerRef.current.toggleFullscreen();
                } else if (playerRef.current.video) {
                    // For browsers that don't support toggleFullscreen
                    if (playerRef.current.video.requestFullscreen) {
                        playerRef.current.video.requestFullscreen();
                    } else if ((playerRef.current.video as any).webkitRequestFullscreen) {
                        (playerRef.current.video as any).webkitRequestFullscreen();
                    }
                }
                setIsFullScreen(false);
            } else {
                // Exit fullscreen mode
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if ((document as any).webkitExitFullscreen) {
                    (document as any).webkitExitFullscreen();
                }
                setIsFullScreen(true);
            }
        }
    };


    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.subscribeToStateChange(handleStateChange);
        }
    }, []);

    const handleFullscreenChange = () => {
        setIsFullScreen(!!(document.fullscreenElement || (document as any).webkitFullscreenElement));
    };

    useEffect(() => {
        if (playerRef.current) {
            // var videoElements = document.getElementsByClassName('video-react-video');

            // // Check if there are any video elements with the specified class
            // if (videoElements.length) {
            //     // Convert HTMLCollection to an array and then use forEach
            //     Array.from(videoElements).forEach(function (element) {
            //         if (element instanceof HTMLVideoElement) {
            //             element.controls = true;
            //         }
            //     });
            // }


            document.addEventListener('fullscreenchange', handleFullscreenChange);
            document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

            return () => {
                document.removeEventListener('fullscreenchange', handleFullscreenChange);
                document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            };
        }
    }, []);

    return (
        <div className='guideVideoDiv' ref={guideVideoDiv}>
            {/* <img src={src}/> */}
            <Player ref={playerRef} autoPlay muted controls={true}>
                <source src={src} />
                <ControlBar />
            </Player>
            {/* {playing ? <div className='controlsDiv'>
                <button className='skipTimeButtons fullScreenBtton' onClick={toggleFullscreen}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"></path></svg>
                </button>
                <button className='skipTimeButtons' onClick={changeCurrentTime(-10)}>
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M7 9l-3 -3l3 -3"></path><path d="M15.997 17.918a6.002 6.002 0 0 0 -.997 -11.918h-11"></path><path d="M6 14v6"></path><path d="M9 15.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z"></path></svg>
                </button>
                <button onClick={pause} className="playPauseBtn pauseBtn">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M96 448h106.7V64H96v384zM309.3 64v384H416V64H309.3z"></path></svg>
                </button>
                <button className='skipTimeButtons' onClick={changeCurrentTime(10)}>
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M17 9l3 -3l-3 -3"></path><path d="M8 17.918a5.997 5.997 0 0 1 -5 -5.918a6 6 0 0 1 6 -6h11"></path><path d="M12 14v6"></path><path d="M15 15.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z"></path></svg>
                </button>
            </div>
                : <div className='controlsDiv'>
                    <button onClick={play} className="playPauseBtn ">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M96 52v408l320-204L96 52z"></path></svg>
                    </button>
                </div>} */}
            {/* <div className="py-3">
                <Button onClick={pause} className="mr-3">
                    pause()
                </Button>
                <Button onClick={load} className="mr-3">
                    load()
                </Button>
            </div>
            <div className="pb-3">
                <Button onClick={changeCurrentTime(10)} className="mr-3">
                    currentTime += 10
                </Button>
                <Button onClick={changeCurrentTime(-10)} className="mr-3">
                    currentTime -= 10
                </Button>
                <Button onClick={seek(50)} className="mr-3">
                    currentTime = 50
                </Button>
            </div> */}
            {/*

            <div className="pb-3">
                <Button onClick={changePlaybackRateRate(1)} className="mr-3">
                    playbackRate++
                </Button>
                <Button onClick={changePlaybackRateRate(-1)} className="mr-3">
                    playbackRate--
                </Button>
                <Button onClick={changePlaybackRateRate(0.1)} className="mr-3">
                    playbackRate+=0.1
                </Button>
                <Button onClick={changePlaybackRateRate(-0.1)} className="mr-3">
                    playbackRate-=0.1
                </Button>
            </div>
            <div className="pb-3">
                <Button onClick={changeVolume(0.1)} className="mr-3">
                    volume+=0.1
                </Button>
                <Button onClick={changeVolume(-0.1)} className="mr-3">
                    volume-=0.1
                </Button>
                <Button onClick={setMuted(true)} className="mr-3">
                    muted=true
                </Button>
                <Button onClick={setMuted(false)} className="mr-3">
                    muted=false
                </Button>
            </div> */}
        </div>
    );
};

export default UserGuideVideo;