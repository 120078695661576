import React from "react";

interface FirstSectionProps {
  onOpen: any;
  onOpenSignUp: any
}

const FirstSection: React.FC<FirstSectionProps> = ({onOpen}) => {


  const handleGoogleFormSubmit = async () => {
    const domain = "dev-2z4p074m.us.auth0.com";
    const client_id = process.env.REACT_APP_AUTH0;
    const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
    const scope = "openid profile email";
    const response_type = "token";
    const CONNECTION = "google-oauth2";
    const redirect_uri = `${window.location.origin}/shift`;
    window.location.replace(
      `https://${domain}/authorize?` +
      `audience=${audience}&` +
      `connection=${CONNECTION}&` +
      `scope=${scope}&` +
      `response_type=${response_type}&` +
      `client_id=${client_id}&` +
      `redirect_uri=${redirect_uri}`
    );
  };

  return (
    <div className="SideStyleDiv" id="Home">
      <div className="first-col">
        <div className="making" style={{}}>
          <h1 className="heading">
            THE #1 PLAYLIST
            <br /> MANAGEMENT TOOL
          </h1>
          <h4 className="sub-heading">
            Take your playlist management to the next level.
          </h4>
          <p className="paragraph">
            <i>
              {" "}
              Redefine the way you schedule and place the songs in your
              playlists. Our powerful tool allows you to schedule songs for any
              period of time. Be in control of when the song gets placed and
              when it gets removed, schedule unreleased songs, work with
              multiple profiles at the same time, Add notes to placements, use
              both Spotify and Apple Music and get analytics on your songs and
              playlists. Automate your playlist placements and focus on what's
              most important - curation.
            </i>
          </p>
          <div className="new-button">
            {/* <button className="btn1">
              <img src="/google.png" />{" "}
              <span style={{ marginLeft: "5px" }}>Continue with Google</span>
            </button> */}
            <button className="combine-button" onClick={handleGoogleFormSubmit}>
              <span className="btn-svg">
                <img alt="google.svg" src="./static/google.svg" />
              </span>
              <div className="btn-text">Continue with Google</div>
            </button>
            <p className="SideStyleOr">or</p>
            <button onClick={onOpen} className="btn2">START NOW </button>
          </div>
        </div>
      </div>
      <div className="firstImage">
        <div className="secondImage"></div>
      </div>
    </div>
  );
};

export default FirstSection;
