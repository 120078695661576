import React from "react";
import { SongsTable } from "../../components/SearchSongsTable";
import { SearchStyle } from "./Search.style";
interface SearchProps { }

const Search: React.FC<SearchProps> = () => {
  return (
    // <InnerPageLayout>
      <SearchStyle>
        <SongsTable />
      </SearchStyle>
    // </InnerPageLayout>
  );
};

export default Search;
