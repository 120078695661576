import { Modal } from "antd";
import styled from "styled-components";

export const AddToPlayListModalStyle = styled(Modal)`
  display:flex !important;
  align-items:center !important;
  justify-content:center !important;
  .heading {
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
font-size: 15px;
font-weight: 500;
letter-spacing: 0em;
text-align: left;
margin-bottom:8px;

  }
  .headingCenter{
    text-align:center;
  }
  .btn2 {
    padding: 20px 10px;
  }
  .btn4 {
    border: 1px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    padding: 14px 18px;
    font-weight: 600;
  }

  .description {
    color: ${(props) => props.theme.colors.primary};
    font-family: Public Sans;
font-size: 12px;
font-weight: 300;
letter-spacing: 0em;
text-align: left;
opacity:0.5;

  }
.refreshDescription{
font-size: 12px;
margin:0px;
}
  .ant-modal-mask {
    backdrop-filter: blur(5px) !important;
  }
  .ant-modal-content {
    backdrop-filter: blur(5px);
    color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.background.secondary1};
  border: 1px solid #FFFFFF4D;
    max-width: 618px;
    border-radius: 20px;
    margin-left: -60px;
  width:100%;
}
  &.tractDetailShare{
  max-width:945px;
  width:100% !important;
  }
  &.tractDetailShare .ant-modal-content{
  max-width:945px;
  width:100%;
  }
  &.refresh .ant-modal-content{
    width:400px
  }
  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .ant-select-selection-item {
    font-size: 15px !important;
    isplay: flex !important;
    align-items: center !important;
  }
  .ant-select-selector {
    height: 49px !important;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }



  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }

  .footer {
    display: flex;
    gap: 10px;
    align-self: flex-end;
  }
  .footer.refreshModalFooter {
    display: flex;
    justify-content: flex-end;
    align-items:center;
    align-self: unset;
  }
  .footer.refreshModalFooter .deleteButton{
  background:transparent;
  border:1px solid red;
  color:red;
  padding:8px 10px;
  }
  .footer.refreshModalFooter .yesNoButtons{
    display: flex;
    justify-content: space-between;
    align-items:center;
  }

  .rowForm{
    display:flex;
    justify-content:space-between;
  }

  .ant-select,.ant-input,.ant-picker, .playlistInput{
    background-color:transparent;
    color:white;
    width:100% !important;
    // height: 40px !important;
    display:flex;
    align-items:center;
    font-size:12px;
    position:relative;
  }
  .playlistInputText{
    padding:5px 10px;

    min-height: 30px;
    width: calc(100%);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 43px;
  }
  .playlistInputText .playlistInputTextSearch{
    outline: none;
    background: transparent;
    background-color: transparent;
    border: none;
    padding:2.5px;
  }
 
  .playlistInputText span{
    background: #ffffff2e;
    border-radius: 4px;
    padding: 2px 10px;
    margin-right: 10px;
    white-space:nowrap;
  }
  .ant-input,.ant-picker, .playlistInput{
    border: 0.6px solid #FFFFFF33 !important;
    border-radius:8px !important;
  }
  .playlistInputText .ant-input{
    border:none !important; 
    padding:2.5px;
  }
  .ant-picker input{
    color:${(props) => props.theme.colors.primary} !important;
  }
  .ant-select-selector{
    background-color:transparent !important;
    color:${(props) => props.theme.colors.primary};
    border: 0.6px solid #FFFFFF33 !important;
    border-radius:8px !important;
    height: 40px !important;
  }
  .addNoteText{
    height: 133px !important;
    color:white
  }
    .textarea_count{
    text-align:right;
    font-size: 12px;
    }
  .ant-select-selection-search{
    display:flex;
    align-items:center;
  }
  .addToPlaylistHead{
    border-bottom: 1px solid #ffffff4d;
    padding-bottom: 16px;
  }
  .formAddToPlaylist{
    // border-bottom: 1px solid #ffffff4d;
    padding-top: 16px;
  }
    .ant-picker-input>input{
        font-size: 12px;
    }
  .playlistPosition{
    display:flex;
    justify-content:space-between;
    width:100%;
    margin-bottom:20px;
    .ant-input{
    height:43px;
    }
  }
  .editEndDate .ant-picker {
    margin-top:8px;
  }
  .release{
    display:flex;
    // justify-content:space-between;
    // width:50%
    margin-bottom:24px;
    margin-top: 15px;
    position:relative;
  }
  .release span{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
  }
   label{
   width: calc(50% - 10px) !important;
    font-size: 12px;
  }

  .fullWidth{
    width:100% !important;
  }
   label p{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom:8px;
    
  }
     .scheduleText{
font-size: 12px;
    opacity: 0.5;
  }
      .scheduleButton{
      width: 180px;
    height: 42px;
    background: transparent;
    border-radius: 8px;
    outline: none;
    border-color: #FFFFFF33;
    margin-right: 10px;
        font-size: 12px;
    }
    .datePick{
    display:inline-block !important;
    }
  .scheduleTime{
  display:flex;
  align-items:center;
  justify-content:flex-start;
  .ant-select, .ant-picker {
  height:40px;
  } 
 
  
  .ant-picker {
  margin-left:15px;
  } 
  }
  .addNoteTextLabel p{
    margin-bottom:8px;
  }
  .EditNoteText{
    background: #303036;
    padding:14px;
    font-size: 11px;
  }
  .editNote{
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom:10px !important;    
  }
  .addNoteTextLabel{
    margin-bottom:24px;
    position:relative;
  }
  .release input{
    display:none;
  }
  .release label{
    display:flex;
    align-items:center;
    margin-right: 24px;
    cursor:pointer;
  }
  .release label .radioCheck{
    width: 16px;
    height: 16px;
    border: 1px solid #007AFF;
    border-radius: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right: 10px;
  }
  .release label .radioCheck div{
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background:#007AFF;
  }

  .addSong{
width: 120px;
    height: 38px;
padding: 12px, 32px, 12px, 32px;
border-radius: 8px;
background: #007AFF;
    border:none;
    cursor:pointer;
    font-size: 12px;
    &:disabled{
      opacity:0.5;
    }
  }
  .loadingDiv{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .limitText{
    color: red;
    font-size: 10px;
    margin: 0px;
  }
  .loadingCircle{
    width: 85px;
    height: 85px;
    border: 5px solid #007AFF;
    border-radius: 90px;
    border-left: 5px solid transparent;
    animation: refreshRound 1s infinite;
  }
  .refreshText{
    text-align:center;
    opacity:0.7;
    font-size:18px;
  }
  .editNoteButton{
    display:flex;
    align-items:center;
    justify-content: flex-end;
  }
  .editNoteButton .deleteButton{
background:#ea43351f;
height:48px;
width: 150px;
color:#EA4335;
border: 1.5px solid #EA4335;
border-radius: 8px;
margin-right:12px;

font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
cursor:pointer;

  }
  .editNoteButton .editButton{
    background:#007AFF1f;
    height: 38px;
    width: 120px;
    color:#007AFF;
    border: 1.5px solid #007AFF;
    border-radius: 8px;

    font-family: Poppins;
    font-size: 12px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
cursor:pointer;

      }

      &.viewDetail .ant-modal-content{
        width:649px;
        max-width: 649px;

      }

      &.viewDetail .ant-modal-body{
        width: 649px
      }
      .viewDetailInner{
        width:100%;
      }
      .viewDetailInner .albumPic{
            width: 72px;
    height: 72px;
      }
      .viewDetailInner .heading{
        margin-bottom:14px;
      }
      .viewDetailInner .albumDiv{
        display:flex;
        align-items:flex-end;
      }
      .viewDetailInner .albumDetail{
        margin-left: 14px;
      }
      .viewDetailInner .albumDetail h3{
        font-size: 15px;
        font-family: Poppins;
        font-weight: 600;
 line-height: normal;
        letter-spacing: 0.04em;
        text-align: left;
        margin-bottom:4px;
        color:white;
      }
      
      .viewDetailInner .albumDetail p{
      font-family: Poppins;
    font-size: 12px;
font-weight: 400;
line-height: 23px;
letter-spacing: -0.02em;
text-align: left;
opacity:0.70;
      }
      .viewDetailInner .detailBoxes1{
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-top:20px;
      }
      .viewDetailInner .detailBoxes1 .box{
        padding:8px 0px;
        background:#303036;
        border: 0.6px solid #FFFFFF33;
        border-radius:16px;
        width:187px;
        display:flex;
        flex-direction:column;
        justify-align:center;
        align-items:center;
      }
      .viewDetailInner .detailBoxes2{
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-top:20px;
      }
      .viewDetailInner .detailBoxes2 .box{
        padding:8px 0px;
        background:#303036;
        border: 0.6px solid #FFFFFF33;
        border-radius:16px;
        width:187px;
        display:flex;
        flex-direction:column;
        justify-align:center;
        align-items:center;
      }
      .viewDetailInner .detailBoxes1 .box p{
        color:white;
        font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: normal;
letter-spacing: 0.04em;
text-align: center;
margin-bottom:0px;

      }
      .viewDetailInner .detailBoxes1 .box h4{
        color:white;
        font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: normal;
letter-spacing: 0.04em;
text-align: center;

      }
      
      .viewDetailInner .detailBoxes2 .box p{
        color:white;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
        text-align: center;
        

      }
      .viewDetailInner .detailBoxes2 .box h4{
        color:white;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        

      }
      .viewDetailInner .note{
        padding:20px;
        margin-top:20px;
        background:#303036;
        border: 1px solid #FFFFFF33;
        border-radius:20px;
      }
      .viewDetailInner .note h3{
        color:white;
        font-size:12px;
      }
      .viewDetailInner .note p{
        opacity:0.8;
        font-size:12px;
      }
      .playlistNameViewDetail{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        overflow:hidden;
        width:100%;
        
      }
@keyframes refreshRound {
  0%{
    transform: rotate(0deg);
  }
  0%{
    transform: rotate(359deg);
  }
}
  .buttonDiv{
    width:100%;
    justify-content: flex-end !important;
    display: flex;
    padding-top:24px;
  }
  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      width: 100%;
      margin-left: 0%;
    }
    .ant-select .ant-select-selector,
    .ant-select-selection,
    .ant-select-item {
      font-size: 17px !important;
    }
    .btn2 {
      padding: 24px !important;
    }
  }

  .verisonsUi{
  p{
      font-size: 12px;
  }
    .verisons{
      display: flex;
      align-items: center;
      margin:10px 0px;
      cursor:pointer;
          font-size: 12px;
      .checkVersion{
        border: 2px solid #ffffff59;
        aspect-ratio: 1;
        width: ;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-right: 10px;
        color: #ffff;
        width: 18px;
    height: 18px;
      }
      span{
        color: #ffffff5e;
        margin-left:10px;
      }
    }
  }
  .checkBoxSaveVersion{
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    .note{
      width:100%;
      margin-bottom:10px;
      font-size:12px;
      display:flex;
    }
    .checkVersion{
      border: 2px solid #ffffff59;
      aspect-ratio: 1;
      width: ;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-right: 10px;
      color: #ffff;
      width: 18px;
    height: 18px;
    }
    span{
      color: #ffffff5e;
      margin-left:10px;
    }
  }
  }
  .filterSelect{

    .playlistInputText .playlistInputTextSearch{
      outline: none;
      background: transparent;
      background-color: transparent;
      border: none;
    }
  }
    .songURlCopy{
    display:flex;
    align-items:center;
    justify-content:space-between;
    span{
        display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    white-space:nowrap;
    width: calc(100% - 139px);
    }
      .copyUrlSong{
background: rgb(0, 122, 255);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: white;
    padding: 7px 21px;
    border: none;
}
}
.shareSongLogo{
    object-fit: contain;
    object-position: left;
    margin-bottom: 12px;
    }
.songDetailTime{
display:flex;
align-items:center;
justify-content:space-between;
.MuiPickersCalendarHeader-root {
position:relative;
}
.css-gqp4xl-MuiPickersLayout-root{
background:transparent;
}
.MuiPickersCalendarHeader-labelContainer {
    text-align: center;
    width: 100%;
    display: flex;
  align-items:center;
  justify-content:center;
}
 .MuiPickersArrowSwitcher-root {
     opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0px;
    }   
.MuiDateRangeCalendar-root, .MuiPickersLayout-root {
    background-color: rgb(48, 48, 54);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
div[style*="position: absolute"] {
    display: none;
  }
  .MuiIconButton-root{
  color:white;
  }
  .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel,.MuiDayCalendar-weekDayLabel{
  color:white;
  }
  .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day,.uiDateRangePickerDay-rangeIntervalDayPreviewStart,.MuiPickersDay-root{
  color:white;
  }
} 
.MuiPickersCalendarHeader-root{
margin-top:0px
}
}
 .songDetail{
    background-color: #303036;
    padding: 22px 24px;
    width: calc(100% - 335px);
    min-height:350px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    .albumDiv{
      margin-bottom:24px;
      .albumPic{
        border-radius:8px;
        }
    }
    .dateDiv{
      font-size:15px;
      line-height:27px;
      color:white;
      display:flex;
      align-items:center;
      justify-content:space-between;
      padding:12px 26px;
      border: 1px solid #FFFFFF33;
      border-radius:8px;
        .date{
          display:flex;
          align-items:center;
          svg{ 
           margin-right:11px;
          }
        }
      }   

  }
       .playlistPositionDetail{
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-top:15px;
    .playlistPositionDiv{
      width:calc(50% - 14.5px);
      background-color: #303036;
      font-size:15px;
      line-height:27px;
      color:white;
      display:flex;
      align-items:center;
      justify-content:space-between;
      padding:12px 26px;
      border: 1px solid #FFFFFF33;
      border-radius:8px;
    }
 }
    .SongplaceText{
    font-size:13px;
    color:white;
    margin-top:30px;
    text-align:center;
    }
`;


export const ScheduleModalStyle = styled(Modal)`
  display:flex !important;
  align-items:center !important;
  justify-content:center !important;
  width:100% !important;
  .heading {
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
font-size: 15px;
font-weight: 500;
letter-spacing: 0em;
text-align: left;
margin-bottom:8px;

  }
.ant-modal-body{
gap:20px;
}
  .headingCenter{
    text-align:center;
  }
  .btn2 {
    padding: 20px 10px;
  }
  .btn4 {
    border: 1px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    padding: 14px 18px;
    font-weight: 600;
  }

  .description {
    color: ${(props) => props.theme.colors.primary};
    font-family: Public Sans;
font-size: 12px;
font-weight: 300;
letter-spacing: 0em;
text-align: left;
opacity:0.5;

  }
.refreshDescription{
font-size: 12px;
margin:0px;
}
  .ant-modal-mask {
    backdrop-filter: blur(5px) !important;
  }
  .ant-modal-content {
    backdrop-filter: blur(5px);
    color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.background.secondary1};
  border: 1px solid #FFFFFF4D;
    max-width: 800px;
    border-radius: 20px;
    margin-left: -60px;
  width:100%;
      padding: 20px 40px;
}
  &.refresh .ant-modal-content{
    width:400px
  }
  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding:0px
  }

  .ant-select-selection-item {
    font-size: 15px !important;
    isplay: flex !important;
    align-items: center !important;
  }
  .ant-select-selector {
    height: 49px !important;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }



  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }

  .footer {
    display: flex;
    gap: 10px;
    align-self: flex-end;
  }
  .footer.refreshModalFooter {
    display: flex;
    justify-content: center;
    align-items:center;
    align-self: unset;
  }
  .footer.refreshModalFooter .deleteButton{
  background:transparent;
  border:1px solid red;
  color:red;
  padding:8px 10px;
  }
  .footer.refreshModalFooter .yesNoButtons{
    display: flex;
    justify-content: space-between;
    align-items:center;
  }
.yesNoButtons button{
height : 50px !important;
font-size:12px !important;
width:200px !important
}
  .rowForm{
    display:flex;
    justify-content:space-between;
  }

  .ant-select,.ant-input,.ant-picker, .playlistInput{
    background-color:transparent;
    color:white;
    width:100% !important;
    // height: 40px !important;
    display:flex;
    align-items:center;
    font-size:12px;
    position:relative;
  }
  .playlistInputText{
    padding:5px 10px;

    min-height: 30px;
    width: calc(100%);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 43px;
  }
  .playlistInputText .playlistInputTextSearch{
    outline: none;
    background: transparent;
    background-color: transparent;
    border: none;
    padding:2.5px;
  }
 
  .playlistInputText span{
    background: #ffffff2e;
    border-radius: 4px;
    padding: 2px 10px;
    margin-right: 10px;
    white-space:nowrap;
  }
  .ant-input,.ant-picker, .playlistInput{
    border: 0.6px solid #FFFFFF33 !important;
    border-radius:8px !important;
  }
  .playlistInputText .ant-input{
    border:none !important; 
    padding:2.5px;
  }
  .ant-picker input{
    color:${(props) => props.theme.colors.primary} !important;
  }
  .ant-select-selector{
    background-color:transparent !important;
    color:${(props) => props.theme.colors.primary};
    border: 0.6px solid #FFFFFF33 !important;
    border-radius:8px !important;
    height: 40px !important;
  }
  .addNoteText{
    height: 133px !important;
    color:white
  }
  .ant-select-selection-search{
    display:flex;
    align-items:center;
  }
  .addToPlaylistHead{
    border-bottom: 1px solid #ffffff4d;
    padding-bottom: 16px;
  }
  .formAddToPlaylist{
    // border-bottom: 1px solid #ffffff4d;
    padding-top: 16px;
  }
    .ant-picker-input>input{
        font-size: 12px;
    }
  .playlistPosition{
    display:flex;
    justify-content:space-between;
    width:100%;
    margin-bottom:20px;
    .ant-input{
    height:33px;
    }
  }
  .editEndDate .ant-picker {
    margin-top:8px;
  }
  .release{
    display:flex;
    // justify-content:space-between;
    // width:50%
    margin-bottom:24px;
    margin-top: 15px;
    position:relative;
  }
  .release span{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
  }
   label{
   width: calc(50% - 10px) !important;
    font-size: 12px;
  }

  .fullWidth{
    width:100% !important;
  }
   label p{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom:8px;
    
  }
     .scheduleText{
font-size: 12px;
    opacity: 0.5;
  }
      .scheduleButton{
      width: 180px;
    height: 42px;
    background: transparent;
    border-radius: 8px;
    outline: none;
    border-color: #FFFFFF33;
    margin-right: 10px;
        display: flex;
    align-items: center;
    justify-content: center;
    transition:.3s ease-in-out;
        font-size: 12px;
  }
    .scheduleButton img{
      width: 20px;
      height: 20px;
    }
    .scheduleButton:hover{
background:#007AFF
  }
.scheduleButtonActive{
background:#007AFF
  }

  .scheduleTime{
  display:flex;
  justify-content:center;
  .ant-select, .ant-picker {
  height:40px;
  } 
 
  .ant-picker input{
    display:none;
  
  }
  .ant-picker {
    display:none;

  margin-left:15px;
  } 
  }
  .addNoteTextLabel p{
    margin-bottom:8px;
  }
  .EditNoteText{
    background: #303036;
    padding:14px;
    font-size: 11px;
  }
  .editNote{
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom:10px !important;    
  }
  .addNoteTextLabel{
    margin-bottom:24px;
    position:relative;
  }
  .release input{
    display:none;
  }
  .release label{
    display:flex;
    align-items:center;
    margin-right: 24px;
    cursor:pointer;
  }
  .release label .radioCheck{
    width: 16px;
    height: 16px;
    border: 1px solid #007AFF;
    border-radius: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right: 10px;
  }
  .release label .radioCheck div{
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background:#007AFF;
  }

  .addSong{
width: 120px;
    height: 38px;
padding: 12px, 32px, 12px, 32px;
border-radius: 8px;
background: #007AFF;
    border:none;
    cursor:pointer;
    font-size: 12px;
    &:disabled{
      opacity:0.5;
    }
  }
  .loadingDiv{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .limitText{
    color: red;
    font-size: 10px;
    margin: 0px;
  }
  .loadingCircle{
    width: 85px;
    height: 85px;
    border: 5px solid #007AFF;
    border-radius: 90px;
    border-left: 5px solid transparent;
    animation: refreshRound 1s infinite;
  }
  .refreshText{
    text-align:center;
    opacity:0.7;
    font-size:18px;
  }
  .editNoteButton{
    display:flex;
    align-items:center;
    justify-content: flex-end;
  }
  .editNoteButton .deleteButton{
background:#ea43351f;
height:48px;
width: 150px;
color:#EA4335;
border: 1.5px solid #EA4335;
border-radius: 8px;
margin-right:12px;

font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
cursor:pointer;

  }
  .editNoteButton .editButton{
    background:#007AFF1f;
    height: 38px;
    width: 120px;
    color:#007AFF;
    border: 1.5px solid #007AFF;
    border-radius: 8px;

    font-family: Poppins;
    font-size: 12px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
cursor:pointer;

      }

      &.viewDetail .ant-modal-content{
        width:649px;
        max-width: 649px;

      }

      &.viewDetail .ant-modal-body{
        width: 649px
      }
      .viewDetailInner{
        width:100%;
      }
      .viewDetailInner .albumPic{
            width: 72px;
    height: 72px;
      }
      .viewDetailInner .heading{
        margin-bottom:24px;
      }
      .viewDetailInner .albumDiv{
        display:flex;
        align-items:flex-end;
      }
      .viewDetailInner .albumDetail{
        margin-left: 14px;
      }
      .viewDetailInner .albumDetail h3{
        font-size: 15px;
        font-family: Poppins;
        font-weight: 600;
 line-height: normal;
        letter-spacing: 0.04em;
        text-align: left;
        margin-bottom:4px;
        color:white;
      }
      
      .viewDetailInner .albumDetail p{
      font-family: Poppins;
    font-size: 12px;
font-weight: 400;
line-height: 23px;
letter-spacing: -0.02em;
text-align: left;
opacity:0.70;
      }
      .viewDetailInner .detailBoxes1{
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-top:20px;
      }
      .viewDetailInner .detailBoxes1 .box{
        padding:8px 0px;
        background:#303036;
        border: 0.6px solid #FFFFFF33;
        border-radius:16px;
        width:187px;
        display:flex;
        flex-direction:column;
        justify-align:center;
        align-items:center;
      }
      .viewDetailInner .detailBoxes2{
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-top:20px;
      }
      .viewDetailInner .detailBoxes2 .box{
        padding:8px 0px;
        background:#303036;
        border: 0.6px solid #FFFFFF33;
        border-radius:16px;
        width:187px;
        display:flex;
        flex-direction:column;
        justify-align:center;
        align-items:center;
      }
      .viewDetailInner .detailBoxes1 .box p{
        color:white;
        font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: normal;
letter-spacing: 0.04em;
text-align: center;
margin-bottom:0px;

      }
      .viewDetailInner .detailBoxes1 .box h4{
        color:white;
        font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: normal;
letter-spacing: 0.04em;
text-align: center;

      }
      
      .viewDetailInner .detailBoxes2 .box p{
        color:white;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
        text-align: center;
        

      }
      .viewDetailInner .detailBoxes2 .box h4{
        color:white;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        

      }
      .viewDetailInner .note{
        padding:20px;
        margin-top:20px;
        background:#303036;
        border: 1px solid #FFFFFF33;
        border-radius:20px;
      }
      .viewDetailInner .note h3{
        color:white;
        font-size:12px;
      }
      .viewDetailInner .note p{
        opacity:0.8;
        font-size:12px;
      }
      .playlistNameViewDetail{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        overflow:hidden;
        width:100%;
        
      }
@keyframes refreshRound {
  0%{
    transform: rotate(0deg);
  }
  0%{
    transform: rotate(359deg);
  }
}
  .buttonDiv{
    width:100%;
    justify-content: flex-end !important;
    display: flex;
    padding-top:24px;
  }
  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      width: 100%;
      margin-left: 0%;
    }
    .ant-select .ant-select-selector,
    .ant-select-selection,
    .ant-select-item {
      font-size: 17px !important;
    }
    .btn2 {
      padding: 24px !important;
    }
  }

  .verisonsUi{
  p{
      font-size: 12px;
  }
    .verisons{
      display: flex;
      align-items: center;
      margin:10px 0px;
      cursor:pointer;
          font-size: 12px;
      .checkVersion{
        border: 2px solid #ffffff59;
        aspect-ratio: 1;
        width: ;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-right: 10px;
        color: #ffff;
        width: 18px;
    height: 18px;
      }
      span{
        color: #ffffff5e;
        margin-left:10px;
      }
    }
  }
  .checkBoxSaveVersion{
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    .note{
      width:100%;
      margin-bottom:10px;
      font-size:12px;
      display:flex;
    }
    .checkVersion{
      border: 2px solid #ffffff59;
      aspect-ratio: 1;
      width: ;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-right: 10px;
      color: #ffff;
      width: 18px;
    height: 18px;
    }
    span{
      color: #ffffff5e;
      margin-left:10px;
    }
  }
  }
  .filterSelect{

    .playlistInputText .playlistInputTextSearch{
      outline: none;
      background: transparent;
      background-color: transparent;
      border: none;
    }
  }
 
`;
