import React, { useEffect } from "react";
import ReactAudioPlayer from "react-h5-audio-player";
import { usePlaylistContext } from "../../context/PlaylistContext";

function AudioPlayerTool({ showFilledVolume}) {
  const musicRef = React.useRef();
  const { state, dispatch } = usePlaylistContext();



  return (
    <ReactAudioPlayer
      className="audio-player"
      ref={musicRef}
      // autoPlay
      showSkipControls={false}
      showJumpControls={true}
      showFilledProgress={true}
      showFilledVolume={true}

      // loop={loopToggle}
      src={state.previewSong.url}
      customIcons={{
        forward: (
          <svg
            width="30"
            height="26"
            viewBox="0 0 30 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.75 5.70866L23.75 20.292"
              stroke="#F3F4F6"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.25 4.66634L18.75 12.9997L6.25 21.333L6.25 4.66634Z"
              fill="#F3F4F6"
              stroke="#F3F4F6"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
        rewind: (
          <svg
            width="30"
            height="26"
            viewBox="0 0 30 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.25 20.2913V5.70801"
              stroke="#F3F4F6"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.75 21.3337L11.25 13.0003L23.75 4.66699V21.3337Z"
              fill="#F3F4F6"
              stroke="#F3F4F6"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )
      }}
      layout={"stacked-reverse"}
      onPause={() => {
        dispatch({
          type: "UPDATE_SONGS_SEARCH_LIST",
          payload: state.previewSong.id,
        });
      }}

      onPlay={() => {
        dispatch({
          type: "UPDATE_SONGS_SEARCH_LIST",
          payload: state.previewSong.id,
        });
      }}
    />
  )
}

export default AudioPlayerTool