import { Modal } from "antd";
import styled from "styled-components";

export const AddToPlayListModalStyle = styled(Modal)`

  .heading {
    font-size: 25px;
    color: ${(props) => props.theme.colors.primary};
    margin-left: 3%;
    padding-bottom: 10px;
    font-weight: 700;
  }

  .description {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primary};
  }
  .ant-modal-mask {
    backdrop-filter: blur(5px) !important;
  }
  .ant-modal-content {
    backdrop-filter: blur(5px);
    color: ${(props) => props.theme.colors.primary};
    width: 1200px;
    border-radius: 12px;
    margin-left: -60%;
  }
  .ant-modal-content{
    background: #242429 !important;
    border-radius: 10px;
    border:none; 
  }
  .justifywidth {
    width: 90%;
  }

  .PrivacyPolicyTopHeading {
    font-weight: 700 !important;
    font-size: 25px !important;
  }
.PrivacyPolicyParam{
    font-size: 12.53px;
    }
  @media only screen and (max-width: 600px) {
    .heading {
      font-size: 26px;
      margin: 0px;
    }

    .PrivacyPolicyTopHeading {
      font-weight: 700 !important;
      font-size:  !important;
    }

    .PrivacyPolicyHeading {
      font-weight: 700 !important;
      font-size: 22px !important;
    }

    .PrivacyPolicyParam {
      font-size: 14px !important;
      font-weight: 500 !important;
    }

    .ant-modal-body {
      padding: 14px !important;
    }

    .justifywidth {
      width: 100%;
    }

    .ant-modal-content {
      width: 90%;
      margin-left: 5%;
    }
  }

  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .ant-select-selection-item {
    font-size: 15px !important;
  }
  .ant-select-selector {
    height: 49px !important;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .date {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }
  h3 {
    color: #788c9b !important;
  }

  .footer {
    display: flex;
    gap: 5px;
    align-self: flex-end;
  }
`;
