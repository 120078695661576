import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return {
    cardNumber: nextValue.trim(),
    cardType: issuer,
  };
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export const countriesList = [
  // {
  //     "name": "----",
  //     "value": null,
  //     "code": "---"
  // },
  {
    name: "Afghanistan",
    value: "Afghanistan",
    code: "AF",
  },
  {
    name: "land Islands",
    value: "land Islands",
    code: "AX",
  },
  {
    name: "Albania",
    value: "Albania",
    code: "AL",
  },
  {
    name: "Algeria",
    value: "Algeria",
    code: "DZ",
  },
  {
    name: "American Samoa",
    value: "American Samoa",
    code: "AS",
  },
  {
    name: "AndorrA",
    value: "AndorrA",
    code: "AD",
  },
  {
    name: "Angola",
    value: "Angola",
    code: "AO",
  },
  {
    name: "Anguilla",
    value: "Anguilla",
    code: "AI",
  },
  {
    name: "Antarctica",
    value: "Antarctica",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    value: "Antigua and Barbuda",
    code: "AG",
  },
  {
    name: "Argentina",
    value: "Argentina",
    code: "AR",
  },
  {
    name: "Armenia",
    value: "Armenia",
    code: "AM",
  },
  {
    name: "Aruba",
    value: "Aruba",
    code: "AW",
  },
  {
    name: "Australia",
    value: "Australia",
    code: "AU",
  },
  {
    name: "Austria",
    value: "Austria",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    value: "Azerbaijan",
    code: "AZ",
  },
  {
    name: "Bahamas",
    value: "Bahamas",
    code: "BS",
  },
  {
    name: "Bahrain",
    value: "Bahrain",
    code: "BH",
  },
  {
    name: "Bangladesh",
    value: "Bangladesh",
    code: "BD",
  },
  {
    name: "Barbados",
    value: "Barbados",
    code: "BB",
  },
  {
    name: "Belarus",
    value: "Belarus",
    code: "BY",
  },
  {
    name: "Belgium",
    value: "Belgium",
    code: "BE",
  },
  {
    name: "Belize",
    value: "Belize",
    code: "BZ",
  },
  {
    name: "Benin",
    value: "Benin",
    code: "BJ",
  },
  {
    name: "Bermuda",
    value: "Bermuda",
    code: "BM",
  },
  {
    name: "Bhutan",
    value: "Bhutan",
    code: "BT",
  },
  {
    name: "Bolivia",
    value: "Bolivia",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    name: "Botswana",
    value: "Botswana",
    code: "BW",
  },
  {
    name: "Bouvet Island",
    value: "Bouvet Island",
    code: "BV",
  },
  {
    name: "Brazil",
    value: "Brazil",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    value: "Brunei Darussalam",
    code: "BN",
  },
  {
    name: "Bulgaria",
    value: "Bulgaria",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    value: "Burkina Faso",
    code: "BF",
  },
  {
    name: "Burundi",
    value: "Burundi",
    code: "BI",
  },
  {
    name: "Cambodia",
    value: "Cambodia",
    code: "KH",
  },
  {
    name: "Cameroon",
    value: "Cameroon",
    code: "CM",
  },
  {
    name: "Canada",
    value: "Canada",
    code: "CA",
  },
  {
    name: "Cape Verde",
    value: "Cape Verde",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    value: "Cayman Islands",
    code: "KY",
  },
  {
    name: "Central African Republic",
    value: "Central African Republic",
    code: "CF",
  },
  {
    name: "Chad",
    value: "Chad",
    code: "TD",
  },
  {
    name: "Chile",
    value: "Chile",
    code: "CL",
  },
  {
    name: "China",
    value: "China",
    code: "CN",
  },
  {
    name: "Christmas Island",
    value: "Christmas Island",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
    code: "CC",
  },
  {
    name: "Colombia",
    value: "Colombia",
    code: "CO",
  },
  {
    name: "Comoros",
    value: "Comoros",
    code: "KM",
  },
  {
    name: "Congo",
    value: "Congo",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the",
    value: "Congo, The Democratic Republic of the",
    code: "CD",
  },
  {
    name: "Cook Islands",
    value: "Cook Islands",
    code: "CK",
  },
  {
    name: "Costa Rica",
    value: "Costa Rica",
    code: "CR",
  },
  {
    name: "Cote D Ivoire",
    value: "Cote D Ivoire",
    code: "CI",
  },
  {
    name: "Croatia",
    value: "Croatia",
    code: "HR",
  },
  {
    name: "Cuba",
    value: "Cuba",
    code: "CU",
  },
  {
    name: "Cyprus",
    value: "Cyprus",
    code: "CY",
  },
  {
    name: "Czech Republic",
    value: "Czech Republic",
    code: "CZ",
  },
  {
    name: "Denmark",
    value: "Denmark",
    code: "DK",
  },
  {
    name: "Djibouti",
    value: "Djibouti",
    code: "DJ",
  },
  {
    name: "Dominica",
    value: "Dominica",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    value: "Dominican Republic",
    code: "DO",
  },
  {
    name: "Ecuador",
    value: "Ecuador",
    code: "EC",
  },
  {
    name: "Egypt",
    value: "Egypt",
    code: "EG",
  },
  {
    name: "El Salvador",
    value: "El Salvador",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    value: "Equatorial Guinea",
    code: "GQ",
  },
  {
    name: "Eritrea",
    value: "Eritrea",
    code: "ER",
  },
  {
    name: "Estonia",
    value: "Estonia",
    code: "EE",
  },
  {
    name: "Ethiopia",
    value: "Ethiopia",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    value: "Faroe Islands",
    code: "FO",
  },
  {
    name: "Fiji",
    value: "Fiji",
    code: "FJ",
  },
  {
    name: "Finland",
    value: "Finland",
    code: "FI",
  },
  {
    name: "France",
    value: "France",
    code: "FR",
  },
  {
    name: "French Guiana",
    value: "French Guiana",
    code: "GF",
  },
  {
    name: "French Polynesia",
    value: "French Polynesia",
    code: "PF",
  },
  {
    name: "French Southern Territories",
    value: "French Southern Territories",
    code: "TF",
  },
  {
    name: "Gabon",
    value: "Gabon",
    code: "GA",
  },
  {
    name: "Gambia",
    value: "Gambia",
    code: "GM",
  },
  {
    name: "Georgia",
    value: "Georgia",
    code: "GE",
  },
  {
    name: "Germany",
    value: "Germany",
    code: "DE",
  },
  {
    name: "Ghana",
    value: "Ghana",
    code: "GH",
  },
  {
    name: "Gibraltar",
    value: "Gibraltar",
    code: "GI",
  },
  {
    name: "Greece",
    value: "Greece",
    code: "GR",
  },
  {
    name: "Greenland",
    value: "Greenland",
    code: "GL",
  },
  {
    name: "Grenada",
    value: "Grenada",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    value: "Guadeloupe",
    code: "GP",
  },
  {
    name: "Guam",
    value: "Guam",
    code: "GU",
  },
  {
    name: "Guatemala",
    value: "Guatemala",
    code: "GT",
  },
  {
    name: "Guernsey",
    value: "Guernsey",
    code: "GG",
  },
  {
    name: "Guinea",
    value: "Guinea",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    value: "Guinea-Bissau",
    code: "GW",
  },
  {
    name: "Guyana",
    value: "Guyana",
    code: "GY",
  },
  {
    name: "Haiti",
    value: "Haiti",
    code: "HT",
  },
  {
    name: "Heard Island and Mcdonald Islands",
    value: "Heard Island and Mcdonald Islands",
    code: "HM",
  },
  {
    name: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)",
    code: "VA",
  },
  {
    name: "Honduras",
    value: "Honduras",
    code: "HN",
  },
  {
    name: "Hong Kong",
    value: "Hong Kong",
    code: "HK",
  },
  {
    name: "Hungary",
    value: "Hungary",
    code: "HU",
  },
  {
    name: "Iceland",
    value: "Iceland",
    code: "IS",
  },
  {
    name: "India",
    value: "India",
    code: "IN",
  },
  {
    name: "Indonesia",
    value: "Indonesia",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic Of",
    value: "Iran, Islamic Republic Of",
    code: "IR",
  },
  {
    name: "Iraq",
    value: "Iraq",
    code: "IQ",
  },
  {
    name: "Ireland",
    value: "Ireland",
    code: "IE",
  },
  {
    name: "Isle of Man",
    value: "Isle of Man",
    code: "IM",
  },
  {
    name: "Israel",
    value: "Israel",
    code: "IL",
  },
  {
    name: "Italy",
    value: "Italy",
    code: "IT",
  },
  {
    name: "Jamaica",
    value: "Jamaica",
    code: "JM",
  },
  {
    name: "Japan",
    value: "Japan",
    code: "JP",
  },
  {
    name: "Jersey",
    value: "Jersey",
    code: "JE",
  },
  {
    name: "Jordan",
    value: "Jordan",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    value: "Kazakhstan",
    code: "KZ",
  },
  {
    name: "Kenya",
    value: "Kenya",
    code: "KE",
  },
  {
    name: "Kiribati",
    value: "Kiribati",
    code: "KI",
  },
  {
    name: "Korea, Democratic People'S Republic of",
    value: "Korea, Democratic People'S Republic of",
    code: "KP",
  },
  {
    name: "Korea, Republic of",
    value: "Korea, Republic of",
    code: "KR",
  },
  {
    name: "Kuwait",
    value: "Kuwait",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    value: "Kyrgyzstan",
    code: "KG",
  },
  {
    name: "Lao People'S Democratic Republic",
    value: "Lao People'S Democratic Republic",
    code: "LA",
  },
  {
    name: "Latvia",
    value: "Latvia",
    code: "LV",
  },
  {
    name: "Lebanon",
    value: "Lebanon",
    code: "LB",
  },
  {
    name: "Lesotho",
    value: "Lesotho",
    code: "LS",
  },
  {
    name: "Liberia",
    value: "Liberia",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    value: "Libyan Arab Jamahiriya",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    value: "Liechtenstein",
    code: "LI",
  },
  {
    name: "Lithuania",
    value: "Lithuania",
    code: "LT",
  },
  {
    name: "Luxembourg",
    value: "Luxembourg",
    code: "LU",
  },
  {
    name: "Macao",
    value: "Macao",
    code: "MO",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    value: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
  },
  {
    name: "Madagascar",
    value: "Madagascar",
    code: "MG",
  },
  {
    name: "Malawi",
    value: "Malawi",
    code: "MW",
  },
  {
    name: "Malaysia",
    value: "Malaysia",
    code: "MY",
  },
  {
    name: "Maldives",
    value: "Maldives",
    code: "MV",
  },
  {
    name: "Mali",
    value: "Mali",
    code: "ML",
  },
  {
    name: "Malta",
    value: "Malta",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    value: "Marshall Islands",
    code: "MH",
  },
  {
    name: "Martinique",
    value: "Martinique",
    code: "MQ",
  },
  {
    name: "Mauritania",
    value: "Mauritania",
    code: "MR",
  },
  {
    name: "Mauritius",
    value: "Mauritius",
    code: "MU",
  },
  {
    name: "Mayotte",
    value: "Mayotte",
    code: "YT",
  },
  {
    name: "Mexico",
    value: "Mexico",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of",
    value: "Micronesia, Federated States of",
    code: "FM",
  },
  {
    name: "Moldova, Republic of",
    value: "Moldova, Republic of",
    code: "MD",
  },
  {
    name: "Monaco",
    value: "Monaco",
    code: "MC",
  },
  {
    name: "Mongolia",
    value: "Mongolia",
    code: "MN",
  },
  {
    name: "Montenegro",
    value: "Montenegro",
    code: "ME",
  },
  {
    name: "Montserrat",
    value: "Montserrat",
    code: "MS",
  },
  {
    name: "Morocco",
    value: "Morocco",
    code: "MA",
  },
  {
    name: "Mozambique",
    value: "Mozambique",
    code: "MZ",
  },
  {
    name: "Myanmar",
    value: "Myanmar",
    code: "MM",
  },
  {
    name: "Namibia",
    value: "Namibia",
    code: "NA",
  },
  {
    name: "Nauru",
    value: "Nauru",
    code: "NR",
  },
  {
    name: "Nepal",
    value: "Nepal",
    code: "NP",
  },
  {
    name: "Netherlands",
    value: "Netherlands",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    value: "Netherlands Antilles",
    code: "AN",
  },
  {
    name: "New Caledonia",
    value: "New Caledonia",
    code: "NC",
  },
  {
    name: "New Zealand",
    value: "New Zealand",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    value: "Nicaragua",
    code: "NI",
  },
  {
    name: "Niger",
    value: "Niger",
    code: "NE",
  },
  {
    name: "Nigeria",
    value: "Nigeria",
    code: "NG",
  },
  {
    name: "Niue",
    value: "Niue",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    value: "Norfolk Island",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
    code: "MP",
  },
  {
    name: "Norway",
    value: "Norway",
    code: "NO",
  },
  {
    name: "Oman",
    value: "Oman",
    code: "OM",
  },
  {
    name: "Pakistan",
    value: "Pakistan",
    code: "PK",
  },
  {
    name: "Palau",
    value: "Palau",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    value: "Palestinian Territory, Occupied",
    code: "PS",
  },
  {
    name: "Panama",
    value: "Panama",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    value: "Papua New Guinea",
    code: "PG",
  },
  {
    name: "Paraguay",
    value: "Paraguay",
    code: "PY",
  },
  {
    name: "Peru",
    value: "Peru",
    code: "PE",
  },
  {
    name: "Philippines",
    value: "Philippines",
    code: "PH",
  },
  {
    name: "Pitcairn",
    value: "Pitcairn",
    code: "PN",
  },
  {
    name: "Poland",
    value: "Poland",
    code: "PL",
  },
  {
    name: "Portugal",
    value: "Portugal",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    value: "Puerto Rico",
    code: "PR",
  },
  {
    name: "Qatar",
    value: "Qatar",
    code: "QA",
  },
  {
    name: "Reunion",
    value: "Reunion",
    code: "RE",
  },
  {
    name: "Romania",
    value: "Romania",
    code: "RO",
  },
  {
    name: "Russian Federation",
    value: "Russian Federation",
    code: "RU",
  },
  {
    name: "RWANDA",
    value: "RWANDA",
    code: "RW",
  },
  {
    name: "Saint Helena",
    value: "Saint Helena",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    value: "Saint Lucia",
    code: "LC",
  },
  {
    name: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
    code: "VC",
  },
  {
    name: "Samoa",
    value: "Samoa",
    code: "WS",
  },
  {
    name: "San Marino",
    value: "San Marino",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    value: "Saudi Arabia",
    code: "SA",
  },
  {
    name: "Senegal",
    value: "Senegal",
    code: "SN",
  },
  {
    name: "Serbia",
    value: "Serbia",
    code: "RS",
  },
  {
    name: "Seychelles",
    value: "Seychelles",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    value: "Sierra Leone",
    code: "SL",
  },
  {
    name: "Singapore",
    value: "Singapore",
    code: "SG",
  },
  {
    name: "Slovakia",
    value: "Slovakia",
    code: "SK",
  },
  {
    name: "Slovenia",
    value: "Slovenia",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    value: "Solomon Islands",
    code: "SB",
  },
  {
    name: "Somalia",
    value: "Somalia",
    code: "SO",
  },
  {
    name: "South Africa",
    value: "South Africa",
    code: "ZA",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
    code: "GS",
  },
  {
    name: "Spain",
    value: "Spain",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    value: "Sri Lanka",
    code: "LK",
  },
  {
    name: "Sudan",
    value: "Sudan",
    code: "SD",
  },
  {
    name: "Suriname",
    value: "Suriname",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen",
    code: "SJ",
  },
  {
    name: "Swaziland",
    value: "Swaziland",
    code: "SZ",
  },
  {
    name: "Sweden",
    value: "Sweden",
    code: "SE",
  },
  {
    name: "Switzerland",
    value: "Switzerland",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    value: "Syrian Arab Republic",
    code: "SY",
  },
  {
    name: "Taiwan, Province of China",
    value: "Taiwan, Province of China",
    code: "TW",
  },
  {
    name: "Tajikistan",
    value: "Tajikistan",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of",
    code: "TZ",
  },
  {
    name: "Thailand",
    value: "Thailand",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    value: "Timor-Leste",
    code: "TL",
  },
  {
    name: "Togo",
    value: "Togo",
    code: "TG",
  },
  {
    name: "Tokelau",
    value: "Tokelau",
    code: "TK",
  },
  {
    name: "Tonga",
    value: "Tonga",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    value: "Trinidad and Tobago",
    code: "TT",
  },
  {
    name: "Tunisia",
    value: "Tunisia",
    code: "TN",
  },
  {
    name: "Turkey",
    value: "Turkey",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    value: "Turkmenistan",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
    code: "TC",
  },
  {
    name: "Tuvalu",
    value: "Tuvalu",
    code: "TV",
  },
  {
    name: "Uganda",
    value: "Uganda",
    code: "UG",
  },
  {
    name: "Ukraine",
    value: "Ukraine",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    value: "United Arab Emirates",
    code: "AE",
  },
  {
    name: "United Kingdom",
    value: "United Kingdom",
    code: "GB",
  },
  {
    name: "United States",
    value: "United States",
    code: "US",
  },
  {
    name: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
    code: "UM",
  },
  {
    name: "Uruguay",
    value: "Uruguay",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    value: "Uzbekistan",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    value: "Vanuatu",
    code: "VU",
  },
  {
    name: "Venezuela",
    value: "Venezuela",
    code: "VE",
  },
  {
    name: "Viet Nam",
    value: "Viet Nam",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    value: "Virgin Islands, British",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    value: "Virgin Islands, U.S.",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    value: "Wallis and Futuna",
    code: "WF",
  },
  {
    name: "Western Sahara",
    value: "Western Sahara",
    code: "EH",
  },
  {
    name: "Yemen",
    value: "Yemen",
    code: "YE",
  },
  {
    name: "Zambia",
    value: "Zambia",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    value: "Zimbabwe",
    code: "ZW",
  },
];

export const currencies = [
  "ALL",
  "AFN",
  "ARS",
  "AWG",
  "AUD",
  "AZN",
  "BSD",
  "BBD",
  "BDT",
  "BYR",
  "BZD",
  "BMD",
  "BOB",
  "BAM",
  "BWP",
  "BGN",
  "BRL",
  "BND",
  "KHR",
  "CAD",
  "KYD",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "HRK",
  "CUP",
  "CZK",
  "DKK",
  "DOP",
  "XCD",
  "EGP",
  "SVC",
  "EEK",
  "EUR",
  "FKP",
  "FJD",
  "GHC",
  "GIP",
  "GTQ",
  "GGP",
  "GYD",
  "HNL",
  "HKD",
  "HUF",
  "ISK",
  "INR",
  "IDR",
  "IRR",
  "IMP",
  "ILS",
  "JMD",
  "JPY",
  "JEP",
  "KZT",
  "KPW",
  "KRW",
  "KGS",
  "LAK",
  "LVL",
  "LBP",
  "LRD",
  "LTL",
  "MKD",
  "MYR",
  "MUR",
  "MXN",
  "MNT",
  "MZN",
  "NAD",
  "NPR",
  "ANG",
  "NZD",
  "NIO",
  "NGN",
  "NOK",
  "OMR",
  "PKR",
  "PAB",
  "PYG",
  "PEN",
  "PHP",
  "PLN",
  "QAR",
  "RON",
  "RUB",
  "SHP",
  "SAR",
  "RSD",
  "SCR",
  "SGD",
  "SBD",
  "SOS",
  "ZAR",
  "LKR",
  "SEK",
  "CHF",
  "SRD",
  "SYP",
  "TWD",
  "THB",
  "TTD",
  "TRY",
  "TRL",
  "TVD",
  "UAH",
  "GBP",
  "USD",
  "UYU",
  "UZS",
  "VEF",
  "VND",
  "YER",
  "ZWD",
];

export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export function rearrangeArrayWithLock(arr) {
  // Filter the array to get only the objects with lock: true
  const lockedObjects = arr.filter((obj) => obj.lockPosition);
  // Sort the filtered objects based on their value
  //lockedObjects.sort((a, b) => a.value - b.value);

  // Remove locked objects from the array
  arr = arr.filter((obj) => !obj.lockPosition);

  // Add sorted locked objects back to the array at the specified index
  lockedObjects
    // .sort((a, b) => Number(b?.lockPositionNumber) - Number(a?.lockPositionNumber))
    .sort((a, b) => Number(a?.lockPositionNumber) - Number(b?.lockPositionNumber))
    .forEach((obj) => {
      arr.splice(Number(obj.lockPositionNumber), 0, obj);
    });

  return arr;
}

//   const inputArray = [
//     { value: 1, lock: false },
//     { value: 2, lock: false },
//     { value: 5, lock: true },
//     { value: 3, lock: false },
//     { value: 4, lock: true },
//     { value: 6, lock: false },
//   ];

//   const rearrangedArray = rearrangeArrayWithLock(inputArray);
export const dateFormate = (date) =>{
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(date));
  return formattedDate;
}




