import styled from "styled-components";

export const SectionWrapperStyle = styled.section`
  color: #fff;
  .headingSlider{
    padding: 0px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    margin-top: 21px;
  }
  .heading {
    font-size: 17px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 600px;
    color: ${(props) => props.theme.colors.primary};
  }
  .artistNumber{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4.88199px 7.67169px;
    gap: 6.97px; 
    width: 24px;
    height: 24px;  
    background: #FFFFFF;
    border-radius: 111.588px;
   flex: none;
    order: 1;
    flex-grow: 0;
     
 
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    text-align: center;
    letter-spacing: -0.03em;
    

    color: #1D1D1D;
    margin-left: 8px;
    

    

  }
  .subheading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
    color: #ababab;
  }
`;
