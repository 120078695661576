import { Modal } from "antd";
import "./CustomSubscriptionModal.css";

type Props = {
  handleOkSubscriptionSuccess: any;
  handleCancelSubscriptionSuccess: any;
  showModal: any;
  isModalOpen: any;
};

const SuccessSubscription = ({
  handleOkSubscriptionSuccess,
  handleCancelSubscriptionSuccess,
  showModal,
  isModalOpen
}: Props) => {
  return (
    <Modal
      className="SubscriptionModal"
      title={false}
      footer={false}
      open={isModalOpen}
      onOk={handleOkSubscriptionSuccess}
      onCancel={handleCancelSubscriptionSuccess}
    >
      <div className="innerSubscriptionModal">
        <img alt="success-img" className="SuccessSubsImage" src={'./static/images/undraw_completing_re_i7ap.png'} />
        <h3>Song has been successfully added!</h3>
        <p>Enjoy your song whenever you want.</p>
        <button className="SubscribeBtn">Go to playlist</button>
      </div>
    </Modal>
  );
};
export default SuccessSubscription;
