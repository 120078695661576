import styled from "styled-components";

const ShareDetailPageStyle = styled.div`
  background-image: url("./static/Rectangle3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
      height: 100vh;
    width: 100vw;
`
export {ShareDetailPageStyle}