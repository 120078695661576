import React, { useEffect, useState } from "react";
import { Modal } from "antd";

type Props = {
    handleOk: any;
    handleCancel: any;
    showModal: any;
    isModalOpen: any;
    heading: string;
    headingText: string;
    button1: string;
    button2: string;
};

const DeleteAndFixModal = ({
    handleOk,
    handleCancel,
    isModalOpen,
    heading,
    headingText,
    button1,
    button2
}: Props) => {
    const [dontShow, setDontShow] = useState(false)
    useEffect(() => {
        let show = localStorage.getItem('dontShow')
        if (show === 'true') {
            setDontShow(true)
        } else {
            setDontShow(false)
        }
    }, [])
    return (
        <Modal
            className="SubscriptionModal"
            title={false}
            footer={false}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >

            <div className="innerSubscriptionModal">
                <img alt="cancelImage" className="cancelSubsImage" src={'./../static/images/undraw_warning_re_eoyh.png'} />
                <h3>{heading}</h3>
                <p>{headingText}</p>
                <button className="SubscribeBtn" onClick={()=>{
                    handleOk()
                }}>{button1}</button>
                <button className="loginBtn" onClick={handleCancel}>{button2}</button>
            </div>
        </Modal>
    );
};
export default DeleteAndFixModal;