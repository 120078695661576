import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "./CustomSubscriptionModal.css";

type Props = {
  handleOk: any;
  handleCancel: any;
  showModal: any;
  isModalOpen: any;
  heading: string;
  headingText: string;
  button1: string;
  button2: string;
  positionWarning?: string;
  rememberCheckbox?: boolean
};

const DeleteModal = ({
  handleOk,
  handleCancel,
  isModalOpen,
  heading,
  headingText,
  button1,
  button2,
  positionWarning,
  rememberCheckbox
}: Props) => {
  const [dontShow, setDontShow] = useState(false)
  useEffect(() => {
    let show = localStorage.getItem('dontShow')
    if (show === 'true') {
      setDontShow(true)
    } else {
      setDontShow(false)
    }
  }, [])
  return (
    <Modal
      className="SubscriptionModal"
      title={false}
      footer={false}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >

      <div className="innerSubscriptionModal">
        <img alt="cancelImage" className="cancelSubsImage" src={'./../static/images/undraw_warning_re_eoyh.png'} />
        <h3>{heading}</h3>
        <p>{headingText}</p>
        <p className="positionWarning">{positionWarning}</p>
        <button className="SubscribeBtn" onClick={(ev) => {
          if (rememberCheckbox) {
            if (dontShow) {
              localStorage.setItem('dontShow', 'true')
            } else {
              localStorage.setItem('dontShow', 'false')
            }
          }
          handleOk(ev)
        }}>{button1}</button>
        <button className="loginBtn" onClick={handleCancel}>{button2}</button>
        {rememberCheckbox ?
          <label className="dontShow"><input type="checkbox" checked={dontShow} onChange={(ev) => {
            setDontShow(ev.target.checked)
          }} />&nbsp;Don’t show this again</label>
          : null}
      </div>
    </Modal>
  );
};
export default DeleteModal;