import { About, Condition, PrivacyPolicy } from "../../pages";
import { AddToPlayListModalStyle } from "./AddToPlayListModal.style";

interface AddToPlayListModalProps {
  isModalVisible?: boolean;
  setIsModalVisible?: (_: boolean) => void;
  setName?: any;
}

const Policies = (props: AddToPlayListModalProps) => {
  const { isModalVisible, setIsModalVisible, setName } = props;

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = (e: any) => {
    e.stopPropagation();
    setIsModalVisible(false);
  };

  return (
    <>
      <AddToPlayListModalStyle
        visible={isModalVisible}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
        className="policieModal"
      >
        {setName === "condition" ? (
          <div>
            <h1 className="heading">Terms & Conditions</h1>
            <Condition />
          </div>
        ) : setName === "policy" ? (
          <div>
            <h1 className="heading">Privacy Policy</h1>
            <PrivacyPolicy />
          </div>
        ) : setName === "about" ? (
          <div>
            <h1 className="heading">About</h1>
            <About />
          </div>
        ) : (
          ""
        )}
      </AddToPlayListModalStyle>
    </>
  );
};

export default Policies;
