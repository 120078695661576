import { ContactFooterStyle, LogoWithHeadingStyle } from './LogoWithHeading.style';

interface LogoWithHeadingProps {
	heading: string;
	description: string;
}


const LogoWithHeading = (props: LogoWithHeadingProps) => {
	return (
		<LogoWithHeadingStyle>
			<img src="logo.png" className="logo" alt="logo" />
			<div>
				<h3 className="signin-heading">{props.heading}</h3>
				<p>{props.description}</p>
			</div>
		</LogoWithHeadingStyle>
	);
};

const ContactFooter = () =>{
    return(
		<ContactFooterStyle>
			{/* About Us | Contact Us | Privacy Policy | © Songplace */}
		</ContactFooterStyle>
	)
}

export { ContactFooter, LogoWithHeading };
