import styled from "styled-components";

export const SectionWrapperStyle = styled.section`
  color: #fff;
  .heading {
    font-size: 27px;
    font-weight: 700;
    margin-top: 56px;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 600px;
    color: ${(props) => props.theme.colors.primary};
  }

  .subheading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
    color: #ababab;
  }
`;
