import { createContext, useContext, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { usePlaylistContext } from "../context/PlaylistContext";
import { darkTheme, lightTheme } from "../theme/theme.style.d";
interface ThemeContextType {
  theme: string;
  toggleTheme: () => void;
}
const defaultState = {
  theme: "dark",
  toggleTheme: () => undefined,
};

export const ThemeContext = createContext<ThemeContextType>(defaultState);
export const useThemeContext = () => useContext(ThemeContext);

interface GlobalThemeProviderProps {
  children: any;
}

const GlobalThemeProvider = (props: GlobalThemeProviderProps) => {
  const { state } = usePlaylistContext();
  const [theme, setTheme] = useState("dark");
  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  useEffect(() => {
    setTheme(state?.isWholeTheme === false ? "dark" : "light");
  }, [state]);

  // const themes = state?.isTheme === false ? "dark" : "light";

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
export { GlobalThemeProvider };
