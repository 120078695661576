import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { SearchBarStyle } from "./SearchBar.style";

const SearchBar = () => {

  const { dispatch } = usePlaylistContext();

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleInputClick = (value) => {
    if (value.key !== "Enter") return;

    dispatch({
      type: "SET_SEARCHED_SONG",
      payload: search,
    });
    let playlist = window.location.pathname.split("/");
    let bulk = localStorage.getItem('bulk')
    if (playlist[1] === "playlist" && bulk) {
      navigate("/search?playlist=" + playlist[2]);
    } else if (playlist[1] === "playlist" && !bulk) {
      navigate(`/search?id=${playlist[2]}`);
    } else {
      navigate("/search");
    }
    setSearch('')
  };
  return (
    <SearchBarStyle>
      <Input
        className="searchBar"
        type="text"
        placeholder="Artist, songs or podcast"
        value={search}
        onChange={handleChange}
        onKeyDown={handleInputClick}
        prefix={<SearchOutlined />}
      />
    </SearchBarStyle>
  );
};

export default SearchBar;
