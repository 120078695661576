import axios from "axios";
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
  useState
} from "react";
import {
  SPOTIFY_CODE,
  SPOTIFY_REFRESH_TOKEN,
  SPOTIFY_TOKEN,
  SPOTIFY_USER_INFO
} from "../constants/storage";
import {
  addItemToStorage,
  Auth0Logout,
  deleteItemFromStorage,
  getItemFromStorage,
  getRefreshedToken,
  isEmptyOrNil
} from "../utils";

type LoginAction =
  | {
    type: "INITIALIZE_LOGIN";
    payload: any;
  }
  | {
    type: "SET_LOGIN";
    payload: boolean;
  }
  | {
    type: "ADD_APP_CODE";
    payload: string;
  }
  | {
    type: "ADD_APP_TOKEN";
    payload: {
      token: string;
      refreshToken: string;
    };
  }
  | {
    type: "SET_USER_ID";
    payload: string;
  }
  | {
    type: "ADD_REFRESH_TOKEN";
    payload: string;
  }
  | {
    type: "ADD_TOKEN";
    payload: string;
  };

export type LoginContextType = {
  state: any;
  dispatch: Dispatch<LoginAction>;
};

const initialContent: any = {
  isLoggedIn: false,
  userId: "",
  username: "",
  password: "",
  email: "",
  code: "",
  token: getItemFromStorage(SPOTIFY_TOKEN) || "",
  refreshToken: getItemFromStorage(SPOTIFY_REFRESH_TOKEN) || "",
  isRememberPassword: false
};

export const LoginContext = createContext<LoginContextType>({
  state: {
    ...initialContent
  },
  dispatch: () => undefined
});
export const useLoginContext = () => useContext(LoginContext);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "INITIALIZE_LOGIN":
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
        username: action.payload.username
      };

    case "SET_LOGIN":
      return {
        ...state,
        isLoggedIn: action.payload
      };

    case "ADD_APP_CODE":
      return {
        ...state,
        code: action.payload
      };

    case "ADD_APP_TOKEN":
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken
      };

    case "SET_USER_ID":
      return {
        ...state,
        userId: action.payload
      };

    case "ADD_TOKEN":
      return {
        ...state,
        token: action.payload
      };

    case "ADD_REFRESH_TOKEN":
      return {
        ...state,
        refreshToken: action.payload
      };
    default:
      return state;
  }
};

export const LoginContextProvider = (props: any) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialContent);

  const token = getItemFromStorage(SPOTIFY_TOKEN);
  const refreshToken = getItemFromStorage(SPOTIFY_REFRESH_TOKEN);
  const code = getItemFromStorage(SPOTIFY_CODE);
  // const [expired, setExpired] = useState(false)
  useEffect(() => {
    if (isEmptyOrNil(token) || isEmptyOrNil(code)) return;
    // console.log('responseeeresponseeeresponseee')

    dispatch({
      type: "ADD_APP_TOKEN",
      payload: {
        token: token,
        refreshToken: refreshToken
      }
    });

    dispatch({
      type: "ADD_APP_CODE",
      payload: code
    });

    dispatch({
      type: "SET_LOGIN",
      payload: true
    });

    axios(`https://api.spotify.com/v1/me`, {
      method: "GET",
      headers: { Authorization: "Bearer " + token }
    })
      .then(resp => {
        dispatch({
          type: "SET_USER_ID",
          payload: resp.data.id
        });
      })
      .catch(async error => {
        // setExpired(true)
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken).then(response => {
            addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);
            dispatch({
              type: "ADD_TOKEN",
              payload: response?.data?.access_token
            });
          }).catch(() => {

          });
        }
      });
  }, [token, refreshToken, code]);
  const handleLogout = () => {
    deleteItemFromStorage(SPOTIFY_TOKEN);
    deleteItemFromStorage(SPOTIFY_CODE);
    deleteItemFromStorage(SPOTIFY_USER_INFO);
    localStorage.removeItem("userInfo");
    localStorage.removeItem("spotifyUser");
    Auth0Logout();

    dispatch({
      type: "SET_LOGIN",
      payload: false,
    });
  };

  return (
    <LoginContext.Provider value={{ state, dispatch }}>

        {children} 

    </LoginContext.Provider>
  );
};
