import { Helmet } from "react-helmet";
import image from './mini-logo.png'

type PageHeaderProps = {
  title: string;
  description: string;
  image?:string;
}

const PageHeader = (props: PageHeaderProps) => {
  const { title, description } = props;

  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <link rel="apple-touch-icon" href={image} />

      <title>{title}</title>
      <meta name="twitter:title" content="Songplace - The best platform to manage your Spotify playlists" />
      <meta itemProp="name" content="Songplace" />
      <meta property="og:title" content="Songplace" />
      <meta name="description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta itemProp="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@songplace" />
      <meta name="twitter:image" content={image} />
      <meta itemProp="image" content={image} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content="https://www.songplace.io/" />
    </Helmet>
  )
}

export default PageHeader;
