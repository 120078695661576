import styled from "styled-components";

export const AudioPlayerStyle = styled.div`
  max-width: 100%;
  width: 100%;
  position:relative;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content:space-between;
  grid-gap: 20px;
  .images1 {
    height: 70px;
    margin-left: 30px;
  }

  .div3 {
    width: 20%;
  }
  .p2 {
    color: ${(props) => props.theme.colors.primary};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
font-size: 16px;
    max-width: 250px;
  }
  .pp12 {
    color: ${(props) => props.theme.colors.primary};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 19px;
    max-width: 250px;
    padding-top: 5px;
    margin-left: 5px;
  }
  .p4 {
    color: ${(props) => props.theme.colors.primary};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
        font-size: 14px;
  }
  .spanner1 {
    display:flex;
    align-items:center;
  }
  .spanner1 svg{
  }
  .spanner1 button{
    margin-right: 32px;
    background:transparent;
    border:none;
    cursor:pointer;
  }
  .audio-player {
    background: transparent;
    border: 0px;
    box-shadow: 0px;
    outline: none;

    width: 100%;

    .rhap_progress-section {
      div {
        color: ${(props) => props.theme.colors.primary};
      }
      @media only screen and (max-width: 600px) {
        .rhap_volume-container {
          display: none !important;
        }
      }
    }
    .rhap_container {
      width: 100%;
      padding: 0px 0px !important;
      box-shadow: 0 0 0px 0 rgb(0 0 0 / 0%) !important;
    }
    .rhap_controls-section {
      //   display: none;
      color: ${(props) => props.theme.colors.primary} !important;
    }

    .rhap_button-clear {
      color: ${(props) => props.theme.colors.primary};
      //   border-radius: 100px;
      //   background: white;
    }
  }

  .loop-button{
    background-color:transparent;
    border:none;
  }


  .SongSideBar{
    padding:25px;
    display: flex;
    left:0;

  }
  .SongSideBarImage img{
    width:62.49px;
    height:122px;
    border-radius:5px;
            border-radius: 0px;
  }
  .SongSideBar .songDetail{
    margin-left: 11px;
    height: 39px;
  }
  .SongSideBar .songDetail h2{
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.03em;
    text-align: left;
    color:white;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  .SongSideBar .songDetail p{
    font-family: DM Sans;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: -0.03em;
text-align: left;
color:#9CA3AF;
  }
  .SongSideBar .songDetail .rhap_progress-section{
    display:none;
  }
  .SongSideBar .songDetail .rhap_container{
    background: transparent;
    border: none;
    box-shadow: unset;
  }
  @media only screen and (max-width: 768px) {
    .SongSideBar{
    display: none;

    }
  .sidebarForPc {
    display: none;
  }
    .sidebarForMob {
      display: block;
    }
  }
  @media only screen and (max-width: 3000px) {
    .SongSideBar{
      position:fixed;
      bottom: 0;
      width: 230px;
      background: #242429;
      overflow: hidden;
      height: 80px;
      padding: 0px 25px;
    }
    .SongSideBarImage img{
      height:51px;
      width:51px;
              border-radius: 0px;
    }
    .SongSideBar .songDetail .rhap_container{
      padding: 0px;
    ransform: scale(0.9);
    }
    .SongSideBar .rhap_additional-controls, .SongSideBar .rhap_volume-container{
      display: none !important; 
    }
    .SongSideBar .audioPlayer{
      position:unset;
      transform:unset;
    }
    .SongSideBar  .rhap_progress-section{
      display:none;
    }
  }
`;

export const AudioPlayerStyle1 = styled.div`
z-index: 100;
    position: relative;
  .titler {
    color: ${(props) => props.theme.colors.primary};
  }
  .div {
    background: ${(props) => props.theme.background.secondary1};
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 10px;
    overflow:hidden;
    padding:0px;
  }
  .spanner {
    position: fixed;
    bottom: 146px;
    z-index: 101;
    cursor: pointer;
    left: 277px;
  }

  .AudioPlayerStyleClassName {
    display: flex;
    align-items: center;
    width: auto;
  }

  .AudioPlayerStyleClassNameDiv {
    display: block;
    // width: 20%;
    margin-left: 20px;
  }

  .AudioPlayerStyleClassNameImage {
    height: 121px;
    margin-left: 10px;
    width: auto;
  }

  .AudioPlayerStyleClassNameImageTwo {
    height: 121px;
    margin-left: 30px;
    width: auto;
  }

  

  .AudioPlayerpreviewSongStyleclassName {
    display: flex;
    align-items: center;
    justify-content: center
    
    
  }
  .AudioPlayerpreviewSongStyleclassName.active {
    padding: 24px;
          height: 80px;
  }

  .audioPlayer{
    max-width: 428px;
    width:100%;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%) scale(0.8);
  }

  .spanner {
    // transform: rotateZ(0deg);
  }

  .AudioPlayerStyleClassNameFlexbox {
    display: none !important;
    height: auto;
  }

  .rhap_container {
    width: 100%;
    box-shadow: 0 0 0px 0 rgb(0 0 0 / 0%) !important;
  }
  rhap_main-controls{
    padding: 18px 0px;
  }

  .bottomNav{
    display: none;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  .bottomBarNav{
    width:25%;
    padding:10px;
  }
  @media only screen and (max-width: 3000px) {
    .AudioPlayerStyleClassNameImage,.AudioPlayerStyleClassNameImageTwo{
            height: 51px;
      margin-left: 0px;

    }
    .AudioPlayerpreviewSongStyleclassName.active{
      padding: 14px 24px;
              height: 80px;
    } 
    .spanner{
      bottom: 59px;
      left: 212px;
    }
  }
  @media only screen and (max-width: 768px) {
  
  .bottomNav{
    display:flex;
  }
   
    .AudioPlayerStyleClassName {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;

      height: 48px;
      position: absolute;
      left: 0px;
      width: 48px;   
      
    }
    .audioPlayer{
      max-width: 100%;
    }
    .rhap_volume-container {
      display: none !important;
    }

   

    .AudioPlayerStyleClassNameImage {
      height: 80px;
      margin-left: 0px;
      width: 100%;
    }

    .AudioPlayerStyleClassNameDiv {
      display: contents;
      width: 100%;
    }

    .AudioPlayerStyleClassNameImageTwo {
      height: 80px !important;
      margin-left: 0px;
      width: 100%;
    }

    .div {
      padding: 0px 0px 0px 0px;
    }

    .AudioPlayerpreviewSongStyleclassName {
      padding: 0px 0px 0px 0px;
      height:0px !important;
      overflow:hidden;
      transition: 0.2s !important;
      padding:0px !important;
    }
    .SongPlaying{
      height:48px !important;
      padding:0px !important;
    }
    .pp12{
      padding:0px;
      max-width:unset;
      font-size:14px;
      padding-left: 48px;
    }
    .spanner {
      bottom: 270px;
      margin-left: 85%;
      transform: rotateZ(90deg);
    }

    .AudioPlayerStyleClassNameFlexbox {
      display: flex;
      justify-content: space-around;
      // height: 85px;
      // margin-bottom: 2%;
      // overflow: scroll;
    }

    .tab:hover {
      padding: 10px 10px 10px 10px;
      background: #5dabffb0;
      border-radius: 14px;
      border-bottom: 5px solid #007aff;
      margin-left: 10px;
    }

    .tab:hover {
      padding: 10px 10px 10px 10px;
      background: #5dabffb0;
      border-radius: 14px;
      border-bottom: 5px solid #007aff;
      margin-left: 10px;
    }

    .tab:active {
      padding: 10px 10px 10px 10px;
      background: #5dabffb0;
      border-radius: 14px;
      border-bottom: 5px solid #007aff;
      margin-left: 10px;
    }

    .tab:active {
      padding: 10px 10px 10px 10px;
      background: #5dabffb0;
      border-radius: 14px;
      border-bottom: 5px solid #007aff;
      margin-left: 10px;
    }

    .tab:focus {
      padding: 10px 10px 10px 10px;
      background: #5dabffb0;
      border-radius: 14px;
      border-bottom: 5px solid #007aff;
      margin-left: 10px;
    }

    .tab:focus {
      padding: 10px 10px 10px 10px;
      background: #5dabffb0;
      border-radius: 14px;
      border-bottom: 5px solid #007aff;
      margin-left: 10px;
    }

    .AudioPlayerFlexbox {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: nowrap;
    }

    .title1 {
      font-size: 14px;
      color: ${(props) => props.theme.colors.primary1};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 25ch;
    }

    .tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      padding-left: 0px;
      align-items: center;
      width: calc(100% / 4);
    }

   

    .AudioPlayerStyleClassNameFlexbox1 {
      width: 100%;
      height: 3px;
      background: #1c1c1c;
      margin-top: 4%;
      margin-bottom: 2%;
    }
    .audioPlayerInner {
      width: 100%;
      display: flex;

    }
    .audioPlayerInner .AudioPlayerStyleClassName{
      // display:none;
    }
    .audioPlayer{
      position: relative !important;
      transform: unset;
      width: 100%;
      display: flex;
      justify-content:space-between;
      align-items:center;
      left:0px;
        .rhap_time{
        display:none;
       } 
        .rhap_progress-indicator{
        display:none;
       }
       .rhap_progress-container{
        margin: 0px;
        height:0px;
       }
       .rhap_progress-bar.rhap_progress-bar-show-download{
        left: 0px;
        position: absolute;
       }
       .rhap_progress-bar{
        height: 3px;
       }
       .rhap_rewind-button,.rhap_forward-button{
        display:none;
       }
       .rhap_container{
        width:auto;
        padding:0px;
        padding-right:10px;
       }
       .rhap_play-pause-button svg{
        width: 30px;
       }
    }
  }


  @media only screen and (min-width: 3000px) {
    .spanner {
      margin-left: 13%;
    }
  }




 
`;
