import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FutureList,
  GuideMag,
  HomeIcon,
  LogoutIcon,
  SearchIcon,
  User
} from "../../assets/index";
import { usePlaylistContext } from "../../context/PlaylistContext";
import {
  addItemToStorage,
  clearSiteStorage,
  deleteItemFromStorage,
  getRefreshedToken,
  getstripePrices,
  isEmptyOrNil,
  truncateText,
} from "../../utils";
import { SideBarStyle, SideBarTabStyle } from "./SideBar.style";
import { SideBarStyle1 } from "./SideBar.style1";

import { Avatar, Dropdown, Popover, Space } from "antd";
import axios from "axios";
import { BASE_URL } from "../../constants/api-urls";
import {
  SPOTIFY_CODE,
  SPOTIFY_REFRESH_TOKEN,
  SPOTIFY_TOKEN,
  SPOTIFY_USER_INFO
} from "../../constants/storage";
import { useLoginContext } from "../../context";
import CreatePlayListModal from "../../pages/Playlist/CreatePlayListModal";
import { Auth0Logout } from "../../utils";
import PlanToast from "../PlanToast/PlanToast";
import DeleteModal from "../Subscriptions/CancelSubscription";
import { SideBarAccount } from "./SideBarAccount.style";
import "./Sidebar.css";

interface SideBarProps {
  className?: string;
}

type SideBarTabProps = {
  title: string;
  icon?: React.ReactNode;
  className?: string;
  url?: string;
  onClick?: () => void;
  isActive?: boolean;
  popoverHide?: boolean;
  titlePop?:any;
  style?:any;
};

const SideBarTab = React.memo((props: SideBarTabProps) => {
  const { title, icon, className, url, onClick, isActive } = props;
  const navigate = useNavigate();

  const handleTabClick = () => {
    if (!isEmptyOrNil(onClick)) {
      onClick();
      return;
    }
    if (!url) return;
    navigate(url);
  };

  return (
    <SideBarTabStyle
      className={`${className} ${isActive ? "active sideBarTab" : " sideBarTab"
        }`}
        
      onClick={handleTabClick}
    >
      {icon}
      {props.popoverHide ?
        <p className="title">
          {truncateText(title, 23)}
        </p>
        :
        <Popover content={<p>{props.titlePop || title}</p>} placement="topLeft">
          <p className="title" style={props?.style}>
            {truncateText(title, 23)}
          </p>
        </Popover>
      }
    </SideBarTabStyle>
  );
});

const SideBar = React.memo((props: SideBarProps) => {
  const { state } = usePlaylistContext();
  const location = useLocation();
  const { dispatch } = useLoginContext();
  const navigate = useNavigate();

  const [theme, setTheme] = useState("dark");
  const [currentPage, setCurrentPage] = useState(0);
  const [user, setUser] = useState<any>({});
  const [createPlaylist, setcreatePlaylist] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [totalUnOwnerNumber, setTotalUnOwnerNumber] = useState(0)
  const [activeTab, setActiveTab] = useState("")
  useEffect(() => {
    // console.log(location,'location')
    let paths = location.pathname.split('/')
    let currentPath = paths[paths.length - 1]
    setActiveTab(currentPath)
  }, [location])
  const showModalDelete = () => {
    setIsModalOpenDelete(true);
  };

  const handleOkDelete = () => {
    setIsModalOpenDelete(false);
    handleLogout();
  };

  const handleCancelDelete = () => {
    setIsModalOpenDelete(false);
  };

  useEffect(() => {
    setTheme(state?.isWholeTheme === false ? "dark" : "light");
  }, [state]);
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userInfo")));
  }, []);

  let proPlanMonthly = process.env.REACT_APP_STRIPE_PRO_MONTHLY;
  let proPlan = process.env.REACT_APP_STRIPE_PRO;
  let unlimitedPlanMonthly = process.env.REACT_APP_STRIPE_UNLIMITED_MONTHLY;
  let unlimitedPlan = process.env.REACT_APP_STRIPE_UNLIMITED;


  const handleLogout = async () => {
    deleteItemFromStorage(SPOTIFY_TOKEN);
    deleteItemFromStorage(SPOTIFY_CODE);
    deleteItemFromStorage(SPOTIFY_USER_INFO);
    localStorage.removeItem("userInfo");
    localStorage.removeItem("spotifyUser");
    clearSiteStorage();

    dispatch({
      type: "SET_LOGIN",
      payload: false,
    });
    Auth0Logout();
  };

  const { dispatch: newDispatch } = usePlaylistContext();
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();



  const [playlists, setPlaylists] = useState([]);
  const [totalPlaylist, setTotalPlaylist] = useState(0);
  const CurrentPages = (page) => {
    axios(
      `https://api.spotify.com/v1/me/playlists?limit=10&offset=${playlists.length === totalPlaylist ? 0 : playlists.length
      }`,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + loginState.token },
      }
    )
      .then((genreResponse) => {
        setTotalPlaylist(genreResponse?.data.total);
        if (playlists.length === totalPlaylist) {
          setCurrentPage(1);
          setPlaylists([...genreResponse?.data.items]);
        } else {
          setCurrentPage(page + 1);
          setPlaylists([...playlists, ...genreResponse?.data.items]);
        }
        // newDispatch({
        //   type: "INITIALIZE_PLAYLISTS",
        //   payload: genreResponse?.data.items,
        // });
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken)
            .then((response) => {
              addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

              loginDispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            })
            .catch(() => { });
        }
      });
  };


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (loginState.token) {
      if (currentPage === 0) {
        CurrentPages(currentPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState, currentPage]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (async () => {
      try {
        let STRIPE_PRO_MONTHLY = process.env.REACT_APP_STRIPE_PRO_MONTHLY;
        let STRIPE_PRO = process.env.REACT_APP_STRIPE_PRO;
        let STRIPE_UNLIMITED_MONTHLY =
          process.env.REACT_APP_STRIPE_UNLIMITED_MONTHLY;
        let STRIPE_UNLIMITED = process.env.REACT_APP_STRIPE_UNLIMITED;
        let prices = await getstripePrices();
        let proPlans = prices.data.data.filter(
          (obj) => obj.id === STRIPE_PRO || obj.id === STRIPE_PRO_MONTHLY
        );
        let unlimitedPlans = prices.data.data.filter(
          (obj) =>
            obj.id === STRIPE_UNLIMITED_MONTHLY || obj.id === STRIPE_UNLIMITED
        );

        let monthly_pro_price = Object.values(
          proPlans.filter((obj) => obj.id === STRIPE_PRO_MONTHLY)[0]
            .currency_options
          // @ts-ignore
        ).map((obj) => obj.unit_amount);
        let annually_pro_price = Object.values(
          proPlans.filter((obj) => obj.id === STRIPE_PRO)[0].currency_options
          // @ts-ignore
        ).map((obj) => obj.unit_amount);
        let monthly_unlimited_price = Object.values(
          unlimitedPlans.filter((obj) => obj.id === STRIPE_UNLIMITED_MONTHLY)[0]
            .currency_options
          // @ts-ignore
        ).map((obj) => obj.unit_amount);
        let annually_unlimited_price = Object.values(
          unlimitedPlans.filter((obj) => obj.id === STRIPE_UNLIMITED)[0]
            .currency_options
          // @ts-ignore
        ).map((obj) => obj.unit_amount);
        newDispatch({
          type: "SET_PLANS_PRICING",
          payload: {
            proPlansPrices: proPlans,
            unlimitedPlansPrices: unlimitedPlans,
            monthly_pro_price,
            annually_pro_price,
            monthly_unlimited_price,
            annually_unlimited_price,
          },
        });
      } catch (err) {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userId = JSON.parse(localStorage.getItem("userInfo"))?.userId;
  const [spotifyConnectedUser, setSpotifyConnectedUser] = useState({
    code: "",
    image: "",
    username: "",
  });

  useEffect(() => {
    const spotifyUser = JSON.parse(localStorage.getItem("spotifyUser"));
    setSpotifyConnectedUser(spotifyUser);
    //Get User Profile
    const newData = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application-json",
          },
        };
        const response = await axios.get(
          `${BASE_URL}/user/${userId}/data`,
          config
        );
        // console.log(response?.data?.data);
        if (response) {
          setAccounts(response?.data?.data);
        }
      } catch (error) {
      }
    };
    newData();
  }, [userId]);
  const changeHandler = (
    token: any,
    user: any,
    refresh: any,
    code: any,
    image: any
  ) => {
    // const token = getItemFromStorage(SPOTIFY_TOKEN);
    addItemToStorage(SPOTIFY_CODE, code);
    deleteItemFromStorage(SPOTIFY_TOKEN);
    deleteItemFromStorage(SPOTIFY_REFRESH_TOKEN);
    const data = {
      username: user,
      image: image,
      code: token,
      userId: userId,
    };
    localStorage.setItem("spotifyUser", JSON.stringify(data, null, " "));
    if (token && refresh) {
      addItemToStorage(SPOTIFY_TOKEN, token);

      addItemToStorage(SPOTIFY_REFRESH_TOKEN, refresh);

      dispatch({
        type: "ADD_APP_TOKEN",
        payload: {
          token: token,
          refreshToken: refresh,
        },
      });
      window.location.replace("/home");
    }
  };
  const menu = (
    <SideBarAccount>
      <p
        className="dropDownHeading"
        onClick={() => {
          // console.log(accounts, 'accounts')
        }}
      >
        OTHER ACCOUNTS
      </p>
      <div className="menuLinks">
        {accounts.map((a, i) => {
          // console.log(a);
          return (
            <div
              className={`menuDiv ${a?.username ===
                JSON.parse(localStorage.getItem("spotifyUser"))?.username
                ? "active"
                : !user?.free ?
                  user?.planId === unlimitedPlan ||
                    user?.planId === unlimitedPlanMonthly ?
                    ''
                    : (user?.planId === proPlan || user?.planId === proPlanMonthly) && i > 3 ?
                      'disabled'
                      : ''

                  : 'disabled'
                }`}
              onClick={() => {
                if (!user?.free) {
                  if (
                    user?.planId === proPlan ||
                    user?.planId === proPlanMonthly
                  ) {
                    if (i < 4) {
                      changeHandler(
                        a?.token,
                        a?.username,
                        a?.refresh,
                        a?.code,
                        a?.image
                      );
                    } else {
                      // toast.error("Upgrade plan");
                    }
                  } else if (
                    user?.planId === unlimitedPlan ||
                    user?.planId === unlimitedPlanMonthly
                  ) {
                    changeHandler(
                      a?.token,
                      a?.username,
                      a?.refresh,
                      a?.code,
                      a?.image
                    );
                  }
                } else {
                  // toast.error("Upgrade plan");
                }
              }}
            >
              <div className="imageName">
                {/* <img src={a.image === "dummy" ? userPic : a.profileImage} /> */}
                <Avatar
                  // size={60}
                  style={{}}
                  className="avater1 account-span"
                  icon={
                    !a?.image ? (
                      <span style={{}} className="spanner3">
                        {a?.username?.length >= 2 ? (
                          a?.username[0] + a?.username[1]
                        ) : (
                          <img
                            src="./static/mini-logo.png"
                            style={{}}
                            className="images2"
                            alt="user"
                          />
                        )}
                      </span>
                    ) : a?.image === "dummy" ? (
                      <span className="spanner3">
                        {a?.username?.length >= 2 ? (
                          a?.username[0] + a?.username[1]
                        ) : (
                          <img alt="user" src="/mini-logo.png" className="images2" />
                        )}
                      </span>
                    ) : (
                      <img alt="user" src={a?.image?.length ? a?.image[0]?.url : null} />
                    )
                  }
                ></Avatar>
                {a?.username.slice(0, 18)} {a?.username.length > 18 ? "...." : ""}
              </div>
              <svg
                className="ticIcon"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.75 8.75L6.25 12.25L13.25 4.75"
                  stroke="#56BA32"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          );
        })}
      </div>
      <hr />

      <SideBarTab
        isActive={activeTab === 'settings'}
        className="settings-button"
        style={{fontSize:'12px'}}
        icon={
          theme === "dark" ? (
            <User color={"white"} />
          ) : (
            <User color={"#1D1D1D"} />
          )
        }
        title="Account overview"
        url="/settings"
      />

      <SideBarTab
        className="logout-button"
        style={{fontSize:'12px'}}
        icon={
          theme === "dark" ? (
            <LogoutIcon color={"white"} />
          ) : (
            <LogoutIcon color={"#1D1D1D"} />
          )
        }
        title="Logout"
        onClick={() => showModalDelete()}
      />
    </SideBarAccount>
  );
  const [totalPlaylistNumber, setTotalPlaylistNumber] = useState(0)
  const [totalPlaylistArr, setTotalPlaylistArr] = useState([])

  const getPlaylists = (offset) => {
    axios(`https://api.spotify.com/v1/me/playlists?limit=50&offset=${offset}`, {
      method: "GET",
      headers: { Authorization: "Bearer " + loginState.token },
    })
      .then((genreResponse) => {
        setTotalPlaylistArr([...totalPlaylistArr, ...genreResponse?.data.items]);
        if (totalPlaylistNumber < genreResponse?.data.total) {
          setTotalPlaylistNumber(genreResponse?.data.total)
        }
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken)
            .then((response) => {
              addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

              loginDispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            })
            .catch(() => { });
        }
      });
  }
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (totalPlaylistArr.length < totalPlaylistNumber || totalPlaylistArr.length === 0) {
      getPlaylists(totalPlaylistArr.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPlaylistNumber, totalPlaylistArr, loginState]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (totalPlaylistArr && totalPlaylistArr.length) {

      const spotifyUser = JSON.parse(localStorage.getItem("spotifyUser"));
      let arr = totalPlaylistArr.filter(obj => obj?.owner?.display_name !== spotifyUser?.username)
      if ((totalPlaylist - arr.length) > 0) {
        setTotalUnOwnerNumber(totalPlaylist - arr.length)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPlaylistArr, totalPlaylist])

  return (
    <>
      <SideBarStyle1>
        <div className={"SideBarStyleclassName1" + ` ${props.className}`}>
          <SideBarStyle>
            <div>
              {theme === "dark" ? (
                <img
                  className="logo-image"
                  src="./static/songplace.png"
                  alt="songplace-logo"
                />
              ) : (
                <img
                  className="logo-image"
                  id="logo-image1"
                  src="./static/logo.png"
                  alt="songplace-logo"
                />
              )}
              {/* <Switch  onChange={ThemeToggler} /> */}
              <div className="rows avatar-row">
                <Avatar
                  // size={60}
                  style={{}}
                  className="avater1 account-span"
                  icon={
                    !spotifyConnectedUser?.image ? (
                      <span style={{}} className="spanner3">
                        {spotifyConnectedUser?.username?.length >= 2 ? (
                          spotifyConnectedUser?.username[0] +
                          spotifyConnectedUser?.username[1]
                        ) : (
                          <img
                            src="./static/mini-logo.png"
                            style={{}}
                            className="images2"
                            alt="user"
                          />
                        )}
                      </span>
                    ) : spotifyConnectedUser?.image === "dummy" ? (
                      <span className="spanner3">
                        {spotifyConnectedUser?.username?.length >= 2 ? (
                          spotifyConnectedUser?.username[0] +
                          spotifyConnectedUser?.username[1]
                        ) : (
                          <img alt="user" src="./static/mini-logo.png" className="images2" />
                        )}
                      </span>
                    ) : (
                      <img alt="user"
                        src={
                          spotifyConnectedUser?.image?.length
                            ? //@ts-ignore

                            spotifyConnectedUser?.image[0]?.url
                            : null
                        }
                      />
                    )
                  }
                ></Avatar>
                <p className="userNameSidebar">
                  {spotifyConnectedUser?.username}
                </p>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  overlayClassName="switchAccount"
                >
                  <span onClick={(e) => e.preventDefault()} style={{ cursor: "pointer" }}>
                    <Space>
                      <img alt="menuIcon" src="./static/3dot.png" />
                    </Space>
                  </span>
                </Dropdown>
              </div>
              <div className="rows">
                <p className="actions">Menu</p>
              </div>
              <div className="tab-wrapper">
                <SideBarTab
                  isActive={activeTab === 'home'}
                  className="settings-button"
                  icon={
                    theme === "dark" ? (
                      <HomeIcon color={"white"} />
                    ) : (
                      <HomeIcon color={"#1D1D1D"} />
                    )
                  }
                  title="Home"
                  url="/home"
                />
              </div>
              <div className="tab-wrapper">
                <SideBarTab
                  isActive={activeTab === 'search'}
                  className="settings-button"
                  icon={
                    theme === "dark" ? (
                      <SearchIcon color={"white"} />
                    ) : (
                      <SearchIcon color={"#1D1D1D"} />
                    )
                  }
                  title="Browse"
                  url="/search"
                />
              </div>
              {/* <Popover content={<p>View or edit your scheduled pre-release songs</p>} placement="topLeft"> */}
              <div className="tab-wrapper">
                <PlanToast
                  available={["unlimited"]}
                  titleHeading={"View or edit your scheduled pre-release songs"}
                  showPopup={true}
                >

              {/* <Popover content={<p>View or edit your pre-release songs</p>} placement="topLeft"> */}
              <SideBarTab
                    isActive={activeTab === 'future_playlist'}
                    className="settings-button"
                    icon={
                      theme === "dark" ? (
                        <FutureList color={"white"} />
                      ) : (
                        <FutureList color={"#1D1D1D"} />
                      )
                    }
                    titlePop="View or edit your pre-release songs"
                    title="Pre-release"
                    url="/future_playlist"
                  />
              {/* </Popover> */}
              </PlanToast>
              </div>
              {/* 23/04/2024 this wil be in new release */}
              <Popover content={<p>View or edit your scheduled songs</p>} placement="topLeft">
                <button className="create-playlist" onClick={() => navigate('/schedule_song')}>Songplace overview</button>
              </Popover>
              <hr style={{}} className="hr-100" />

            </div>
            <div className="playlist-wrapper">
              <div className="padder">
                <p className="actions">
                  <b>My playlists ({totalUnOwnerNumber})</b>
                </p>
                <Popover content={<p>Create playlist</p>} placement="topLeft">
                  <span
                    className="plusPlaylist"
                    onClick={() => setcreatePlaylist(true)}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path>
                    </svg>
                  </span>
                </Popover>
              </div>
              <div>{/* <CreatePlayList /> */}</div>
              <div className="playlist-items">
                <div className="playlist-items1">
                  {playlists?.map((data, index) => {
                    const { name, id } = data;

                    return data?.owner?.display_name === spotifyConnectedUser?.username ? (
                      <>

                        <SideBarTab
                          title={name}
                          isActive={activeTab === id}
                          url={`/playlist/${id}`}

                        />
                      </>
                    ) : null;
                  })}
                  <div style={{ position: "relative" }}>

                    {Number(totalPlaylist) > 10 ? (
                      <p
                        onClick={(e) => {

                          CurrentPages(currentPage);
                        }}
                        className="view-all"
                      >
                        {playlists.length === totalPlaylist
                          ? `View less`
                          : `View more`}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="div5">
              <div className="rowing">
                <hr style={{}} className="hr-90" />
              </div>
              <div className="tab-wrapper" style={{ paddingBottom: "10px" }}>
                <div style={{}} className="padder">
                  <p style={{}} className="actions">
                    Actions
                  </p>
                </div>

                <SideBarTab
                  isActive={activeTab === 'settings'}
                  className="settings-button"
                  icon={
                    theme === "dark" ? (
                      <User color={"white"} />
                    ) : (
                      <User color={"#1D1D1D"} />
                    )
                  }
                  title="Account overview"
                  url="/settings"
                />
                <SideBarTab
                  isActive={activeTab === 'user-guide'}
                  className="settings-button"
                  icon={
                    theme === "dark" ? (
                      <GuideMag color={"white"} />
                    ) : (
                      <GuideMag color={"#1D1D1D"} />
                    )
                  }
                  title="User guidelines"
                  url="/user-guide"
                />
                <SideBarTab
                  className="logout-button"
                  icon={
                    theme === "dark" ? (
                      <LogoutIcon color={"white"} />
                    ) : (
                      <LogoutIcon color={"#1D1D1D"} />
                    )
                  }
                  title="Logout"
                  onClick={() => showModalDelete()}
                />
              </div>
            </div>
          </SideBarStyle>
        </div>
        <div className="SideBarStyleclassName1"></div>
        <DeleteModal
          handleCancel={handleCancelDelete}
          showModal={showModalDelete}
          isModalOpen={isModalOpenDelete}
          handleOk={handleOkDelete}
          heading={"Are you sure you want to log out?"}
          headingText={""}
          button1={"Logout"}
          button2={"Cancel"}
        />
        <CreatePlayListModal
          isModalVisible={createPlaylist}
          setIsModalVisible={setcreatePlaylist}
          playlists={playlists}
          setPlaylists={setPlaylists}
        />

      </SideBarStyle1>
    </>
  );
});

export default SideBar;
