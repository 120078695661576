import { Modal } from "antd";
import styled from "styled-components";

export const EditToPlayModalStyle = styled(Modal)`
  .heading {
    font-size: 26px;
    color: ${(props) => props.theme.colors.primary};
  }

  .description {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-modal-content {
    background-color: ${(props) => props.theme.background.secondary};
    color: ${(props) => props.theme.colors.primary};
    width: 778px;
    border: 1px solid white;
    border-radius: 7px;
  }

  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }
  .heading {
    text-shadow: 0px 0px #ff0000 !important;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    // z-index: 1058 !important;
    z-index: 1000000 !important;
  }

  .ant-select-selection-item {
    font-size: 15px !important;
  }
  .ant-select-selector {
    height: 49px !important;
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .date {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .ant-picker {
    width: 100% !important;
  }

  .row-space {
    display: flex;
    justify-content: space-around;
  }

  .divs3 {
    background: transparent;
    padding: 0px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
  }
  .images5 {
    width: 100px;
    border-radius: 8px;
    background: transparent;
    box-shadow: 0px 6px 32px 6px rgba(0, 0, 0, 0.2);
  }

  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }

  .footer {
    display: flex;
    gap: 10px;
    align-self: flex-end;
  }
  .row1 {
    display: flex;
    justify-content: space-between;
  }
  .row2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #div1 {
    background-color: ${(props) => props.theme.background.main};
    padding: 20px;
    width: 30%;
    border-radius: 8px;
    text-align: center;
  }
  .h2 {
    // font-weight: 600;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props) => props.theme.colors.primary} !important;
    font-size: 20px;
  }
  .h3 {
    // font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
  }

  .btn1 {
    border: 1px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    padding: 14px 18px;
  }

  .btn2 {
    padding: 20px;
    font-size: 16px;
  }

  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      width: 100%;
      margin-left: 0%;
    }

    .ant-select-selection-item,
    .ant-select-item {
      font-size: 17px !important;
    }

    .ant-modal-body {
      padding: 14px;
    }

    .h2 {
      max-width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 11px;
    }
  }
`;

export const EditToPlayStyle = styled.div`
  .spanner {
    font-size: 15px;
    color: ${(props) => props.theme.colors.primary};
    display: flex;
  }
  .spanner1 {
    margin-left: 5px;
  }
`;
