import { message } from "antd";
import { Form, Formik } from "formik";
import { useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { InputBox } from "..";
import { FilledButtonStyle } from "../../styles/common.style";
import {
  AddToPlayListModalStyle,
  AddToPlayListStyle,
} from "./UsernameModelstyle";
import { CheckIcon } from "../../assets";


const PasswordModel = (props) => {

  const [setLoading, showLoading] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);



  const handleOk = () => {
    setEditModalVisible(false);
  };

  const [setButtom, showButtom] = useState("");

  const [password, setPassword] = useState("");
  const [confpassword, setConfPassword] = useState("");
  const [passworderror, setPasswordErr] = useState("disabled");
  const [isUppercaseRegExp, setUppercaseRegExp] = useState("#ffffff54");
  const [isPasswordLength, setPasswordLength] = useState("#ffffff54");
  const [isLowercasePassword, setLowercasePassword] = useState("#ffffff54");
  const [isDigitsPassword, setdigitsPassword] = useState("#ffffff54");
  const [isSpecialCharPassword, setSpecialCharPassword] = useState("#ffffff54");
  const [isMinLengthPassword, setMinLengthPassword] = useState("#ffffff54");



  const handleCancel = (e: any) => {
    e.stopPropagation();
    setEditModalVisible(false);
  };

  const showModal1 = () => {
    setEditModalVisible(true);
  };

  const handleValidation = (evnt) => {
    setConfPassword(evnt.target.value);


  };

  const handlePassValidation = (evnt: any) => {
    setPassword(evnt.target.value);

    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[!@$%^&*])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength > 0) {
        setPasswordLength("green");
      } else if (passwordLength === 0) {
        setPasswordLength("#ffffff54");
        errMsg = "Password field should not be empty";
      }
      if (uppercasePassword) {
        setUppercaseRegExp("green");
      } else if (!uppercasePassword) {
        setUppercaseRegExp("#ffffff54");
        errMsg = "At least one Uppercase";
      }
      if (lowercasePassword) {
        setLowercasePassword("green");
      } else if (!lowercasePassword) {
        setLowercasePassword("#ffffff54");
        errMsg = "At least one Lowercase";
      }
      if (digitsPassword) {
        setdigitsPassword("green");
      } else if (!digitsPassword) {
        setdigitsPassword("#ffffff54");
        errMsg = "At least one digit";
      }
      if (specialCharPassword) {
        setSpecialCharPassword("green");
      } else if (!specialCharPassword) {
        setSpecialCharPassword("#ffffff54");
        errMsg = "At least one Special Characters";
      }
      if (minLengthPassword) {
        setMinLengthPassword("green");
      } else if (!minLengthPassword) {
        setMinLengthPassword("#ffffff54");
        errMsg = "Minumum 8 characters needed";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
  };


  const user = JSON.parse(localStorage.getItem("userInfo"));
  // console.log(state.searchedSongData);
  const handleFormSubmit = (values: any) => {
    // const { password, confirmpassword } = values;
    if (password === confpassword) {
      showLoading(true);
      const configs = {
        headers: {
          "content-type": "application/json",
        },
      };
      const bodies = {
        client_id: process.env.REACT_APP_AUTH0,
        client_secret: process.env.REACT_APP_AUTH0_SECRET,
        audience: "https://dev-2z4p074m.us.auth0.com/api/v2/",
        grant_type: `client_credentials`,
      };
      axios
        .post(`https://dev-2z4p074m.us.auth0.com/oauth/token/`, bodies, configs)
        .then((responseding) => {
          const configs = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${responseding?.data?.access_token}`,
            },
          };
          const bodies = {
            password: `${password}`,
          };
          axios
            .patch(
              `https://dev-2z4p074m.us.auth0.com/api/v2/users/${user?.userId}`,
              bodies,
              configs
            )
            .then((response) => {
              showButtom("");
              message.info("Password updated");
              showLoading(false);
              setEditModalVisible(false);
            })
            .catch((error) => {
              message.error("Make a password powerful");
              showLoading(false);
            });
        })
        .catch((error) => {
          message.error("Error while updating password");
          showLoading(false);
        });
    } else {
      message.error("Confirm And New Password doesn't match");
      showButtom("Confirm And New Password doesn't match");
    }
  };

  return (
    <AddToPlayListStyle>
      <span onClick={showModal1} className="editNameModal" style={{ cursor: 'pointer' }}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="11"
            fill="#505050"
            stroke="white"
            stroke-opacity="0.2"
          />
          <g clip-path="url(#clip0_1895_29875)">
            <path
              d="M16.5408 9.13579L15.043 7.63801C14.9765 7.57136 14.8975 7.51847 14.8106 7.48239C14.7237 7.44631 14.6305 7.42773 14.5363 7.42773C14.4422 7.42773 14.349 7.44631 14.262 7.48239C14.1751 7.51847 14.0961 7.57136 14.0297 7.63801L7.93632 13.7669L7.44299 15.9047C7.42197 16.0083 7.42418 16.1153 7.44946 16.218C7.47474 16.3206 7.52245 16.4164 7.58918 16.4985C7.6559 16.5805 7.73998 16.6467 7.83535 16.6923C7.93073 16.738 8.03503 16.7619 8.14077 16.7625C8.19542 16.7685 8.25056 16.7685 8.30521 16.7625L10.4608 16.2869L16.5408 10.1491C16.6074 10.0826 16.6603 10.0037 16.6964 9.91674C16.7325 9.82979 16.751 9.73659 16.751 9.64245C16.751 9.54832 16.7325 9.45511 16.6964 9.36817C16.6603 9.28123 16.6074 9.20226 16.5408 9.13579ZM9.99854 15.4647L8.37188 15.8247L8.74966 14.2113L13.3363 9.59356L14.5897 10.8469L9.99854 15.4647ZM15.0919 10.3447L13.8385 9.09134L14.5274 8.38912L15.7897 9.65134L15.0919 10.3447Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1895_29875">
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(4 4)"
              />
            </clipPath>
          </defs>
        </svg>
      </span>
      <AddToPlayListModalStyle
        visible={isEditModalVisible}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
        closable={false}
      >
        <div>
          <h1 className="heading">Edit Password</h1>
        </div>
        <Formik
          initialValues={{ password: "", confirmpassword: "" }}
          onSubmit={(values, { resetForm }) => {
            handleFormSubmit(values);
            resetForm();
          }}
        >
          {() => (
            <Form className="form-wrapper">

              <InputBox
                className="passInput"
                type="password"
                name="password"
                label="New Password"
                transparent={true}
                onChange={(a) => handlePassValidation(a)}
                placeholder=""
                value={password}
              // onKeyUp={(e) => handleValidation(e)}
              />
              <li style={{ color: `${isUppercaseRegExp}`, fontWeight: "bold" }}>
              {isUppercaseRegExp === 'green' ? <CheckIcon /> :''} Uppercase character (A-Z)
              </li>
              <li
                style={{ color: `${isLowercasePassword}`, fontWeight: "bold" }}
              >
                {isLowercasePassword === 'green' ? <CheckIcon /> :''} Lowercase character (a-z)
              </li>
              <li style={{ color: `${isDigitsPassword}`, fontWeight: "bold" }}>
              {isDigitsPassword === 'green' ? <CheckIcon /> :''}  Number (0-9)
              </li>
              <li
                style={{
                  color: `${isSpecialCharPassword}`,
                  fontWeight: "bold",
                }}
              >
                {isSpecialCharPassword === 'green' ? <CheckIcon /> :''} Special character (!,@,#,$,%,&,*)
              </li>
              <li
                style={{ color: `${isMinLengthPassword}`, fontWeight: "bold" }}
              >
               {isMinLengthPassword === 'green' ? <CheckIcon /> :''}  8 characters
              </li>
              <InputBox
                type="password"
                name="confirmpassword"
                label="Confirm Password"
                placeholder=""
                onChange={(a) => handleValidation(a)}
                value={confpassword}
                transparent={true}
              />

              <span style={{ color: "red" }}>{setButtom}</span>
              <div className="footer">
                <FilledButtonStyle
                  background="transparent"
                  width="90px"
                  type="button"
                  onClick={handleCancel}
                  // style={{ border: "1px solid white", padding: "10px 50px" }}
                  className="btn2"
                >
                  Cancel
                </FilledButtonStyle>

                {passworderror !== "" ? (
                  <FilledButtonStyle
                    disabled
                    type="button"
                    style={{ padding: "10px 40px", fontSize: "15px" }}
                  >
                    {setLoading ? <LoadingOutlined /> : "Save"}
                  </FilledButtonStyle>
                ) : (
                  <FilledButtonStyle
                    type="submit"
                    style={{ padding: "10px 40px", fontSize: "15px" }}
                  >
                    {setLoading ? <LoadingOutlined /> : "Save"}
                  </FilledButtonStyle>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </AddToPlayListModalStyle>
    </AddToPlayListStyle>
  );
};

export default PasswordModel;
