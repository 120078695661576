import styled from "styled-components";

const SideBarAccount = styled.div`
  padding:12px;
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.background.secondary1};
  border: 1px solid #FFFFFF4D;
  border-radius:10px;
  width: 244px;
  margin-top: 10px;
.dropDownHeading{
  padding: 6px 20px;
  font-family: Poppins;
font-size: 9px;
font-weight: 600;
line-height: 18px;
letter-spacing: 0.04em;
text-align: left;
opacity:0.5;
}
.avater1 {
    background: #2dce6e;
    margin-right: 0px;
        font-size: 14px;
    width: 26px;
    height: 26px;
    display:fex;
    align-items:center;
    justify-content: center;
        margin-right: 10px;
}
.spanner3 {
  color: white;
  text-transform: uppercase;
}
.menuLinks{
  max-height: calc(40.17px * 3);
  overflow-y: auto;
}
.menuDiv{
  padding: 8px;
  border-radius: 6px;
  color:white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity:0.5;
  cursor:pointer;
}
.menuDiv img{
  width:24px;
  height:24px;
  border-radius: 100%;
  margin-right: 14px;
}
.menuDiv:hover{
  background: #303036;
  color:white;
  opacity:1;
}
.menuDiv.active,.menuDiv:hover{
  background: #303036;
  color:white;
  opacity:1;
}

.menuDiv.disabled:hover{
  opacity:0.5;
  cursor:not-allowed;
  background:transparent;
}
.menuDiv .imageName{
  display:flex;
  align-items:center;
  font-size: 12px;
}
.menuLinks .ticIcon{
  opacity:0;
}
.menuDiv.active .ticIcon{
  opacity:1;
}
.menuLinks{
}
hr{
  border: 0.5px solid #FFFFFF;
  opacity:0.5;
  margin-bottom:12px;
  margin-top:12px;
}
.settingSVG{
  margin-right: 14px;

}

`;
export { SideBarAccount };
