import styled from "styled-components";

export const BottomNavigationStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ;

    svg {
        fill: #fff;
    }

    .nav-item {
        padding:  20px;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        &.active {
            background: rgba(0, 0, 0, 0.5);
        }
    }
`;
