import auth0 from "auth0-js";
import axios from "axios";
import { BASE_URL } from "../../constants/api-urls";
import {
  ADD_USER_SPOTIFY_PROFILE_FAIL,
  ADD_USER_SPOTIFY_PROFILE_REQUEST,
  ADD_USER_SPOTIFY_PROFILE_SUCCESS,
  DELETE_USER_SPOTIFY_PROFILE_FAIL,
  DELETE_USER_SPOTIFY_PROFILE_REQUEST,
  DELETE_USER_SPOTIFY_PROFILE_SUCCESS,
  GET_USER_SPOTIFY_PROFILE_FAIL,
  GET_USER_SPOTIFY_PROFILE_REQUEST,
  GET_USER_SPOTIFY_PROFILE_SUCCESS,
  IS_AUDIOPLAYER_OPEN,
} from "../types/playlistTypes";

//Get User Profile
export const getUserProfileAction =
  (userId: any) =>
    async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
      try {
        // console.log(companyName);
        dispatch({
          type: GET_USER_SPOTIFY_PROFILE_REQUEST,
        });
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/user/${userId}/data`,
          config
        );
        // console.log(companyWithOwner);
        dispatch({
          type: GET_USER_SPOTIFY_PROFILE_SUCCESS,
          payload: data?.data,
        });
      } catch (error) {
        let err =
          error.response &&
          (typeof error.response.data == "string"
            ? error.response.data
            : error.response.data.message
              ? error.response.data.message
              : error.response.data.errors
                ? error.response.data.errors[0].msg
                : error.message);
        if (!err) {
          err = "Network Error";
        }
        dispatch({
          type: GET_USER_SPOTIFY_PROFILE_FAIL,
          payload: err,
        });
        throw Error(err);
      }
    };

//Add User Profile
export const addUserProfileAction =
  (name: string, image: string, code: string, userId: string) =>
    async (dispatch) => {
      try {
        // console.log(companyName);
        dispatch({
          type: ADD_USER_SPOTIFY_PROFILE_REQUEST,
        });
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const formData = {
          username: name,
          image: image,
          code: code,
          userId: userId,
        };
        const { data } = await axios.post(
          `${BASE_URL}/user/add`,
          formData,
          config
        );
        if (data) {

          setTimeout(() => {
            window.location.replace("/home");
          }, 2000)

        }
        dispatch({
          type: ADD_USER_SPOTIFY_PROFILE_SUCCESS,
          payload: data?.data,
        });
      } catch (error) {

        let err =
          error.response &&
          (typeof error.response.data == "string"
            ? error.response.data
            : error.response.data.message
              ? error.response.data.message
              : error.response.data.errors
                ? error.response.data.errors[0].msg
                : error.message);
        if (!err) {
          err = "Network Error";
        }
        dispatch({
          type: ADD_USER_SPOTIFY_PROFILE_FAIL,
          payload: err,
        });
        throw Error(err);
      }
    };

//Add User Profile
export const deleteUserProfileAction =
  (id: any) =>
    async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
      try {
        // console.log(companyName);
        dispatch({
          type: DELETE_USER_SPOTIFY_PROFILE_REQUEST,
        });
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URI}/user/${id}/delete`,
          config
        );
        // console.log(companyWithOwner);
        dispatch({
          type: DELETE_USER_SPOTIFY_PROFILE_SUCCESS,
          payload: data?.data,
        });
      } catch (error) {
        let err =
          error.response &&
          (typeof error.response.data == "string"
            ? error.response.data
            : error.response.data.message
              ? error.response.data.message
              : error.response.data.errors
                ? error.response.data.errors[0].msg
                : error.message);
        if (!err) {
          err = "Network Error";
        }
        dispatch({
          type: DELETE_USER_SPOTIFY_PROFILE_FAIL,
          payload: err,
        });
        throw Error(err);
      }
    };

export const webAuth = new auth0.WebAuth({
  domain: `dev-2z4p074m.us.auth0.com`,
  clientID: `${process.env.REACT_APP_AUTH0}`,
  // scope: "read:current_user read:users_app_metadata update:users_app_metadata   update:current_user_metadata",
});
export const authentication = new auth0.Authentication({
  domain: `dev-2z4p074m.us.auth0.com`,
  clientID: `${process.env.REACT_APP_AUTH0}`,
});

// export const switchAudioBarWidth = (isOpen:any) => {
//   async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
//     dispatch({
//       type: IS_AUDIOPLAYER_OPEN,
//       payload: isOpen
//     })
//   }
// }