import { useField } from "formik";
import { InputBoxStyle } from "../../styles/common.style";
import { InputStyle } from "./InputBox.style";

const InputBox = (props: any) => {
  const [field] = useField(props);
  return (
    <InputBoxStyle>
      <label>{props.label}</label>
      <InputStyle
        className={props.className}
        name={field.name}
        color={props.color}
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
        placeholder={props.label}
        width={props.width}
        height={props.height}
        type={props.type}
        transparent={props.transparent}
        {...props}
      />
      {props.error && <p className="error">{props.error}</p>}
    </InputBoxStyle>
  );
};

export default InputBox;
