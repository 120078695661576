//GET_USER_SPOTIFY_PROFILE
export const GET_USER_SPOTIFY_PROFILE_REQUEST =
  "GET_USER_SPOTIFY_PROFILE_REQUEST";
export const GET_USER_SPOTIFY_PROFILE_SUCCESS =
  "GET_USER_SPOTIFY_PROFILE_SUCCESS";
export const GET_USER_SPOTIFY_PROFILE_FAIL = "GET_USER_SPOTIFY_PROFILE_FAIL";

//ADD_USER_SPOTIFY_PROFILE
export const ADD_USER_SPOTIFY_PROFILE_REQUEST =
  "ADD_USER_SPOTIFY_PROFILE_REQUEST";
export const ADD_USER_SPOTIFY_PROFILE_SUCCESS =
  "ADD_USER_SPOTIFY_PROFILE_SUCCESS";
export const ADD_USER_SPOTIFY_PROFILE_FAIL = "ADD_USER_SPOTIFY_PROFILE_FAIL";

//DELETE_USER_SPOTIFY_PROFILE
export const DELETE_USER_SPOTIFY_PROFILE_REQUEST =
  "DELETE_USER_SPOTIFY_PROFILE_REQUEST";
export const DELETE_USER_SPOTIFY_PROFILE_SUCCESS =
  "DELETE_USER_SPOTIFY_PROFILE_SUCCESS";
export const DELETE_USER_SPOTIFY_PROFILE_FAIL =
  "DELETE_USER_SPOTIFY_PROFILE_FAIL";

//audioPlayer
export const IS_AUDIOPLAYER_OPEN = "IS_AUDIOPLAYER_OPEN"
