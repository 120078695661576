import styled from "styled-components";

const LandingPageStyle = styled.div`
  background: #131314;
  min-height: 100vh;
  width: 100%;
  .SideStyle-row {
    padding: 0px 7% 0px 7%;
    align-items: unset;
  }
    
  .SideStyleButton {
    background: #007aff;
    border-radius: 5px;
    border: 0px;
    padding: 12px 0px;
    cursor: pointer;
    width: 137px;
    color: #fff;

    font-family: Poppins;
    font-size: 21px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    transition: 0.3s ease-in-out;
        font-size: 14px;
        width: 133px;
        padding: 4px 0px;
  }
  .SideStyleButton:hover{
    background: white;
    color: #007aff;
  }
  .loginButton {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    margin-right: 40px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  .loginButton:hover {
    color: #007aff;
  }

  .blob1 {
    position: absolute;
    right: 0%;
    z-index: 0;
    top: 0;
  }
  .row4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row {
    display: flex;
    justify-content: space-between;
    padding: 2% 4.2% 2% 4.2%;
    // margin-top: 2%;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 3;
  }
  .navbar-li {
    margin: 0px 5px;
    text-decoration: none;
    color: #ffffff;
       font-size: 16px;
    font-weight: 400;
    opacity: 0.7;
  }
  .navbar-li.active {
    opacity: 1;
    color: #007aff;
  }
  .SideStyleDiv {
    display: flex;
    flex-direction: row;
    height: auto;
    z-index: 1;
    position: relative;
  }
  .first-col {
    width: 47%;
    height: 90vh;
    background: transparent;
    overflow: initial;
    padding-bottom: 50px;
    padding-top: 5%;
  }
  .making {
    margin-left: 6% !important;
    margin-top: 15% !important;
  }
  .heading {
    font-size: 38px;
    color: white;
    font-weight: 600;
  }

  .sub-heading {
    font-size: 19px;
    color: white;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .paragraph {
    font-size: 12px;
    width: 80%;
    color: white;
  }
  .new-button {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5%;
  }

  .btn1 {
    padding: 10px ;
  }
  .btn2 {
    padding: 7px 30px;
  }

  .SideStyleOr {
    font-size: 18px;
    margin: 2%;
    color: white;
  }
  .btn2 {
    color: white;
    padding: 14px 0px;
    border-radius: 5px;
    background: #007aff;
    border: none;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 1px;
transition: 0.3s ease-in-out;
width:137px;
    font-size: 11px;
  }
  .btn2:hover{
    background: white;
    color: #007aff;
  }
  .firstImage {
    background-size: auto;
    overflow: initial !important;
  }
  .secondImage {
    background-image: url("./static/thirdImage.png");
    height: 64%;
    margin-top: 0%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    width: 100%;
  }
  .SideStyleDiv {
    display: flex;
    flex-direction: row;
    height: auto;
    z-index: 1;
  }

  .firstImage {
    background-image: url("${(props) => props.theme.background.firstImages}");
    background-repeat: no-repeat;
    background-position: right;
    // background-size: cover;
    height: 100vh;
    width: 53%;
    display: flex;
    align-items: center;
    overflow: initial !important;
  }
  .about-us-Container {
    position: relative;
    border: 1px solid #d1d1d1;
    background: #242429;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 22px 155px;
    z-index: 1;
    margin-bottom: 100px;
  }
  .about-us-Container h1 {
color: rgb(255, 255, 255);
    font-size: 35px;
    font-weight: 600;
    line-height: 50px;
  }
  .about-us-Container p {
    color: #fff;
    font-size: 12.53px;
    line-height: 32px;
    z-index: 1;
    margin: 12px 0px;

  }
  .about-us-blob1 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .about-us-blob2 {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .features-container {
    width: 87%;
    text-align: center;
    margin: auto;
    margin-bottom: 80px;
  }
  .features-container .main-para {
    width: 692px;
    text-align: center;
    color: #fff;
    font-size: 12.53px;
    font-weight: 400;
    line-height: 30px;
    margin: 0px auto;
    z-index: 1;
  }
  .features-container h1 {
color: rgb(255, 255, 255);
    font-size: 35px;
    font-weight: 600;
    line-height: 50px;
  }
  .features-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: left;
    color: white;
  }
  .features-list-items {
    border: 1px solid #d1d1d1;
    background: #242429;
    width: 22%;
    border-radius: 10px;
    padding: 22px;
    margin-top: 32px;
    margin-right: 32px;
  }
  .features-list-items img {
    margin-bottom: 12px;
    width: 52px;
    aspect-ratio: 1;
  }
  .features-list-items p {
    font-size: 12.53px;
    color: #fff;
    font-weight: 500;
  }
  .pricing-container {
    width: 87%;
    text-align: center;
    margin: auto;
    padding-bottom: 150px;
    position: relative;
  }
    .currnecyropDown{
    font-size: 12.53px;
    }
  .pricing-container .main-para {
    width: 692px;
    text-align: center;
    color: #fff;
    font-size: 12.53px;
    font-weight: 400;
    line-height: 30px;
    margin: 0px auto 15px;
    z-index: 1;
  }
  .pricing-container h1 {
    color: #fff;
font-size: 35px;
    font-weight: 600;
    line-height: 50px;
  }
  .annualMonth{
    
      font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color:#fff;
  margin-bottom:32px;
  z-index:1;
  position:relative;
  .div1{
    display:flex;
    align-items:center;
    justify-content:center;
    .ant-switch{
    min-width: 37px !important;
    height: 16px !important;
     .ant-switch-handle{
    left: 1px;
    right:auto;
    top: 1px !important;
    width: 12px !important;
    height: 12px !important;
    }
    &.ant-switch-checked .ant-switch-handle{
    left: auto;
    right: 1px;
    }
    }
  }
  .div2{
    display:flex;
    align-items:center;
    justify-content:center;
    }
p{
  margin-right:12px;
  font-size: 13px;
}
  }
  .main-title {
    font-size: 22.19px;
    color: white;
    font-weight: 700;
  }
  .sub-title {
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin: 10px 0px 20px 0px;
  }
  .pricing-container .SideStyleButton {
    width: 85%;
  }
  .pricing-container table {
    border-collapse: collapse;
    width: 100%;
    z-index: 1;
    position: inherit;
  }
  td {
    border: 1px solid #d1d1d1;
    padding:  0px;
  }
  td p {
    // width: 333px;
  }
  .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.5px;
    color: white;
    width: 85%;
    margin: auto;
  }
  .pricingplanblob {
    position: absolute;
    top: -50%;
    right: -7.5%;
    z-index: 0;
  }
  .news-letter-container {
    width: 87%;
    text-align: center;
    margin: auto;
    padding-bottom: 150px;
  }
  .news-letter-container .main-para {
    width: 692px;
    text-align: center;
    color: #fff;
    font-size: 12.53px;
    font-weight: 400;
    margin: 20px auto 60px auto;
    z-index: 1;
  }
  .news-letter-container h1 {
color: rgb(255, 255, 255);
    font-size: 35px;
    font-weight: 600;
    line-height: 50px;
  }
  .input-container {
    border: 1px solid #d1d1d1;
    background: #242429;
width: 599px;
    height: 63px;
    border-radius: 10px;
    padding: 8px;
    padding-left: 32px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-item: center;
  }
  .input-container input {
    width: 70%;
    background: none;
    outline: none;
    border: none;
    height: 100%;
  }
  .input-container button {
    width: 137px;
    color: white;
    background: #007aff;
    outline: none;
    border: none;
    border-radius: 10px;
    height: 100%;
    transition: 0.3s ease-in-out;
    font-size:12px;
  }
  .input-container button:hover{
    background: white;
    color: #007aff;
  }
  .footer-container {
    background: #007aff;
    width: 100%;
    padding: 20px 0px;
  }
  .footer-main,
  hr {
    width: 87%;
    margin: auto;
    display: flex;
    margin-bottom: 15px;
  }
  .footer-main{
    align-items:flex-end;
  }
  .footer-bottom {
    width: 87%;
    margin: auto;
    display: flex;
    // margin-bottom:45px;
    justify-content: space-between;
  }
  .footer-bottom .section3 {
    display: flex;
    justify-content: space-between;
    width: 25%;
  }
  .bottom-para {
    font-size: 12px;
    line-height: 28px;
    color: white;
  }
  .footer-para {
    font-size: 12px;
    margin-bottom: 15px;
    color: white;
  }
  .footer-icon {
    margin-right: 20px;
  }
  .footer-section1 {
    width: 40%;
    .socialIconsDiv{
    img{
      width: 35px;
    aspect-ratio: 1 / 1;
    height: 35px;
    }
    }
  }
  .footer-section2 {
    width: 60%;
  }
  .footer-input-container {
    width: 80%;
    background: rgba(36, 36, 41, 0.5);
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    padding: 22px;
    margin-left: auto;
  }

  .footer-input-container form {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .footer-input-container input {
    background: rgba(36, 36, 41, 0.6);
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    padding: 11px 32px;
    width: 70%;
  }
  .footer-input-container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    color: white;
    margin-bottom: 10px;
  }
  .footer-input-container button {
    width: 137px;
    height: 45px;
    border-radius: 10px;
    background: #007aff;
    font-size: 12px;
    color: white;
    border: none;
    outline: none;
    transition: 0.3s ease-in-out;
  }
  .footer-input-container button:hover{
    background: white;
    color: #007aff;
  }
  .login-component {
    background: #242429;
  }
  .login-modal {
    position: absolute;
    width: 554px;
    height: 622px;
    top: 50%;
    background: #242429;
    left: 50%;
    z-index: 5;
    border-radius: 20px;
    border: 1px solid white;
    transform: translate(-50%, 50%);
    padding: 32px 24px 24px 24px;
  }
  .login-heading-container {
    display: flex;
    justify-content: space-between;
  }
  .login-heading {
    font-family: Poppins;
    font-size: 27px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.03em;
    text-align: left;
    color: white;
  }
  .login-heading-description {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    opacity: 0.7;
    margin-top: 20px;
  }
  .login-modal-inner {
    width: 100%;
    margin-top: 20px;
    background: #303036;
    padding: 24px  24px ;
    border-radius: 20px;
    border: 1px solid white;
  }
  .btn-svg {
    
    padding: 10px 7px;
    background: #fff;
  }
  .btn-svg img {
       height: 22px;
    width: 22px;
  }
  .btn-text {
      padding: 0px 7px;
      height: 44px !important;
    background: rgb(66, 133, 244);
    font-size: 11px;
  }
  .combine-button {
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 0px;
    border: none;
    outline: none;
    background: none;
    color: white;
    margin-right: 0px;
    cursor: pointer;
  }
  .login-or {
    font-size: 20px;
  }
  .scroll-header {
    border-bottom: 1px solid #d1d1d1;
    background: #242429;
    transition: 0.3s ease-in;
    padding: 20px 30px;
    backdrop-filter: blur(11px);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .SideStyle-row .row4 img {
    display: none;
  }

  
  @media only screen and (max-width: 1500px) {
    .heading{
      font-size: 38px;
    }
    .sub-heading{
      font-size: 19px;
    }
    .paragraph{
      font-size: 12px;
    }
    .about-us-Container h1{
      font-size: 35px;

    }
    .features-container h1{
              font-size: 35px;
    }
    .pricing-container h1{
              font-size: 35px;
    }
    .news-letter-container h1{
              font-size: 35px;
    }
    .navbar-li{
      font-size: 16px;
    }
    .loginButton{
      font-size: 14px;
    }
    .SideStyleButton{
             font-size: 14px;
        width: 133px;
        padding: 4px 0px;
    }
  }
  @media only screen and (max-width: 1100px) {
    .navbar {
      display: none;
    }
    .SideStyle-row .row4 button {
      display: none;
    }
    .SideStyle-row .row4 img {
      display: block;
    }
    .SideStyleDiv .firstImage {
      display: none;
    }
    .SideStyleDiv .first-col {
      width: 100%;
    }
    .about-us-Container {
      padding: 32px;
    }
    .features-list-items {
      width: 35%;
    }
    .footer-main {
      flex-direction: column;
    }
    .footer-section1 {
      width: 100%;
    }
    .footer-section2 {
      width: 100%;
    }
    .footer-input-container {
      margin-right: auto;
    }
  }
  .pricing-page {
    display: none;
  }
  @media only screen and (max-width: 770px) {
    .features-container .main-para {
      width: 90%;
    }
    .SideStyleDiv .first-col {
    height:auto;
    }
    .pricing-page {
      display: block;
    }
    .pricing-container .main-para {
      width: 90%;
    }
    .news-letter-container .main-para {
      width: 90%;
    }
    .news-letter-container .input-container {
      width: 95%;
    }
    .about-us-blob1 {
      width: 100%;
    }
    .btn2 {
      color: white;
      padding: 10px 30px;
      border-radius: 5px;
      background: #007aff;
      border: none;
      cursor: pointer;
      font-weight: 700;
      -webkit-letter-spacing: 1px;
      -moz-letter-spacing: 1px;
      -ms-letter-spacing: 1px;
      letter-spacing: 1px;
      padding: 10px 15px;
      height: 50px;
      width: 220px;
transition: 0.3s ease-in-out;
}
    
    .new-button {
      flex-direction: column;
    }
    .combine-button {
      margin-right: 0px;
    }
    .making .heading {
      font-size: 27px !important;
    }
    .making .sub-heading {
      font-size: 18px;
    }
    .making {
      margin-top: 20% !important;
    }
    .about-us-Container {
      width: 90%;
      padding: 32px 15px;
    }
    .features-list-items {
      width: 80%;
      margin-right: 0px;
    }
    .paragraph {
      font-size: 16px;
      width: 98%;
      color: white;
    }
    table {
      display: none;
    }
    .pricing-page__header-tablet {
      font-size: 1.5rem;
      text-align: center;
    }
    .pricing-page__tablet-card {
      padding: 48px 32px;
      border-radius: 4px;
      width: 100%;
      border: 1px solid #d1d1d1;
      background: #242429;
      z-index: 3;
      color: white;
      position: relative;
      z-index: 2;
      margin-bottom: 20px;
    }
    .pricing-page__dollar-price {
      font-size: 2rem;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .pricing-page__plan-btn {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      background-color: #3b8cff;
      border-color: #3b8cff;
      color: #fff;
      border-radius: 4px;
      border: 1px solid transparent;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .princing-page__include h5 {
      font-size: 35px;
      color: white;
      font-weight: bold;
      margin-top: 20px;
    }
    .princing-page__include {
      text-align: left;
    }
    .princing-page__include p {
      opacity: 0.8;
      margin-bottom: 5px;
    }
    .pricing-page__features-toggle span {
      color: #3b8cff;
      font-size: 1rem;
      margin-right: 8px;
    }
  }
.ant-dropdown-menu-item{
font-size: 12px;
}
  .dropDownButton{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    padding: 7px 9px;
        background: rgb(0 122 255 / 53%);
    border: 1px solid rgb(0, 122, 255);
    .userNameAv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    .ant-avatar.ant-avatar-icon{
        font-size: 11px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
        width: 25px;
    aspect-ratio: 1;
    height: 25px;
    }
    p{
        font-size: 12px;
        }
    }
    }
    .dropDownButtonAv{
    width: 47px;
height: 47px;
background:#007AFF;
color:white;
    font-size: 16px;
    border-radius: 60px;
    padding:0px;
    display:flex;
    align-items:center;
    justify-content:center;
}
`;
export { LandingPageStyle };


