import styled from "styled-components";


export const UserGuideStyle = styled.div`
.userGuideInner{
    background-image:url('./static/images/futureBackground.png') !important;
    background-position: top;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background: black;
    padding:0px 60px;
    .userGuideHead{
        padding-top:90px;
        h2{
            font-family: Poppins;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.04em;
            text-align: left;
            color:white;
            margin-bottom:14px;
            
        }
        p{
            font-family: Poppins;
            font-size: 12px;
            font-weight: 300;
            line-height: 21px;
            letter-spacing: -0.03em;
            text-align: left;
            color:#ffffff50;
            margin-bottom:32px;

        }
        .searchLabel{
            padding:8px;
            background: rgb(36, 36, 41);
            background-color: rgb(36, 36, 41);
            display:flex;
            align-items:center;
            width:50%;
            border: 0.6px solid #FFFFFF33;
            border-radius:8px;
            .searchIconDiv{
                margin-right:13px;
                display:flex;
                align-items:center;
                color:white;
                font-size:15px;
            }
            input{
              background: transparent;
    border: none;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.03em;
    text-align: left;
    width: 100%;
    color: white;
    outline: none;
    font-size: 13px;
            }
        }
    }
    .userGuideContent{
        margin-top:32px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        width:100%;
        h3{
            font-family: Poppins;
               font-size: 15px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.04em;
    text-align: left;
    color: white;
    margin-bottom: 10px;
    width: 100%;
        }
        h4{
            font-family: Poppins;
font-size: 15px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.04em;
text-align: left;
width:100%;
color:white;
margin-bottom:20px;
        }
p{
    color:#ffffffb5;
            font-family: Poppins;
font-size: 12px;
font-weight: 300;
line-height: 21px;
letter-spacing: 0.04em;
text-align: left;
width:100%;


}
}
.guideVideoDiv{
    margin-top:51.89px;
    margin-bottom:40px;
    position:relative;
    img {
        max-width: 760px;
        width: 100%;
        border: 1.59px solid #D1D1D1;
        border-radius:15.95px;
    }
    .video-react-video{
        max-width: 800px;
        width: 100%;
    }
    .controlsDiv{
        &:hover{   
            background: #00000038;
        }
        width:100%;
        height:100%;
        top: 0px;
        left: 0px;
        position: absolute;
        display:flex;
        align-items:center;
        justify-content:center;
        .skipTimeButtons{
            background-color:transparent;
            background: transparent;
            color: white;
            font-size: 40px;
            border: none;
            height:60px;
            width:60px;
            margin:0px 10px;
        }
        .playPauseBtn{
            
            display: flex;
            align-items: center;
        justify-content: center;
        width: 50px;
        aspect-ratio: 1;
        border-radius: 31px;
        border:none;
        svg{
            font-size: 25px;
        }
    }
    .fullScreenBtton{
        position: absolute;
    top: 20px;
    right: 20px;
    }
    }
    .pauseBtn, .skipTimeButtons{
        opacity:0;
        transition:0.2s;
    }
    &:hover{
        .pauseBtn, .skipTimeButtons{
            opacity:1;
        }
}
}
.video-react{
        padding-top:0px !important;
        display: flex;
    align-items: center;
    justify-content: center;
        video{
            border: 1.59px solid #D1D1D1;
            border-radius:15.95px;
        }
    }
    .video-react-button,.video-react-control-bar {
        display:none;
    } 
}

    `