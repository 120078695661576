import axios from "axios";
import isMobile from "is-mobile";
import { length } from "ramda";
import React, { useEffect, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Colapse } from "../../assets";
import {
  FutureListDark,
  FutureListMobile,
  FutureListMobile1,
  HomeIconMobile,
  PlaylistDark,
  SearchIconMobile
} from "../../assets/index";
import { SPOTIFY_TOKEN } from "../../constants/storage";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import {
  addItemToStorage,
  getDevices,
  getRefreshedToken,
  isEmptyOrNil
} from "../../utils";
import { SideBarTabStyle } from "../SideBar/SideBar.style";
import { DrawerStyle } from "../ToggledMenu/ToggledMenu.style";
import { AudioPlayerStyle, AudioPlayerStyle1 } from "./AudioPlayer.style";
import AudioPlayerTool from "./AudioPlayerTool";
const AudioPlayer1 = () => {
  const [isRotateDesk, setRotateDesk] = useState("rotateY(0deg)");
  const [showEvery, setEvery] = useState(true);
  // transform: rotateY(0deg);
  const { state } = usePlaylistContext();
  const { playlistId } = useParams();


  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    setTheme(state?.isWholeTheme === false ? "dark" : "light");
  }, [state]);



  const [currentPage, setCurrentPage] = useState(1);

  const { dispatch: newDispatch } = usePlaylistContext();
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();
  const CurrentPages = () => {
    axios(
      `https://api.spotify.com/v1/me/playlists?limit=40&offset=${(currentPage - 1) * 3
      }`,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + loginState.token },
      }
    )
      .then((genreResponse) => {
        newDispatch({
          type: "INITIALIZE_PLAYLISTS",
          payload: genreResponse?.data.items,
        });
      })
      .catch(async (error) => {
        setCurrentPage(1);
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken).then((response) => {
            addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

            loginDispatch({
              type: "ADD_TOKEN",
              payload: response?.data?.access_token,
            });
          }).catch(() => {

          });
        }
      });
  };
  useEffect(() => {
    getDevices(loginState.token)
  }, [loginState.token])

  const onHide = () => {
    setEvery(false);
    setRotateDesk("rotateY(180deg)");
  };

  const onFull = () => {
    setEvery(true);
    setRotateDesk("rotateY(0deg)");
  };



  const [showDrawer, setShowDrawer] = useState(false);

  const handleOpen = () => {
    if (length(state.playlists) === 0) return;

    setShowDrawer(true);
  };

  const handleClose = () => {
    setShowDrawer(false);
  };


  type SideBarTabProps = {
    title: string;
    icon?: React.ReactNode;
    className?: string;
    url?: string;
    onClick?: () => void;
    isActive?: boolean;
  };

  const SideBarTab = (props: SideBarTabProps) => {
    const { title, icon, className, url, onClick, isActive } = props;

    const navigate = useNavigate();

    const handleTabClick = () => {
      if (!isEmptyOrNil(onClick)) {
        onClick();
        return;
      }

      if (!url) return;

      navigate(url);
    };

    return (
      <SideBarTabStyle
        className={`${className} ${isActive ? "active" : ""}`}
        onClick={handleTabClick}
      >
        {icon}
        <p className="title" title={title}>
          {title}
        </p>
      </SideBarTabStyle>
    );
  };



  return (
    <>
      <AudioPlayerStyle1>
        {showEvery === true ? (
          isMobile() === true ? (
            <></>
          ) : (
            <span
              className="spanner"

              onClick={onHide}
            >
              <div style={{ transform: `${isRotateDesk}` }}>
                <Colapse />
              </div>
            </span>
          )
        ) : isMobile() === true ? (
          <></>

        ) : (
          <span
            className="spanner"

            onClick={onFull}
          >
            <div style={{ transform: `${isRotateDesk}`}}>
              <Colapse />
            </div>
          </span>
        )}
        <div className="div">
          <div className={`AudioPlayerpreviewSongStyleclassName ${showEvery ? " active" : ''} ${isMobile() && state.previewSong.name ? 'SongPlaying' : ''}`} >
            <AudioPlayerStyle>
              <div className={!showEvery ? `SongSideBar` : `audioPlayerInner`}>
                {showEvery !== true ? (
                  <div className="SongSideBarImage">
                    {state.previewSong?.image ? (
                      <img
                        alt="songImage"
                        src={state.previewSong?.image}
                        className="AudioPlayerStyleClassNameImageTwo"
                      />
                    ) : (
                      <img
                        alt="songImage"
                        src="./static/background1.png"
                        className="AudioPlayerStyleClassNameImage"
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <div className={!showEvery ? `SongSideBarImage` : "AudioPlayerStyleClassName"}>
                      {state.previewSong?.image ? (
                        <img
                          alt="songImage"
                          src={state.previewSong?.image}
                          className="AudioPlayerStyleClassNameImageTwo"
                        />
                      ) : (
                        <img
                          alt="songImage"
                          src="./static/background1.png"
                          className="AudioPlayerStyleClassNameImage"
                        />
                      )}
                      {isMobile() === false ? (
                        <div
                          className={!showEvery ? `songDetail` : "AudioPlayerStyleClassNameDiv"}
                          style={{ display: "block" }}
                        >
                          {/* <p className="titler">Title</p> */}
                          <p style={{}} className="p2">
                            {state.previewSong?.name
                              ? state.previewSong?.name
                              : "No song selected"}
                          </p>
                          <p style={{}} className="p4">
                            {state.previewSong?.artist
                              ? state.previewSong?.artist
                              : "No song selected"}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
                <div className="audioPlayer">
                  {showEvery === true ? (
                    isMobile() === true ? (
                      <p className="pp12">
                        {state.previewSong?.name
                          ? state.previewSong?.name
                          : "No song selected"}
                      </p>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}



                  <div className={!showEvery ? "songDetail" : ''}>
                    {!showEvery ?
                      <>
                        <h2>
                          {state.previewSong?.name
                            ? state.previewSong?.name
                            : `Play a song`}
                        </h2>
                        <p>
                          {state.previewSong?.artist ? state.previewSong?.artist : `artist`}
                        </p>
                      </>
                      : null}
                    <AudioPlayerTool showFilledVolume={false} />
                  </div>



                </div>
              </div>


            </AudioPlayerStyle>
          </div>
          <div className="AudioPlayerStyleClassNameFlexbox">
            <div className="AudioPlayerFlexbox">
              <SideBarTab
                className="tab"
                icon={
                  theme === "dark" ? (
                    <HomeIconMobile color={"white"} />
                  ) : (
                    <HomeIconMobile color={"#1D1D1D"} />
                  )
                }
                title="Home"
                url="/home"
              />
            </div>
            <div className="AudioPlayerFlexbox">
              <SideBarTab
                className="tab"
                icon={
                  theme === "dark" ? (
                    <SearchIconMobile color={"white"} />
                  ) : (
                    <SearchIconMobile color={"#1D1D1D"} />
                  )
                }
                title="Browse"
                url="/search"
              />
            </div>
            <div className="AudioPlayerFlexbox">
              <Link to="/future_playlist" className="paragraphing tab">
                <span>
                  {" "}
                  {theme === "dark" ? (
                    <FutureListMobile color={"white"} />
                  ) : (
                    <FutureListDark />
                  )}
                </span>{" "}
                <span style={{}} className="title1">
                  Pre-release
                </span>
              </Link>
            </div>
            <DrawerStyle
              // title="Drawer with extra actions"
              placement="left"
              onClose={handleClose}
              visible={showDrawer}
              width={300}
            >
              <div className="playlist-items">
                {state.playlists.map((data) => {
                  const { name, id } = data;

                  return (
                    <div
                      className={`playlist-item ${playlistId === id ? "active" : ""
                        }`}
                      onClick={handleClose}
                    >
                      <Link to={`/playlist/${id}`} className="titles1">
                        <p>{name}</p>
                      </Link>
                    </div>
                  );
                })}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                      CurrentPages();
                    }}
                    style={{
                      background: "rgb(0, 122, 255,0.6)",
                      marginTop: "5px",
                      marginBottom: "5px",
                      padding: "3px",
                      borderRadius: "5px",
                      width: "50%",
                      textAlign: "center",
                      cursor: "pointer",
                      color: "white",
                    }}
                  >
                    Load more
                  </p>
                </div>
              </div>
            </DrawerStyle>
            <div className="AudioPlayerFlexbox">
              <SideBarTab
                className="settings-button tab"
                icon={
                  theme === "dark" ? (
                    <FutureListMobile1 color={"white"} />
                  ) : (
                    <PlaylistDark />
                  )
                }
                title="Playlists"
                onClick={handleOpen}
              />
            </div>
          </div>
          <div className="bottomNav">
            <div className="bottomBarNav">
              <SideBarTab
                className=""
                icon={
                  theme === "dark" ? (
                    <HomeIconMobile color={"white"} />
                  ) : (
                    <HomeIconMobile color={"#1D1D1D"} />
                  )
                }
                title="Home"
                url="/home"
              />
            </div>
            <div className="bottomBarNav">
              <SideBarTab
                className=""
                icon={
                  theme === "dark" ? (
                    <SearchIconMobile color={"white"} />
                  ) : (
                    <SearchIconMobile color={"#1D1D1D"} />
                  )
                }
                title="Browse"
                url="/search"
              />
            </div>
            <div className="bottomBarNav">
              <SideBarTab
                className=""
                icon={
                  theme === "dark" ? (
                    <FutureListMobile color={"white"} />
                  ) : (
                    <FutureListDark />
                  )
                }
                title="Pre-release"
                url="/future_playlist"
              />
            </div>
            <div className="bottomBarNav">
              <SideBarTab
                className=""
                icon={
                  theme === "dark" ? (
                    <FutureListMobile1 color={"white"} />
                  ) : (
                    <PlaylistDark />
                  )
                }
                title="Playlists"
                onClick={handleOpen}
              />
            </div>
            <DrawerStyle
              // title="Drawer with extra actions"
              placement="left"
              onClose={handleClose}
              visible={showDrawer}
              width={300}
            >
              <div className="playlist-items">
                {state.playlists.map((data) => {
                  const { name, id } = data;

                  return (
                    <div
                      className={`playlist-item ${playlistId === id ? "active" : ""
                        }`}
                      onClick={handleClose}
                    >
                      <Link to={`/playlist/${id}`} className="titles1">
                        <p>{name}</p>
                      </Link>
                    </div>
                  );
                })}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    onClick={() => {
                      setCurrentPage(currentPage + 1);
                      CurrentPages();
                    }}
                    style={{
                      background: "rgb(0, 122, 255,0.6)",
                      marginTop: "5px",
                      marginBottom: "5px",
                      padding: "3px",
                      borderRadius: "5px",
                      width: "50%",
                      textAlign: "center",
                      cursor: "pointer",
                      color: "white",
                    }}
                  >
                    Load more
                  </p>
                </div>
              </div>
            </DrawerStyle>
          </div>
        </div>
      </AudioPlayerStyle1>
    </>
  );
};

export default AudioPlayer1;
