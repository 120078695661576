import { Input, message } from "antd";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { AddToPlayListModalStyle } from "../../components/AddToPlayListModal/AddToPlayListModal.style";
import Spinner from "../../components/Spinner/Spinner";
import { useLoginContext } from "../../context";

interface CreatePlayListModalProps {
  isModalVisible?: boolean;
  setIsModalVisible?: (_: boolean) => void;
  playlists?: any;
  setPlaylists?: any;
}

const CreatePlayListModal = (props: CreatePlayListModalProps) => {
  const { isModalVisible, setIsModalVisible } = props;
  const [setLoading, showLoading] = useState(false);


  const { state: loginState } = useLoginContext();
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const [data, setData] = useState({
    name: "",
    description: "",
    public: false,
  });


  const handleCancel = (e: any) => {
    e.stopPropagation();
    setIsModalVisible(false);
  };


  // console.log(state.searchedSongData);
  const handleFormSubmit = (values: any) => {
    if (values.name !== "" || values.description !== "") {
      showLoading(true);
      axios
        .post(
          `https://api.spotify.com/v1/users/${loginState.userId
          }/playlists`,
          JSON.stringify(data),
          {
            headers: {
              Authorization: `Bearer ${loginState.token}`,
            },

          }
        )
        .then((res) => {
          message.info("Playlist created successfully");
          showLoading(false);
          setIsModalVisible(false);
          window.location.reload()
          // props.setPlaylists([res.data, ...props.playlists])
        })
        .catch((e) => {
          toast.error("Something went wrong");
          showLoading(false);
        });
    } 
  };

  return (
    <AddToPlayListModalStyle
      visible={isModalVisible}
      onOk={handleOk}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="addToPlaylistHead" style={{ paddingBottom: "0px" }}>
        <h1 className="heading">Create playlist</h1>
        {/* <p className="description"> */}
        {/* To schedule a song in your playlist, please select the playlist below. */}
        {/* </p> */}
      </div>
      <div className="formAddToPlaylist">
        <div className="formPlaylist">
          <div className="playlistPosition">
            <label className="label">
              <p>Playlist name</p>
              <Input
                // type="number"
                placeholder="Playlist name"
                onChange={(value) => {
                  let obj = { ...data };
                  obj.name = value.target.value;
                  setData(obj);
                }}
              />
            </label>
          </div>
          <label className="label">
            <p>Description</p>
            <Input.TextArea
              // type="text"
              className="addNoteText"
              placeholder="Description"
              rows={4}
              onChange={(value: any) => {
                let obj = { ...data };
                obj.description = value.target.value;
                setData(obj);
              }}
            />
          </label>
        </div>
      </div>
      <div className="buttonDiv">
        <button className="addSong" disabled={setLoading} onClick={() => handleFormSubmit(data)}>
          {setLoading ?
            <Spinner />
            :
            'Add'
          }
        </button>
      </div>
    </AddToPlayListModalStyle>
  );
};

export default CreatePlayListModal;
