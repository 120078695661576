import "./Spinner.css";

function Spinner(color) {
  return (
    <div className="circle-container">
      <svg
        fill="none"
        width="22px"
        height="22px"
        className="circle-svg"
        style={{ stroke: color || "white", height: "60px" }}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle className="circle" cx="50" cy="50" r="45" />
      </svg>
    </div>
  );
}

export default Spinner;
