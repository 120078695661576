import {
  ADD_USER_SPOTIFY_PROFILE_FAIL,
  ADD_USER_SPOTIFY_PROFILE_REQUEST,
  ADD_USER_SPOTIFY_PROFILE_SUCCESS,
  DELETE_USER_SPOTIFY_PROFILE_FAIL,
  DELETE_USER_SPOTIFY_PROFILE_REQUEST,
  DELETE_USER_SPOTIFY_PROFILE_SUCCESS,
  GET_USER_SPOTIFY_PROFILE_FAIL,
  GET_USER_SPOTIFY_PROFILE_REQUEST,
  GET_USER_SPOTIFY_PROFILE_SUCCESS,
  IS_AUDIOPLAYER_OPEN,
} from "../types/playlistTypes";

export const getUserProfileReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_SPOTIFY_PROFILE_REQUEST:
      return {
        ...state,
        getUserLoading: true,
      };
    case GET_USER_SPOTIFY_PROFILE_SUCCESS:
      return {
        ...state,
        getUserLoading: false,
        getUserInfo: payload,
        getUserError: "",
      };
    case GET_USER_SPOTIFY_PROFILE_FAIL:
      return {
        ...state,
        getUserLoading: false,
        getUserInfo: null,
        getUserError: payload,
      };
    default:
      return state;
  }
};

export const addUserProfileReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_USER_SPOTIFY_PROFILE_REQUEST:
      return {
        ...state,
        addUserLoading: true,
      };
    case ADD_USER_SPOTIFY_PROFILE_SUCCESS:
      return {
        ...state,
        addUserLoading: false,
        addUserInfo: payload,
        addUserError: "",
      };
    case ADD_USER_SPOTIFY_PROFILE_FAIL:
      return {
        ...state,
        addUserLoading: false,
        addUserInfo: null,
        addUserError: payload,
      };
    default:
      return state;
  }
};

export const deleteUserProfileReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_USER_SPOTIFY_PROFILE_REQUEST:
      return {
        ...state,
        deleteUserLoading: true,
      };
    case DELETE_USER_SPOTIFY_PROFILE_SUCCESS:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserInfo: payload,
        deleteUserError: "",
      };
    case DELETE_USER_SPOTIFY_PROFILE_FAIL:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserInfo: null,
        deleteUserError: payload,
      };
    default:
      return state;
  }
};

export const audioBarReducer = (state = { isOpen: false }, action) => {
  const { type, payload } = action;
  switch (type) {
    case IS_AUDIOPLAYER_OPEN:
      return {
        ...state,
        isOpen: payload
      };
    default:
      return state;

  }
}
