import styled from "styled-components";

export const SwitchStyle = styled.div`
.platformSelect{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 36px;
    border-radius: 8px;
    background: rgb(36, 36, 41);
    border: 0.6px solid rgba(255, 255, 255, 0.2);
    font-size: 12px;
    padding: 9.5px 16px;
    width: 127.33px !important;
    color: rgb(255, 255, 255) !important;
  }
`