import styled from "styled-components";

const LoaderPageStyle = styled.div`
background: ${(props) => props.theme.background.page3};
height:100vh;
width:100vw;
display:flex;
align-items:center;
justify-content:center;
.loadingPage{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
}
h1{
    color:white;
    margin-bottom:20px
    font-size: 22px;
}
.loadingCircle{
    width: 85px;
    height: 85px;
    border: 5px solid #007AFF;
    border-radius: 90px;
    border-left: 5px solid transparent;
    animation: refreshRound 1s infinite;
  }
  @keyframes refreshRound {
    0%{
      transform: rotate(0deg);
    }
    0%{
      transform: rotate(359deg);
    }
  }
`
export { LoaderPageStyle };
