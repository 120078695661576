import styled from "styled-components";

export const AudioPlayerStyle = styled.div`
  max-width: 90%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  grid-gap: 20px;

  .audio-player {
    background: transparent;
    border: 0px;
    box-shadow: 0px;
    outline: none;
    width: 100%;

    .rhap_progress-section {
      div {
        color: #fff;
      }
    }
    .rhap_container {
      width: 80%;
      box-shadow: 0px 0px 0px 0px !important;
    }
    .rhap_controls-section {
      display: none;
      color: white !important;
    }
    .rhap_button-clear {
      color: white;
      //   border-radius: 100px;
      //   background: white;
    }
  }
`;
