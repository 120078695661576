import { Input } from "antd";
import styled from "styled-components";
interface InputBoxStyleProps {
  width: string;
  height: string;
  transparent: boolean;
}

const InputStyle = styled(Input)<InputBoxStyleProps>`
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  max-width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "48px")};
  color: ${(props) =>
    props.transparent
      ? props.theme.colors.primary
      : props.theme.background.secondary};
  background: ${(props) => props.theme.background.inputs};
  border: 1px solid ${(props) => props.theme.background.inputsBorder};

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  @media only screen and (max-width: 600px) {
    font-size: 17px;
  }
`;

export { InputStyle };
