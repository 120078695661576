import styled from "styled-components";

const HomeStyle = styled.div`
  // height: 100%;
  padding-bottom: 4%;
  // .awesome-iframe {
  //   display: none;
  // }
  margin-top: 50px;
  .caursal {
    // padding: 0px 20px !important;
    // margin: 0px 20px !important;
  }

  .diver {
    padding: 65px 0px;
    background: transparent;
  }

  .react-multi-carousel-item {
    // display: flex;
    // justify-content: center;
    // padding: 0px 0px;
    margin-left: 20px;
  }

  .PauseIcon1Mobile1 {
    display: block;
  }

  .PauseIcon1Mobile2 {
    display: none;
  }

  .imageheading1 {
    width: 20px !important;
    height: 20px !important;
  }

  #playssSvg {
    margin: 0px 0px 0px 0px;
    height: 60px;
    width: 60px;
    padding: 0px;
  }

  .sc-home-padding-bottom {
    padding-bottom: 0%;
  }

  .div3 {
    padding-bottom: 0%;
  }

  .div2 {
    padding-bottom: 0%;
  }

  .GraphicEqIcon {
    font-size: 35px;
    color: white;
  }

  .artistCarousel .react-multi-carousel-item {
    margin: 0px 8px;
    width: auto !important;
    padding: 18px;
  }
  .jWJgME{
    padding: 0px;
  }
  .artistCarousel .diver{
    padding: 0px;
  }



  @media only screen and (max-width: 600px) {
    padding-bottom: 200px;

    .card-paragraph {
      font-size: 12px;
      display: flex;
    }

    .spanner {
      font-size: 10px;
      margin-left: 6px;
    }

    .MusicCardStyleVector {
      width: 12px;
      height: 12px;
    }

    .heading {
      font-size: 24px;
    }
    .subheading {
      font-size: 12px;
    }

    .homeimage {
      width: 14px;
    }

    .sc-iJnaPW {
      width: 100% !important;
    }

    .sc-gikAfH {
      width: 100% !important;
    }

    .khEuol {
      width: 100% !important;
    }

    .card-heading {
      font-size: 12px;
    }

    .card-description {
      font-size: 12px;
    }

    .playss1 {
      background: #007aff;
      border: 1px solid #007aff;
    }

    .MuiSvgIconRootHome {
      color: black;
      font-size: 35px;
    }

    #playssSvg {
      margin: -14px 0px -14px -14px;
      height: 44px;
      width: 44px;
      padding: 0px;
    }

 

    .p3 {
      color: white !important;
      font-size: 14px !important;
      margin-left:  !important;
      padding-top:  !important;
    }

    .div2 {
      margin-top: 50% !important;
      margin-left: 7% !important;
      font-size: 14px !important;
      padding-bottom: 4%;
    }

    .div5 {
      margin-left: 10px !important;
    }

    .div4 {
      margin-left: 10px;
    }

    .div3 {
      margin-top: 50% !important;
      margin-left: 7% !important;
      font-size: 14px !important;
      padding-bottom: 4%;
    }

    .imageheading1 {
      width: 15px !important;
      height: 15px !important;
    }

    .heading1 {
      font-size: 14px;
      max-width: calc(70% - 0%) !important;
    }

    .searchBar {
      height: 40px;
    }

    .PauseIcon1Mobile1 {
      display: none;
    }

    .PauseIcon1Mobile2 {
      display: block;
    }

    .playss {
      padding: 4px 15px 4px 0px;
      margin-left: 16%;
      font-size: 13px;
    }

   

    .kBdMHu {
      width: 100%;
    }

    .playss1 {
      height: 30px;
      width: 60%;
    }

    .GraphicEqIcon {
      font-size: 26px;
    }

    .sc-home-padding-bottom {
      padding-bottom: 26%;
    }
  }

  @media only screen and (max-width: 360px) {
   

    .card-heading {
      font-size: 12px;
    }

    #playssSvg {
      margin: -14px 0px -14px -14px;
      height: 38px;
      width: 38px;
      padding: 0px;
    }

    .card-description {
      font-size: 11px;
    }

    .card-paragraph {
      font-size: 11px;
      display: flex;
    }

    .MusicCardStyleVector {
      width: 12px;
      height: 12px;
    }

    .spanner {
      font-size: 10px;
      margin-left: 6px;
    }

    .playss {
      padding: 4px 20px 4px 0px;
      margin-left: 12%;
      font-size: 10px;
    }

    .playss1 {
      height: 30px;
      width: 60%;
    }

    .GraphicEqIcon {
      font-size: 26px;
    }
  }

  @media only screen and (max-width: 353px) {
   

    .card-heading {
      font-size: 12px;
    }

    #playssSvg {
      margin: -14px 0px -14px -14px;
      height: 80px;
      width: 80px;
      padding: 0px;
    }

    .card-description {
      font-size: 12px;
    }

    .card-paragraph {
      font-size: 16px;
      display: flex;
    }

    .MusicCardStyleVector {
      width: 20px;
      height: 20px;
    }

    .spanner {
      font-size: 16px;
      margin-left: 6px;
    }

    .playss {
      padding: 0px 40px 0px 0px;
      margin-left: 16%;
      font-size: 20px;
    }

    .playss1 {
      height: 55px;
      width: 60%;
    }

    .GraphicEqIcon {
      font-size: 40px;
    }
  }

  @media only screen and (max-width: 320px) {
    .heading1 {
      max-width: calc(70% - 10%) !important;
    }
  }

  @media only screen and (min-width: 1366px) {
    padding-bottom: 4%;
    .react-multi-carousel-item {
      margin-left: 80px;
    }
  }
  @media only screen and (min-width: 1440px) {
    padding-bottom: 4%;
    .react-multi-carousel-item {
      margin-left: 60px;
    }
  }
  @media only screen and (min-width: 1536px) {
    padding-bottom: 4%;
    .react-multi-carousel-item {
      margin-left: 40px;
    }
  }
  @media only screen and (min-width: 1920px) {
    padding-bottom: 4%;
    .react-multi-carousel-item {
      margin-left: -50px;
    }
  }
  .react-multiple-carousel__arrow--left {
    height: 100%;
    width: 10%;
    border-radius: 0px;
    margin-left: -4.5%;
    background: linear-gradient(-270deg, #171717 0%, rgba(0, 0, 0, 0) 98.29%);
    backdrop-filter: blur(2px);
  }
  .react-multiple-carousel__arrow--left:hover {
    transform: scale(1.3);
  }
  .react-multiple-carousel__arrow--right:hover {
    transform: scale(1.3);
  }
  .react-multiple-carousel__arrow--right {
    height: 100%;
    width: 10%;
    border-radius: 0px;
    margin-right: -5%;
    background: linear-gradient(270deg, #171717 0%, rgba(0, 0, 0, 0) 99.29%);
    backdrop-filter: blur(2px);
  }
`;
export { HomeStyle };
