import styled from "styled-components";
const LoginFormStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-size: 14px;
  width: 100%;
  padding: 0 20px;

  input {
    color: #000;
    background-color: transparent;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: 400px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .forgot-password-text {
    bottom: 50px;
  }

  .remember-me {
    display: flex;
    gap: 10px;
    align-items: center;

    label {
      font-size: 14px;
    }

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }
  }
  .text-dontHaveAccount {
    font-size: 14px;

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  .link {
    color: #007aff;
    margin-top: 10px;
  }
`;
export { LoginFormStyle };
