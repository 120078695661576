import { Switch } from "antd";
import styled from "styled-components";

export const BulkModeCTAStyle = styled(Switch)`
  border: 1px solid white;
  
  margin-top: 0px;
  display: flex;
  align-items: center;

`;

export const BulkModeWrapperStyle = styled.span`
  color: ${(props) => props.theme.colors.primary};
  margin-top: 5px;
  margin-bottom: 20px;

  .ant-switch-handle {
    margin-top: 0px;
    top:auto;
    left: 2px;
width: 10px;
    height: 10px;
  }
  .ant-switch-checked .ant-switch-handle{
    right: 2px;
    left: auto;
  }
  .ant-switch {
    height: 16px;
        min-width: 10px;
  }
  .row {
    display: flex;
    align-items: center;
  }
  .text {
    margin-left: 10px;
    font-size: 12px;
    margin-top: 5px;
    &.bulkText{
      width: 71px;
      margin:0px 0px 0px 10px !important;
      font-size:11px;
    }
  }
  .spanner {
    color: ${(props) => props.theme.colors.primary};
    font-size: 17px;
  }

  .songplace_only {
    color: ${(props) => props.theme.colors.primary};
    font-size: 17px;
  }

  .text_songplace_only {
    margin-left: 10px;
    font-size: 11px;
    margin-top: 5px;
  }

  .SwitchPlayListSpannerRows_text_songplace_only {
    margin-top: 0px;
  }

  @media only screen and (max-width: 600px) {
    .songplace_only {
      font-size: 14px;
    }

    .text_songplace_only {
      font-size: 16px;
    }

    .SwitchPlayListSpannerRows_text_songplace_only {
      margin-top: 14px;

    }

  }

  @media only screen and (max-width: 412px) {
    .SwitchPlayListSpannerRows_text_songplace_only {
      margin-top: 7px;
    }
  }

  @media only screen and (max-width: 360px) {
    .songplace_only {
      font-size: 12px;
    }

    .text_songplace_only {
      font-size: 14px;
    }

    .SwitchPlayListSpannerRows_text_songplace_only {
      margin-top: 8px;
    }

  }
`;
