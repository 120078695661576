import styled from "styled-components";

const SettingsStyle = styled.div`
  padding: 0 20px;

  .heading {
    color: ${(props) => props.theme.colors.primary};
  }
  @media (max-width: 600px) {
    // max-height: calc(100vh - 05px);
    padding-bottom: 10px;
  }
`;

export { SettingsStyle };
