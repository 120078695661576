import { Modal } from "antd";
import styled from "styled-components";

export const MailchimpModalStyle = styled(Modal)`
  .heading {
    font-size: 26px;
    color: ${(props) => props.theme.colors.primary};
  }

  .description {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-modal-content {
    backdrop-filter: blur(5px);
    background-color: ${(props) => props.theme.background.secondary};
    color: ${(props) => props.theme.colors.primary};
    width: 655px;
    border: 1px solid white;
    border-radius: 7px;
    margin-left: -60px;
  }

  .required {
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: 48px;
    background: ${(props) => props.theme.background.inputs};
    border: 1px solid ${(props) => props.theme.background.inputsBorder};
    margin-top: 10px;
  }
  .naming {
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: 48px;
    background: ${(props) => props.theme.background.inputs};
    border: 1px solid ${(props) => props.theme.background.inputsBorder};
    margin-top: 10px;
  }

  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .ant-select-selection-item {
    font-size: 15px !important;
  }
  .ant-select-selector {
    height: 49px !important;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .date {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .ant-modal-body {
    padding: 24px;
  }

  .button {
    background: rgb(0, 122, 255);
    border-radius: 5px;
    border: 1px solid rgb(0, 122, 255);
    padding: 14px 18px;
    font-size: 16px;
  }

  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      width: 90%;
      margin-left: 5%;
    }

    .required,
    .naming {
      font-size: 17px;
    }

    .ant-select-selection-item {
      font-size: 15px !important;
    }

    .ant-modal-body {
      padding: 18px;
    }

    .button {
      background: rgb(0, 122, 255);
      border-radius: 5px;
      border: 1px solid rgb(0, 122, 255);
      padding: 10px 15px;
      font-size: 15px;
    }
  }

  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }

  .ant-modal-wrap {
    z-index: 10000000;
  }

  .footer {
    display: flex;
    gap: 5px;
    align-self: flex-end;
  }
`;
