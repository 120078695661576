import { GeneralInformationForm } from "../../components";
import { SettingsStyle } from "./Settings.style";

const Setting = () => {


  return (
      <SettingsStyle>
        <GeneralInformationForm />
      </SettingsStyle>
  );
};

export default Setting;
